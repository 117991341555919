import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Loader } from '@orascom/common-components';
import styles from './deal-lifecycle.module.scss';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { AnalyticsLeadsCount } from '../../definitions/interfaces/analytics';
import { FilterValue, UserRole } from '@orascom/api-interfaces';
import queryString from 'query-string';
import {
  AnalyticsFilter,
  LifecycleContent,
  LifecycleHeaders,
  useAnalyticsFilters,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';

interface DealLifeCycleProps {
  filterDestinations?: FilterValue[];
  filterUnitTypes?: FilterValue[];
  filterBrokerAgents?: FilterValue[];
  filterBrokerages?: FilterValue[];
}
export function DealLifeCycle(props: Readonly<DealLifeCycleProps>) {
  const userContext = useContext(USER_CONTEXT);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [leadsCount, setLeadsCount] = useState<AnalyticsLeadsCount>();

  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedBrokerAgents,
    handleSelectedBrokerAgents,
    selectedBrokerage,
    handleSelectedBrokerage,
    selectedUnitTypesIds,
    selectedBrokerAgentsIds,
    selectedBrokeragesIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  const filtersParams = queryString.parse(window.location.search);

  useEffect(() => {
    let promise;
    if (userContext.user?.role === UserRole.BROKER) {
      promise = AnalyticsUtils.getAnalyticsLeadCount({
        from: startDateArg,
        to: endDateArg,
        'destination_ids[]': selectedDestinationsIds,
        'unit_type_ids[]': selectedUnitTypesIds,
        'broker_ids[]': selectedBrokerAgentsIds,
      });
    } else {
      promise = AnalyticsSalesManagerUtils.getAnalyticsLeadCountSalesManager({
        from: startDateArg,
        to: endDateArg,
        'destination_ids[]': selectedDestinationsIds,
        'unit_type_ids[]': selectedUnitTypesIds,
        'brokerage_company_ids[]': selectedBrokeragesIds,
      });
    }
    promise
      .then((res) => {
        setLeadsCount(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    selectedUnitTypes,
    selectedBrokerAgents,
    selectedBrokerage,
  ]);

  if (loading) {
    return <Loader />;
  }
  if (!leadsCount) {
    return <h1>No Leads Found</h1>;
  }

  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      <h3 className="title">
        {t('dealLifeCycle')}{' '}
        {/* {userContext.user?.role === UserRole.BROKER ? (
          <AnalyticsFilter
            countriesFilter
            filterBrokerAgents={props.filterBrokerAgents}
            handleSelectBrokerAgents={handleSelectedBrokerAgents}
           
          />
        ) : null} */}
      </h3>
      <div className={`card ${styles['deal-lifecycle-wrapper']}`}>
        <div className={styles['card-filters']}>
          <AnalyticsFilter
            filterDestinations={props.filterDestinations}
            filterUnitTypes={props.filterUnitTypes}
            handleSelectedDestinations={handleSelectedDestinations}
            handleSelectedUnitTypes={handleSelectedUnitTypes}
            {...(userContext.user?.role === UserRole.BROKER && {
              countriesFilter: true,
              filterBrokerAgents: props.filterBrokerAgents,
              handleSelectBrokerAgents: handleSelectedBrokerAgents,
            })}
            {...(userContext.user?.role === UserRole.SALES_MANAGER && {
              buyerCountry: true,
              unitSizeFilter: true,
              filterBrokerages: props.filterBrokerages,
              handleSelectBrokerages: handleSelectedBrokerage,
            })}
            filterByLabel={userContext.user?.role === UserRole.BROKER}
          />
        </div>

        <LifecycleHeaders />

        <LifecycleContent
          baseCount={leadsCount.submitted}
          cycleContentTitle="Lead Submitted"
          cycleContentCount={leadsCount.submitted}
          cycleContentPrevStepCount={leadsCount.submitted}
        />

        <LifecycleContent
          baseCount={leadsCount.submitted}
          cycleContentTitle="Lead Verified"
          cycleContentCount={leadsCount.verifed}
          cycleContentPrevStepCount={leadsCount.submitted}
        />
        {leadsCount.meeting !== 0 ? (
          <LifecycleContent
            baseCount={leadsCount.submitted}
            cycleContentTitle="Conducted Meeting"
            cycleContentCount={leadsCount.meeting}
            cycleContentPrevStepCount={leadsCount.verifed}
          />
        ) : null}

        <LifecycleContent
          baseCount={leadsCount.submitted}
          cycleContentTitle="Reserved"
          cycleContentCount={leadsCount.reserved}
          cycleContentPrevStepCount={leadsCount.verifed}
        />

        <LifecycleContent
          baseCount={leadsCount.submitted}
          cycleContentTitle="Deal Closed"
          cycleContentCount={leadsCount.closed}
          cycleContentPrevStepCount={leadsCount.reserved}
        />

        <LifecycleContent
          baseCount={leadsCount.submitted}
          cycleContentTitle="Submitted Sales Invoice"
          cycleContentCount={leadsCount.invoice_submitted}
          cycleContentPrevStepCount={leadsCount.closed}
        />

        <LifecycleContent
          baseCount={leadsCount.submitted}
          cycleContentTitle="Sales Invoice Approved"
          cycleContentCount={leadsCount.invoice_approved}
          cycleContentPrevStepCount={leadsCount.invoice_submitted}
        />
      </div>
    </div>
  );
}

export default DealLifeCycle;
