/* eslint-disable import/no-extraneous-dependencies */
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import styles from '../../pages/deal-details/deal-details.module.scss';
import { meetingStatusData, Deal as DealUtils } from '../../utils/deal';
import { useEffect, useState } from 'react';
import { Loader } from '@orascom/common-components';
import { MeetingInterface } from '../../definitions/interfaces/inputs/new-deal';

export interface LeadScheduledMeetingsProps {
  dealId: string;
  countryId: string;
  leadMeetings: MeetingInterface[];
  setLeadMeetings: (value: MeetingInterface[]) => void;
}

export function LeadScheduledMeetings({
  dealId,
  countryId,
  leadMeetings,
  setLeadMeetings,
}: Readonly<LeadScheduledMeetingsProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    DealUtils.getLeadMeetings(dealId, countryId)
      .then((res) => {
        setLeadMeetings(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [dealId]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      {leadMeetings.length ? (
        leadMeetings.map((meeting, index) => {
          return (
            <div key={uuidv4()} className="card meeting-card">
              <h3>Meeting {index + 1}</h3>
              <div className={styles['meeting-details']}>
                <div className={styles['detail']}>
                  <p>{t('meetingDate')}</p>
                  <h3>
                    {format(new Date(meeting.meeting_date), 'EEE, d MMM yyyy')}
                  </h3>
                </div>
                <div className={styles['detail']}>
                  <p>{t('time')}</p>
                  <h3>{format(new Date(meeting.meeting_date), 'hh:mm aa')}</h3>
                </div>
                <div className={styles['detail']}>
                  <p>{t('location')}</p>
                  <h3>{meeting.location}</h3>
                </div>
                <div className={styles['detail']}>
                  <p>{t('status')}</p>
                  <div
                    className={`status ${
                      meetingStatusData(meeting.approval_status).statusClassName
                    }`}
                  >
                    {meetingStatusData(meeting.approval_status).statusText}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className={styles['empty-placeholder']}>
          {t('noScheduledMeetings')}
        </p>
      )}
    </div>
  );
}

export default LeadScheduledMeetings;
