import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './price-range-slider.scss';
import { FiltersEnums, PriceSlider, PriceValue } from '@orascom/api-interfaces';
import PriceTextInput from './numeric-input';
import { useSearchParams } from 'react-router-dom';

interface Props {
  priceSlider: PriceSlider;
  onChange: (arg: PriceValue) => void;
  onChangeEnd?: (arg: PriceValue) => void;
  disabled?: boolean;
  horizontalLine?: boolean;
  customMargin?: number;
}

function convertToInt(value: number) {
  return parseInt(value.toFixed(0));
}

const priceRangeStep = 1;

export const PriceRangeSlider = (props: Props) => {
  const { t } = useTranslation();
  const { priceSlider, onChange, onChangeEnd, disabled } = props;
  const { price: value, minRange, maxRange } = priceSlider;
  const [searchParams] = useSearchParams();
  const minInputRef = useRef<HTMLInputElement>(null);
  const maxInputRef = useRef<HTMLInputElement>(null);

  // We need these states to force update the input values. Previously, we were relying only on `value.min` and `value.max`,
  // but there are times when `value.max` isn't changed and we still need to update the input references.
  const [shouldUpdateMinInput, setShouldUpdateMinInput] = useState(false);
  const [shouldUpdateMaxInput, setShouldUpdateMaxInput] = useState(false);

  useEffect(() => {
    const noSelectedPrice = !searchParams.get(FiltersEnums.MIN_PRICE);
    // useful for resetting values after clearing price filter(delete search params)
    if (noSelectedPrice) {
      onChange({ ...value, min: minRange, max: maxRange });
    }
  }, [searchParams]);

  useEffect(() => {
    minInputRef.current!.value = value.min.toLocaleString();
  }, [value.min, shouldUpdateMinInput]);

  useEffect(() => {
    maxInputRef.current!.value = value.max.toLocaleString();
  }, [value.max, shouldUpdateMaxInput]);

  // a small diff to prevent overlap between min and max
  // const priceDiff = Math.round((maxRange - minRange) / 100) * 3;

  const onMinChangeHandler = (min: number) => {
    // snap min to max if min >= max
    if (min >= value.max) {
      min = value.max;
    }

    // don't exceed the start point
    if (min <= minRange) {
      min = minRange;
    }

    onChange({ ...value, min: convertToInt(min) });

    setShouldUpdateMinInput((val) => !val);

    return min;
  };

  const onMaxChangeHandler = (max: number) => {
    // snap max to min if max <= min
    if (max <= value.min) {
      max = value.min;
    }

    // don't exceed the end point
    if (max >= maxRange) {
      max = maxRange;
    }

    onChange({ ...value, max: convertToInt(max) });

    setShouldUpdateMaxInput((val) => !val);

    return max;
  };

  // 1 is fallback if min and max are the same to prevent division by 0
  const rangeDiff = maxRange - minRange || 1;

  const calcPercentage = (value?: number) => {
    if (!value) return 0;
    return ((value - minRange) / rangeDiff) * 100;
  };
  // these percentages are used to draw the progress bar
  const leftPercentage = calcPercentage(value?.min);
  const rightPercentage = calcPercentage(value?.max);

  return (
    <div
      className="min-max"
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? '0.5' : '1',
        marginTop: props.customMargin ?? '-30px',
      }}
    >
      {props.horizontalLine ? <hr className="min-max__separator" /> : null}

      <label className="min-max__label">{t('priceRange')}</label>

      <div className="min-max__scroll">
        <div
          className="min-max__progress"
          style={{
            insetInlineStart: `${leftPercentage}%`,
            width: `${rightPercentage - leftPercentage}%`,
          }}
        />
        <input
          type="range"
          className="min-max__range"
          min={convertToInt(minRange)}
          max={convertToInt(maxRange)}
          value={convertToInt(value.min)}
          step={priceRangeStep}
          onChange={(e) => onMinChangeHandler(Number(e.target.value))}
          onTouchEnd={() => onChangeEnd?.(value)}
          onMouseUp={() => onChangeEnd?.(value)}
        />
        <input
          type="range"
          className="min-max__range"
          min={convertToInt(minRange)}
          max={convertToInt(maxRange)}
          value={convertToInt(value.max)}
          step={priceRangeStep}
          onChange={(e) => onMaxChangeHandler(Number(e.target.value))}
          onTouchEnd={() => onChangeEnd?.(value)}
          onMouseUp={() => onChangeEnd?.(value)}
        />
      </div>

      <div className="min-max__inputs">
        <div className="min-max__input-wrapper">
          <PriceTextInput
            id="min-numeric-input"
            className="min-max__input-price"
            onChange={(val) => {
              const min = onMinChangeHandler(val);
              onChangeEnd?.({ ...value, min });
            }}
            value={value.min}
            label={t('minimum')}
            ref={minInputRef}
            currency={value.currency}
          />
        </div>
        <div className="min-max__input-wrapper">
          <PriceTextInput
            id="max-numeric-input"
            className="min-max__input-price"
            onChange={(val) => {
              const max = onMaxChangeHandler(val);
              onChangeEnd?.({ ...value, max });
            }}
            label={t('maximum')}
            value={value.max}
            ref={maxInputRef}
            currency={value.currency}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceRangeSlider;
