import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User as UserUtils } from '../../utils/user';
import { DocumentsInterface } from '../../definitions/interfaces/documents';
import { Loader } from '@orascom/common-components';
import pdfIcon from '../../assets/icons/pdf.svg';
import downloadIcon from '../../assets/icons/download.svg';
import styles from './my-documents.module.scss';

export function MyDocuments() {
  const [documents, setDocuments] = useState<DocumentsInterface[]>();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    UserUtils.getUserDocuments()
      .then((res) => setDocuments(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myDocuments')}</h1>
      <div className={styles['cards']}>
        {documents?.map((d) => (
          <div className={styles['card']} key={d.id}>
            <img src={pdfIcon} alt={d.name} className={styles['thumbnail']} />
            <div className={styles['info']}>
              <div className={styles['name']}>{d.name}</div>
              <a
                className={styles['download']}
                href={d.document}
                download
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles['icon']}>
                  <img src={downloadIcon} alt="" role="presentation" />
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyDocuments;
