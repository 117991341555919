import { Network } from './network';
import { PaginationPayload, Payload } from '@orascom/api-interfaces';
import {
  NewsDetailsInterface,
  NewsInterface,
} from '../definitions/interfaces/news-events';
import { ENDPOINTS } from './endpoints';

export class News {
  /**
   * get all news and events
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @param {('true' | 'false')} isEvent
   * @returns {Promise<Payload<PaginationPayload<NewsInterface[]>>>}
   *
   * @memberOf News
   */
  public static getNewsEvents(
    page: number,
    perPage: number,
    isEvent: '1' | '0'
  ): Promise<Payload<PaginationPayload<NewsInterface[]>>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getNewsAndEvents.url}`
    );
    url.searchParams.append('page', String(page));
    url.searchParams.append('per_page', String(perPage));
    url.searchParams.append('event', isEvent);
    const fetchURL = url.href;
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getUnits.method,
    });
  }

  /**
   * get news details by news slug
   *
   * @static
   * @param {string} newsSlug
   * @returns {Promise<Payload<NewsDetailsInterface>>}
   *
   * @memberOf News
   */
  public static getNewsDetails(
    newsSlug: string
  ): Promise<Payload<NewsDetailsInterface>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getNewsDetails.url(
        newsSlug
      )}`,
      {
        method: ENDPOINTS.getNewsDetails.method,
      }
    );
  }
}
