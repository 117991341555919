import { Destination as DestinationAPI } from '../api/destination.api';
import {
  Destination as DestinationInterface,
  DestinationEducation,
  DestinationFacilities,
  DestinationFAQ,
  DestinationProject,
  Location,
  PaginationPayload,
} from '@orascom/api-interfaces';
import { getPayloadData, handleError } from '@orascom/utils';

export class Destination {
  /**
   * list all Destination data.
   *
   * @static
   * @returns {Promise<DestinationInterface[]>}
   * @memberof Destination
   */
  public static listDestinations(): Promise<DestinationInterface[]> {
    return DestinationAPI.listDestinations()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all Destination data.
   *
   * @static
   * @returns {Promise<DestinationInterface>}
   * @memberof Destination
   */
  public static getDestinationInfo(
    destinationSlug: string
  ): Promise<DestinationInterface> {
    return DestinationAPI.getDestinationInfo(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get destination facilities by destination id
   *
   * @static
   * @param {number} destinationId
   * @returns {Promise<DestinationFacilities[]>}
   *
   * @memberOf Destination
   */
  public static getDestinationFacilities(
    destinationSlug: string
  ): Promise<DestinationFacilities[]> {
    return DestinationAPI.getDestinationFacilities(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get destination educational hubs by destination id
   *
   * @static
   * @param {number} destinationId
   * @returns {Promise<DestinationEducation[]>}
   *
   * @memberOf Destination
   */
  public static getDestinationEducation(
    destinationSlug: string
  ): Promise<DestinationEducation[]> {
    return DestinationAPI.getDestinationEducation(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all Destination projects data.
   *
   * @static
   * @returns {Promise<DestinationProject[]>}
   * @memberof Destination
   */
  public static getDestinationProjects(
    destinationSlug: string
  ): Promise<DestinationProject[]> {
    return DestinationAPI.getDestinationProjects(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all Destination projects data.
   *
   * @static
   * @returns {Promise<DestinationFAQ[]>}
   * @memberof Destination
   */
  public static getDestinationFAQ(
    destinationSlug: string
  ): Promise<PaginationPayload<DestinationFAQ[]>> {
    return DestinationAPI.getDestinationFAQ(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getDestinationLocations(
    destinationSlug: string
  ): Promise<Location[]> {
    return DestinationAPI.getDestinationLocations(destinationSlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
