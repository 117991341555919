import { useTranslation } from 'react-i18next';
import styles from './compare-button.module.scss';
import ErrorIcon from '../../assets/icons/info.svg?react';
import { Link } from 'react-router-dom';
import { useCompareUnitsContext } from '@orascom/broker-sales-man-common-components';

export interface CompareButtonProps {
  comparePropertiesPath: string;
}
export function CompareButton(props: Readonly<CompareButtonProps>) {
  const { compareUnitsIds, showExceedLimitError } = useCompareUnitsContext();
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      {compareUnitsIds && compareUnitsIds.length > 0 && (
        <div className={styles['wrapper']}>
          <span>{compareUnitsIds?.length}</span>
          <Link className={`btn btn--navy`} to={props.comparePropertiesPath}>
            {t('compare')}
          </Link>
        </div>
      )}
      {showExceedLimitError && (
        <p className={styles['error']}>
          <ErrorIcon />
          <span>{t('maximumCompareError')}</span>
        </p>
      )}
    </div>
  );
}

export default CompareButton;
