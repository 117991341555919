import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';
import { SetStateAction } from 'react';

export async function downloadAnalyticsReport(
  reportName: string,
  setDownloading?: (value: SetStateAction<boolean>) => void
) {
  const analyticsPage = document.getElementById('analytics');
  if (setDownloading) {
    setDownloading(true);
  }
  const pdfDoc = await PDFDocument.create();
  if (analyticsPage) {
    const canvas = await html2canvas(analyticsPage);
    const imgDataUrl = canvas.toDataURL('image/png');

    const page = pdfDoc.addPage([canvas.width + 30, canvas.height + 60]);

    const img = await pdfDoc.embedPng(imgDataUrl);
    const { height } = page.getSize();

    page.drawImage(img, {
      x: 15,
      y: (height - canvas.height) / 2,
      width: canvas.width,
      height: canvas.height,
    });
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = reportName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
  if (setDownloading) {
    setDownloading(false);
  }
}
