import { FilterValue, PaginationPayload } from '@orascom/api-interfaces';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Loader } from '@orascom/common-components';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';
import { AnalyticsBrokerRankingsSalesManager } from '../../definitions/interfaces/analytics';
import { v4 as uuidv4 } from 'uuid';
import Numeral from 'numeral';
import {
  AnalyticsFilter,
  PaginationStyled,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';

interface SalesManagerBrokerRankingProps {
  filterDestinations: FilterValue[];
  filterUnitTypes: FilterValue[];
  filterBrokerages?: FilterValue[];
}
function SalesManagerBrokerRanking(
  props: Readonly<SalesManagerBrokerRankingProps>
) {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [loading, setLoading] = useState(true);
  const filtersParams = queryString.parse(window.location.search);
  const [brokersRanking, setBrokersRanking] =
    useState<PaginationPayload<AnalyticsBrokerRankingsSalesManager[]>>();

  const {
    selectedDestinations,
    selectedDestinationsIds,
    handleSelectedDestinations,
    selectedUnitTypes,
    selectedUnitTypesIds,
    handleSelectedUnitTypes,
    selectedBrokerage,
    selectedBrokeragesIds,
    handleSelectedBrokerage,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedBrokerage, selectedDestinations, selectedUnitTypes]);

  useEffect(() => {
    AnalyticsSalesManagerUtils.getAnalyticsBrokerRankingsSalesManager({
      page: currentPage.toString(),
      per_page: perPage.toString(),
      from: startDateArg,
      to: endDateArg,
      'brokerage_company_ids[]': selectedBrokeragesIds,
      'destination_ids[]': selectedDestinationsIds,
      'unit_type_ids[]': selectedUnitTypesIds,
    })
      .then((res) => {
        setBrokersRanking(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedBrokerage,
    selectedDestinations,
    selectedUnitTypes,
    currentPage,
  ]);
  if (loading) {
    return <Loader />;
  }
  if (!brokersRanking) {
    return <h1>no rankings found</h1>;
  }

  return (
    <div className="broker-analytic-wrapper">
      <h3 className="title">Broker Agent Ranking</h3>
      <div className={`card ranking-wrapper`}>
        <AnalyticsFilter
          unitSizeFilter
          filterBrokerages={props.filterBrokerages}
          filterDestinations={props.filterDestinations}
          filterUnitTypes={props.filterUnitTypes}
          handleSelectBrokerages={handleSelectedBrokerage}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
        />

        <div className="analytics-table">
          <table>
            <thead>
              <tr className="manager-tr">
                <th className="manager-th">Rank</th>
                <th className="manager-th">Brokers Name</th>
                <th className="manager-th">No. of deals</th>
                <th className="manager-th">Sales</th>
              </tr>
            </thead>
            <tbody>
              {brokersRanking.data?.map((broker, index) => {
                return (
                  <tr className="manager-tr" key={uuidv4()}>
                    <td>
                      <td className="manager-td--details">
                        <p>#{perPage * (currentPage - 1) + (index + 1)}</p>
                      </td>
                    </td>
                    <td className="manager-td--name">
                      <p>{broker.name}</p>
                    </td>
                    <td className="manager-td--details">
                      <p>{broker.deals_count}</p>
                    </td>
                    <td className="manager-td--name">
                      <p>
                        {' '}
                        {Numeral(broker.total_selling_price).format('0,0')}{' '}
                        {broker.currency}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationStyled
            currentPage={currentPage}
            totalCount={brokersRanking.meta.total}
            pageSize={brokersRanking.meta.per_page}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default SalesManagerBrokerRanking;
