import { PieChart } from 'react-minimal-pie-chart';
import { v4 as uuidv4 } from 'uuid';
import styles from './analytics-pie-chart-card.module.scss';
import { PieChartData } from '../../../definitions/interfaces/analytics';

export interface AnalyticsPieChartCardProps {
  data: PieChartData[];
  title?: string;
  totalSales: number;
  totalSalesTitle: string;
  currency: string;
  commissionsBreakdownChart?: boolean;
  downloadingReport?: boolean;
}

export function AnalyticsPieChartCard(
  props: Readonly<AnalyticsPieChartCardProps>
) {
  return (
    <div className={`${styles['pie-card']} card`}>
      {props.title ? <h3 className={styles['title']}>{props.title}</h3> : null}
      <div className={styles['content']}>
        <PieChart
          data={props.data}
          style={{
            width: props.commissionsBreakdownChart ? '161px' : '251px',
            height: props.commissionsBreakdownChart ? '161px' : '251px',
            border: '1px solid #B59566',
            padding: props.downloadingReport ? '0' : '30px',
            borderRadius: '50%',
            boxShadow: '0px 10px 17px -5px rgba(18, 43, 94, 0.25)',
          }}
          startAngle={210}
          animate
        />
        <ul className={styles['content-list']}>
          {props.data.map((dataItem) => (
            <li style={{ color: dataItem.color }} key={uuidv4()}>
              <h4>{dataItem.title}</h4>
              <p>
                {dataItem.value.toLocaleString()} {props.currency} (
                {dataItem.percentage}%)
              </p>
            </li>
          ))}
        </ul>
        {/* <div>
          <h2>
            {Math.round(
              (revenueInsights.total_number_of_closed_deals /
                revenueInsights.total_number_of_deals) *
                100
            )}
            %
          </h2>
          <h5>
            {revenueInsights.total_number_of_closed_deals} {t('closedDeals')}
          </h5>
          <span>
            / {revenueInsights.total_number_of_deals} {t('totalDeals')}
          </span>
        </div> */}
      </div>
      <div className={styles['footer']}>
        <h4>{props.totalSalesTitle}</h4>
        <p>
          {props.totalSales.toLocaleString()} {props.currency}
        </p>
      </div>
    </div>
  );
}

export default AnalyticsPieChartCard;
