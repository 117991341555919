/**
 * @author Salma Hefnawy
 * @date 2024-08-19
 * @description Implementation of user context
 * @filename user-context.ts
 */
import { UserInterface } from '@orascom/api-interfaces';
import * as React from 'react';

/**
 * user context that holds under user creation.
 */

export const USER_CONTEXT: React.Context<{
  /**
   * created user from BE.
   */
  user?: UserInterface | null;

  /**
   *for refetching user
   *
   * @type {(user: UserInterface | null) => void}
   */
  setUser?: (user: UserInterface | null) => void;

  /**
   * a boolean which is true if the user is loading.
   */
  isLoading?: boolean;
}> = React.createContext({});
