import { createContext } from 'react';

interface CompareUnitsContextProps {
  compareUnitsIds: number[] | undefined;
  addUnitToCompare: (unitId: number) => void;
  removeUnitFromCompare: (unitId: number) => void;
  clearCompareUnits: () => void;
  showExceedLimitError: boolean;
}

const CompareUnitsContext = createContext<CompareUnitsContextProps | undefined>(
  undefined
);

export default CompareUnitsContext;
