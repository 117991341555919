import styles from './modal.module.scss';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from '../../../assets/icons/close.svg';

export interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  title?: string;
  headerExtraChildren?: React.ReactNode;
  bodyBackgroundClass?: string;
  broker?: boolean;
  Footer?: React.ReactNode;
  fixedFooter?: boolean;
  hideHeaderBorder?: boolean;
  customMaxWidth?: number;
  customBackground?: string;
  overlapHeader?: boolean;
  sideModal?: boolean;
  hideCloseButton?: boolean;
  textOnlyHeader?: boolean;
  sideModalWidth?: boolean;
}
export const Modal = ({
  show,
  children,
  onClose,
  title,
  headerExtraChildren,
  bodyBackgroundClass,
  broker,
  Footer,
  fixedFooter,
  hideHeaderBorder,
  customMaxWidth,
  customBackground,
  overlapHeader,
  sideModal,
  hideCloseButton,
  textOnlyHeader,
  sideModalWidth,
}: ModalProps): JSX.Element => {
  const [docState, setDocState] = useState<Document | null>(null);

  useEffect(() => {
    setDocState(document);
  }, []);

  const handleCloseClick = () => {
    onClose();
  };

  const fixedFooterClass =
    Footer && fixedFooter ? styles['has-fixed-footer'] : '';
  const hideBorderClass = hideHeaderBorder ? styles['hide-header-border'] : '';
  const modalDOMElement = docState?.getElementById('modal')!;

  const absoluteHeaderClass = overlapHeader ? styles['absolute-header'] : '';
  return (
    <>
      {docState && show
        ? createPortal(
            <div
              className={`${styles['wrapper']} ${fixedFooterClass}`}
              onClick={handleCloseClick}
              onKeyDown={handleCloseClick}
            >
              <section
                role="dialog"
                aria-modal="true"
                className={`${styles['content']} modal-content ${
                  sideModal && styles['content--side']
                } ${sideModalWidth && styles['content--filters']}
                }
                  ${customBackground ? styles['custom-background'] : ''}`}
                // do not close modal if anything inside modal content is clicked
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  maxWidth: customMaxWidth ? customMaxWidth + 'px' : '',
                  background: customBackground ?? '',
                  backgroundColor: customBackground ?? '',
                }}
              >
                <header
                  className={`${styles['header']}
                    ${absoluteHeaderClass}
                    ${
                      broker && styles['header-broker']
                    }  modal-header ${hideBorderClass}
                     ${customBackground ? styles['remove-background'] : ''}
                     `}
                >
                  <div
                    className={`
                    ${
                      textOnlyHeader
                        ? styles['header--text']
                        : styles['header--info']
                    }
                    `}
                  >
                    <span>{title}</span>
                    {headerExtraChildren}
                  </div>
                  {!hideCloseButton ? (
                    <button className="close-button" onClick={handleCloseClick}>
                      <img src={CloseIcon} alt="" role="presentation" />
                    </button>
                  ) : null}
                </header>
                <div
                  className={`${styles['body']} ${
                    sideModal && styles['body--side']
                  } ${bodyBackgroundClass ?? ''} ${
                    broker && styles['body-broker']
                  } ${customBackground ? styles['remove-background'] : ''}`}
                >
                  {children}
                </div>
                {Footer}
              </section>
            </div>,
            modalDOMElement
          )
        : null}
    </>
  );
};

export default Modal;
