/* eslint-disable import/no-extraneous-dependencies */
import { News as NewsUtils } from '../../utils/news-events';
import CircularArrow from '../../components/common/circular-arrow/circular-arrow';
import styles from './news-events.module.scss';
import { useEffect, useState } from 'react';
import { PaginationInterface } from '@orascom/api-interfaces';
import { NewsInterface } from '../../definitions/interfaces/news-events';
import { Loader } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from '../../api/routes';

export function NewsEvents() {
  const [isNews, setIsNews] = useState(true);
  const [cards, setCards] = useState<NewsInterface[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);

  const { t } = useTranslation();

  const unitsCurrentPage = paginationData?.current_page
    ? paginationData.current_page + 1
    : 1;
  const unitsPage = (page?: number) => {
    return page ?? unitsCurrentPage;
  };

  const loadNewsEventsHandler = (
    loadMore: boolean,
    isEvent: '1' | '0',
    page?: number
  ) => {
    if (loadMore) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }
    NewsUtils.getNewsEvents(unitsPage(page), 6, isEvent)
      .then((res) => {
        if (loadMore) {
          setCards((prevState) => {
            return [...prevState, ...res.data];
          });
        } else {
          setCards(res.data);
        }
        setPaginationData(res.meta);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    loadNewsEventsHandler(false, '0', 1);
  }, []);

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('newsEvents')}</h1>
      <div className={styles['tabs']}>
        <button
          className={`${styles['tab']} ${isNews ? styles['tab--active'] : ''}`}
          onClick={() => {
            setIsNews(true);
            loadNewsEventsHandler(false, '0', 1);
          }}
        >
          {t('news')}
        </button>
        <button
          className={`${styles['tab']} ${!isNews ? styles['tab--active'] : ''}`}
          onClick={() => {
            setIsNews(false);
            loadNewsEventsHandler(false, '1', 1);
          }}
        >
          {t('events')}
        </button>
      </div>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className={styles['flex']}>
            {cards.map((c) => (
              <Link
                to={ROUTES['NewsEventsDetails'].getPath?.(c.slug)}
                key={uuidv4()}
                className={styles['event']}
              >
                <div className={styles['flex-child']}>
                  <img
                    src={c.image}
                    alt={c.title}
                    className={styles['image']}
                    loading="lazy"
                  />
                  <div className={styles['content']}>
                    <h2>{c.title}</h2>
                    <p>{c.short_description}</p>
                    <span>
                      {new Date(c.date * 1000).toLocaleDateString('default', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </span>
                  </div>
                </div>

                <div className={styles['arrow']}>
                  <CircularArrow />
                </div>
              </Link>
            ))}
          </div>

          {paginationData?.last_page &&
            paginationData?.current_page &&
            paginationData?.last_page > paginationData?.current_page && (
              <div className={styles['load-more']}>
                <button
                  className="btn btn--navy"
                  disabled={loadingMore}
                  onClick={() =>
                    loadNewsEventsHandler(true, `${isNews ? '0' : '1'}`)
                  }
                >
                  {t('loadMore')} {isNews ? t('news') : t('events')}
                </button>
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default NewsEvents;
