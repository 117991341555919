import { DestinationEducation as DestinationEducationInterface } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import styles from './destination-education.module.scss';

export interface DestinationEducationProps {
  education: DestinationEducationInterface[];
}

export function DestinationEducation({
  education,
}: Readonly<DestinationEducationProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      <h3 className={styles['title']}>{t('educationalHub')}</h3>
      <div className={styles['grid']}>
        {Boolean(education.length) &&
          education.map((e) => (
            <div key={e.id} className={`card ${styles['education']}`}>
              <img
                src={e.icon}
                alt=""
                role="presentation"
                className={styles['icon']}
                loading="lazy"
              />
              <h4 className={styles['label']}>{e.title}</h4>
              <p className={styles['description']}>{e.description}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default DestinationEducation;
