import './circular-arrow.scss';

interface CircularArrowProps {
  left?: boolean;
  rotated?: boolean;
}

export function CircularArrow(props: Readonly<CircularArrowProps>) {
  const arrowClasses = `circular-arrow ${
    props.rotated ? 'circular-arrow__rotated' : ''
  }`;
  return (
    <button className={arrowClasses}>
      <div className="circular-arrow__circle">
        {props.left ? (
          <svg
            width="7"
            height="11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m5.302.948-4.25 4.25 4.25 4.25"
              stroke="#003A70"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="7"
            height="11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1.699.949 4.25 4.25-4.25 4.25"
              stroke="#003A70"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </button>
  );
}

export default CircularArrow;
