import { FiltersUnits } from '@orascom/broker-sales-man-common-components';
import { useTranslation } from 'react-i18next';
import { Unit as UnitUtils } from '../../utils/unit';
import { Filter as FilterApi } from '../../utils/filter';
import { ROUTES } from '../../api/routes';

export function ResaleUnits() {
  const { t } = useTranslation();

  return (
    <FiltersUnits
      title={t('resaleUnits')}
      onResale={true}
      getUnitFilters={FilterApi.getUnitFilters}
      getUnits={UnitUtils.getUnits}
      comparePropertiesPath={ROUTES['CompareProperties'].path}
      unitDetailsPath={ROUTES['UnitDetails'].getPath}
      addSavedUnit={UnitUtils.addSavedUnit}
      deleteSavedUnit={UnitUtils.deleteSavedUnit}
      portal="broker"
    />
  );
}

export default ResaleUnits;
