import styles from './login-component.module.scss';
import { Formik, Form, Field } from 'formik';
import { useState, useContext, useEffect } from 'react';
import OpenedEyeIcon from '../../assets/icons/eye-opened.svg';
import ClosedEyeIcon from '../../assets/icons/eye-closed.svg';
import logo from '../../assets/icons/logo-horizontal.png';
import { Link, To, useNavigate } from 'react-router-dom';
import AuthWrapper from '../auth-wrapper/auth-wrapper';
import { useTranslation } from 'react-i18next';
import { USER_CONTEXT } from '../../contexts/user-context';
import * as yup from 'yup';

export interface LoginComponentProps {
  subtitle?: string;
  invalidCredentials: string;
  forgetPasswordRoute: To;
  dashboardRoute: To;
  submitHandler: (
    values: {
      email: string;
      password: string;
    },
    setSubmitting: (arg: boolean) => void
  ) => void;
}

export function LoginComponent(props: Readonly<LoginComponentProps>) {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);
  const navigate = useNavigate();

  const schema = yup.object({
    email: yup
      .string()
      .email(t('pleaseEnterValidEmail'))
      .required(t('required')),
    password: yup.string().required(t('required')),
  });

  useEffect(() => {
    if (userContext.user) {
      navigate(props.dashboardRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.user]);
  return (
    <AuthWrapper>
      <div className={styles['wrapper']}>
        <div className={styles['heading']}>
          <img
            src={logo}
            alt=""
            role="presentation"
            className={styles['logo']}
          />
          <h2 className={styles['subtitle']}>{props.subtitle}</h2>
        </div>
        <h1>{t('signIn')}</h1>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) =>
            props.submitHandler(values, setSubmitting)
          }
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <div className="form__input">
                <label htmlFor="email">{t('email')}</label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder="email@example.com"
                />
                {errors?.email && (
                  <div className="form__error">{errors.email}</div>
                )}
              </div>
              <div className="form__input">
                <label htmlFor="password">{t('password')}</label>
                <div className={styles['password-wrapper']}>
                  <Field
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="your password"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <img src={OpenedEyeIcon} alt="Open Eye" />
                    ) : (
                      <img src={ClosedEyeIcon} alt="Closed Eye" />
                    )}
                  </button>
                </div>
                {errors?.password && (
                  <div className="form__error">{errors.password}</div>
                )}

                {props.invalidCredentials && (
                  <p className="form__error form__error--invalid">
                    {props.invalidCredentials}
                  </p>
                )}
              </div>
              <div className={styles['anchor']}>
                <Link to={props.forgetPasswordRoute} className="anchor">
                  {t('forgotPassword')}
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn--navy"
                disabled={isSubmitting}
              >
                {t('signIn')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </AuthWrapper>
  );
}
export default LoginComponent;
