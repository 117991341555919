import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';
import type { SetStateAction } from 'react';

export interface UnitsPdfDownloaderProps {
  downloadName: 'units-listing';
  setDownloading?: (value: SetStateAction<boolean>) => void;
  className: string;
  icon?: string;
  buttonText: string;
}
const UnitsPdfDownloader = ({
  downloadName,
  setDownloading,
  className,
  icon,
  buttonText,
}: UnitsPdfDownloaderProps) => {
  async function downloadUnits() {
    if (setDownloading) {
      setDownloading(true);
    }

    const downloadPage = document.getElementById(downloadName);
    const pdfDoc = await PDFDocument.create();
    if (downloadPage) {
      const canvasWidth = downloadPage.scrollWidth + 20;
      const canvasHeight = downloadPage.scrollHeight + 20;
      const canvas = await html2canvas(downloadPage, {
        width: canvasWidth,
        height: canvasHeight,
      });
      const imgDataUrl = canvas.toDataURL('image/png');

      const page = pdfDoc.addPage([canvas.width + 30, canvas.height + 60]);

      const img = await pdfDoc.embedPng(imgDataUrl);
      const { height } = page.getSize();

      page.drawImage(img, {
        x: 15,
        y: (height - canvas.height) / 2,
        width: canvas.width,
        height: canvas.height,
      });
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = downloadName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    if (setDownloading) {
      setDownloading(false);
    }
  }

  return (
    <button onClick={downloadUnits} className={className}>
      {buttonText}
      {icon ? (
        <span className="download-icon-wrapper">
          <img
            src={icon}
            role="presentation"
            alt=""
            className="download-icon"
            loading="lazy"
          />
        </span>
      ) : null}
    </button>
  );
};

export default UnitsPdfDownloader;
