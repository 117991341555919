import { UnitDetails as UnitDetailsUi } from '@orascom/broker-sales-man-common-components';
import { Unit as UnitUtils } from '../../utils/unit';
import { ROUTES } from '../../api/routes';

import {
  analyticsBackButtonCustomEvent,
  analyticsUnitBrochureCustomEvent,
  analyticsUnitSalesOfferDownloadCustomEvent,
  analyticsUnitSpecsCustomEvent,
  analyticsUnitSubmitInterestCustomEvent,
} from '../../utils/analytics-events';
export function UnitDetails() {
  return (
    <UnitDetailsUi
      getUnitDetails={UnitUtils.getUnitDetails}
      getUnitPaymentTerms={UnitUtils.getUnitPaymentTerms}
      primaryUnitsPath={ROUTES['PrimaryUnits'].path}
      portal="Broker"
      getSimilarUnits={UnitUtils.getSimilarUnits}
      similarUnitsGetPath={ROUTES['SimilarUnits'].getPath}
      unitDetailsGetPath={ROUTES['UnitDetails'].getPath}
      addSavedUnit={UnitUtils.addSavedUnit}
      deleteSavedUnit={UnitUtils.deleteSavedUnit}
      downloadOmanSalesOffer={UnitUtils.downloadOmanSalesOffer}
      analyticsUnitSalesOfferDownloadCustomEvent={
        analyticsUnitSalesOfferDownloadCustomEvent
      }
      analyticsUnitSubmitInterestCustomEvent={
        analyticsUnitSubmitInterestCustomEvent
      }
      analyticsUnitBrochureCustomEvent={analyticsUnitBrochureCustomEvent}
      analyticsUnitSpecsCustomEvent={analyticsUnitSpecsCustomEvent}
      comparePropertiesPath={ROUTES['CompareProperties'].path}
      unitDealGetPath={ROUTES['UnitDeal'].getPath}
      analyticsBackButtonCustomEvent={analyticsBackButtonCustomEvent}
    />
  );
}

export default UnitDetails;
