import { Network } from './network';
import {
  PaginationPayload,
  Payload,
  UserLoginResponse,
} from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { DocumentsInterface } from '../definitions/interfaces/documents';

export class User {
  public static getUserData(): Promise<Payload<UserLoginResponse>> {
    const url = `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getUserData.url}`;
    return Network.fetch(url, {
      method: ENDPOINTS.getUserData.method,
    });
  }

  public static getUserDocuments(): Promise<
    Payload<PaginationPayload<DocumentsInterface[]>>
  > {
    const url = `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getDocuments.url}`;
    return Network.fetch(url, {
      method: ENDPOINTS.getDocuments.method,
    });
  }
}
