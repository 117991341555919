import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import {
  debounce,
  Loader,
  MultiSelectDropdown,
} from '@orascom/common-components';
import { ROUTES } from '../../api/routes';
import styles from './my-deals.module.scss';
import { Deal as DealUtils, groupLeadsByBroker } from '../../utils/deal';
import {
  DealInterface,
  SaleInterface,
  statusesOptions,
} from '../../definitions/interfaces/deals.interface';
import BrokerDealsCard from '../../components/common/broker-deals-card/broker-deals-card';
import DealCard from '../../components/deal-card/deal-card';
import searchIcon from '../../assets/icons/search.svg';
import InfoIcon from '../../assets/icons/info.svg';
import {
  analyticsActiveDealsCustomEvent,
  analyticsNewtDealCustomEvent,
  analyticsPastDealsCustomEvent,
} from '../../utils/analytics-events';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { CommonEventParameters } from '@orascom/utils';

import { OptionValue } from '@orascom/api-interfaces';
import pendingIcon from '../../assets/icons/bullet-point.png';
import approvedIcon from '../../assets/icons/approved.svg';
import closedIcon from '../../assets/icons/closed.svg';
import actionIcon from '../../assets/icons/action.svg';

export function MyDeals() {
  const [activeTab, setActiveTab] = useState<'active' | 'past'>('active');
  const [loading, setLoading] = useState(true);
  const [loadingPastDeals, setLoadingPastDeals] = useState(true);

  const [deals, setDeals] = useState<DealInterface[]>([]);
  const [pastDeals, setPastDeals] = useState<DealInterface[]>([]);
  const [duplicateDeals, setDuplicateDeals] = useState<DealInterface[]>([]);
  const [pastDuplicateDeals, setPastDuplicateDeals] = useState<DealInterface[]>(
    []
  );

  const [searchPhone, setSearchPhone] = useState('');

  const [selectedStatuses, setSelectedStatuses] = useState<OptionValue[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<
    OptionValue[]
  >([]);
  const [filteredActiveDeals, setFilteredActiveDeals] = useState<
    DealInterface[]
  >([]);
  const [filteredActiveDuplicateDeals, setFilteredActiveDuplicateDeals] =
    useState<DealInterface[]>([]);

  const [filteredPastDeals, setFilteredPastDeals] = useState<DealInterface[]>(
    []
  );
  const [filteredPastDuplicateDeals, setFilteredPastDuplicateDeals] = useState<
    DealInterface[]
  >([]);

  const uniqueDestinations = Array.from(
    new Set(deals.flatMap((deal) => deal.destinationName))
  );

  const destinationOptions = uniqueDestinations.map((destination) => ({
    value: destination,
    label: destination,
  }));

  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    DealUtils.getLeads()
      .then((res) => {
        setDeals(res.data.active_deals);
        setPastDeals(res.data.past_deals);
      })
      .catch((err) => {
        toast.error('failure to load leads');
        console.log(err);
      })
      .finally(() => setLoading(false));

    DealUtils.getDuplicateLeads()
      .then((res) => {
        setDuplicateDeals(res.data.active_deals);
        setPastDuplicateDeals(res.data.past_deals);
      })
      .catch((err) => {
        toast.error('failure to load duplicate leads');
        console.log(err);
      })
      .finally(() => setLoadingPastDeals(false));
  }, []);

  const activeGroupedDealsByBroker = groupLeadsByBroker(filteredActiveDeals);
  const activeDuplicateGroupedDealsByBroker = groupLeadsByBroker(
    filteredActiveDuplicateDeals
  );
  const pastGroupedDealsByBroker = groupLeadsByBroker(filteredPastDeals);

  const filterDealsByStatus = (filteringDeals: DealInterface[]) => {
    return filteringDeals?.filter((deal: DealInterface) =>
      selectedStatuses.some((selectedStatus) =>
        deal?.sales?.length
          ? deal?.sales.some(
              (sale: SaleInterface) =>
                sale?.deal_status?.replace(/_/g, ' ') ===
                selectedStatus.value.toString().toLowerCase()
            )
          : deal?.lead_status?.replace(/_/g, ' ') ===
            selectedStatus.value.toString().toLowerCase()
      )
    );
  };

  const resetStatusFilters = () => {
    setFilteredActiveDeals(deals);
    setFilteredActiveDuplicateDeals(duplicateDeals);
    setFilteredPastDeals(pastDeals);
    setFilteredPastDuplicateDeals(pastDuplicateDeals);
  };
  const filterByPhone = (dealsToFilter: DealInterface[]) => {
    return searchPhone
      ? dealsToFilter.filter((deal) => deal?.phone?.includes(searchPhone))
      : dealsToFilter;
  };

  const filterByDestinations = (dealsToFilter: DealInterface[]) => {
    return selectedDestinations.length > 0
      ? dealsToFilter.filter((deal) =>
          deal?.destinationName?.some((destination) =>
            selectedDestinations.some(
              (selected) => selected.value === destination
            )
          )
        )
      : dealsToFilter;
  };

  useEffect(() => {
    if (selectedStatuses.length) {
      setFilteredActiveDeals(
        filterByDestinations(filterByPhone(filterDealsByStatus(deals)))
      );
      setFilteredActiveDuplicateDeals(
        filterByDestinations(filterByPhone(filterDealsByStatus(duplicateDeals)))
      );
      setFilteredPastDeals(
        filterByDestinations(filterByPhone(filterDealsByStatus(duplicateDeals)))
      );
      setFilteredPastDuplicateDeals(
        filterByDestinations(
          filterByPhone(filterDealsByStatus(pastDuplicateDeals))
        )
      );
    } else {
      setFilteredActiveDeals(filterByDestinations(filterByPhone(deals)));
      setFilteredActiveDuplicateDeals(
        filterByDestinations(filterByPhone(duplicateDeals))
      );
      setFilteredPastDeals(filterByDestinations(filterByPhone(pastDeals)));
      setFilteredPastDuplicateDeals(
        filterByDestinations(filterByPhone(pastDuplicateDeals))
      );
    }

    return () => resetStatusFilters();
  }, [
    selectedStatuses,
    selectedDestinations,
    searchPhone,
    deals,
    duplicateDeals,
    pastDeals,
    pastDuplicateDeals,
  ]);

  const searchDeals = (value: string) => {
    setSearchPhone(value);
  };
  const debouncedOnChange = debounce(searchDeals, 500);

  let numberOfActiveDeals;
  let numberOfPastDeals;

  if (
    activeGroupedDealsByBroker.length > 1 ||
    activeDuplicateGroupedDealsByBroker.length
  ) {
    numberOfActiveDeals =
      activeGroupedDealsByBroker.reduce((acc, curr) => acc + curr.length, 0) +
      activeDuplicateGroupedDealsByBroker.reduce(
        (acc, curr) => acc + curr.length,
        0
      );
  } else {
    numberOfActiveDeals =
      filteredActiveDeals.length + filteredActiveDuplicateDeals.length;
  }

  if (pastGroupedDealsByBroker.length > 1) {
    numberOfPastDeals = pastGroupedDealsByBroker.reduce(
      (acc, curr) => acc + curr.length,
      0
    );
  } else {
    numberOfPastDeals =
      filteredPastDeals.length + filteredPastDuplicateDeals.length;
  }

  if (loading || loadingPastDeals) {
    return <Loader />;
  }

  const pastUngroupedDeals = filteredPastDeals.length
    ? filteredPastDeals.map((deal) => (
        <div
          className={styles['duplicate-deal']}
          key={deal.lead_history_source_id}
        >
          <p className={styles['deal--hovered']}>
            <img src={InfoIcon} role="presentation" alt="" />
            <span>{t('This lead is rejected')}</span>
          </p>
          <DealCard
            key={deal.lead_source_id}
            deal={deal}
            status={
              deal.sales?.length ? deal.sales[0].deal_status : deal.lead_status
            }
            sales={deal.sales}
            pastDeal
          />
        </div>
      ))
    : null;

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
  };

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('myDeals')}</h1>
      <div className={styles['tabs']}>
        <button
          onClick={() => {
            analyticsActiveDealsCustomEvent(analyticsEventParams);
            setActiveTab('active');
          }}
          className={`${styles['tab']} ${
            activeTab === 'active' && styles['tab--active']
          }`}
        >
          {t('activeDeals')}
        </button>
        <button
          onClick={() => {
            analyticsPastDealsCustomEvent(analyticsEventParams);
            setActiveTab('past');
          }}
          className={`${styles['tab']} ${
            activeTab === 'past' && styles['tab--active']
          }`}
        >
          {t('pastDeals')}
        </button>
      </div>

      <div className={styles['search-wrapper']}>
        {' '}
        <div className={styles['search-input']}>
          <img src={searchIcon} alt="" role="presentation" />
          <input
            type="text"
            placeholder={t('searchLead')}
            onChange={(e) => debouncedOnChange(e.target.value)}
          />
        </div>
        <Link
          to={ROUTES['NewDeal'].path}
          className={styles['deal-anchor']}
          onClick={() => analyticsNewtDealCustomEvent(analyticsEventParams)}
        >
          {t('newDeal')}
        </Link>
      </div>

      <div className={styles['filters']}>
        <div className={styles['dropdown']}>
          <label htmlFor="status">Filter By</label>
          <MultiSelectDropdown
            name="status"
            options={statusesOptions}
            placeholder={t('status')}
            onChange={(value) => {
              setSelectedStatuses(value);
            }}
            className={styles['select']}
          />
          <MultiSelectDropdown
            name="destination"
            options={destinationOptions}
            placeholder={t('destination')}
            onChange={(value) => {
              setSelectedDestinations(value);
            }}
            className={styles['select']}
          />
        </div>

        <div className={styles['status-wrapper']}>
          <div className={styles['status']}>
            <img src={approvedIcon} alt="" role="presentation" />
            <p>{t('approved')}</p>
          </div>
          <div className={styles['status']}>
            <img src={actionIcon} alt="" role="presentation" />
            <p>{t('requiresAction')}</p>
          </div>
          <div className={styles['status']}>
            <img src={pendingIcon} alt="" role="presentation" />
            <p>{t('pending')}</p>
          </div>
          <div className={styles['status']}>
            <img src={closedIcon} alt="" role="presentation" />
            <p>{t('closed')}</p>
          </div>
        </div>
      </div>

      <div className={styles['count-wrapper']}>
        <p className={styles['count']}>
          {activeTab === 'active'
            ? `${numberOfActiveDeals} ${t('activeDeals')}`
            : `${numberOfPastDeals} ${t('pastDeals')}`}
        </p>
      </div>

      {activeTab === 'active' &&
        (activeGroupedDealsByBroker.length > 1 ? (
          <>
            {activeGroupedDealsByBroker.map((brokerDeals) => {
              const filteredBrokerDeals = brokerDeals.filter((deal) =>
                deal?.phone?.includes(searchPhone)
              );
              if (filteredBrokerDeals.length === 0) {
                return null;
              }
              return (
                <BrokerDealsCard
                  key={uuidv4()}
                  brokerDeals={filteredBrokerDeals}
                />
              );
            })}

            {/* {activeDuplicateGroupedDealsByBroker.length
              ? activeDuplicateGroupedDealsByBroker.map((brokerDeals) => {
                  const filteredBrokerDeals = brokerDeals.filter((deal) =>
                    deal?.phone?.includes(searchPhone)
                  );

                  if (filteredBrokerDeals.length === 0) {
                    return null;
                  }
                  return (
                    <BrokerDealsCard
                      key={uuidv4()}
                      brokerDeals={filteredBrokerDeals}
                      duplicateDeals
                    />
                  );
                })
              : null} */}
          </>
        ) : (
          <div className={styles['deals']}>
            {filteredActiveDeals.length
              ? filteredActiveDeals.map((deal) => (
                  <DealCard
                    key={deal.lead_source_id}
                    deal={deal}
                    status={
                      deal.sales?.length
                        ? deal.sales[0].deal_status
                        : deal.lead_status
                    }
                    sales={deal.sales}
                  />
                ))
              : null}

            {filteredActiveDuplicateDeals.length
              ? filteredActiveDuplicateDeals.map((deal) => (
                  <div
                    className={styles['duplicate-deal']}
                    key={deal.lead_history_source_id}
                  >
                    <p className={styles['deal--hovered']}>
                      <img src={InfoIcon} role="presentation" alt="" />
                      <span>{t('inaccessibleLead')}</span>
                    </p>
                    <DealCard
                      key={deal.lead_history_source_id}
                      deal={deal}
                      status={
                        deal.sales?.length
                          ? deal.sales[0].deal_status
                          : deal.lead_status
                      }
                      sales={deal.sales}
                      duplicateDeal
                    />
                  </div>
                ))
              : null}
            {!filteredActiveDeals.length &&
              !filteredActiveDuplicateDeals.length && <h1>No Active Deals</h1>}
          </div>
        ))}
      <div className={styles['deals']}>
        {activeTab === 'past' &&
          (pastGroupedDealsByBroker.length > 1
            ? pastGroupedDealsByBroker.map((brokerDeals) => {
                const filteredBrokerDeals = brokerDeals.filter((deal) =>
                  deal?.phone?.includes(searchPhone)
                );

                if (filteredBrokerDeals.length === 0) {
                  return null;
                }
                return (
                  <BrokerDealsCard
                    key={uuidv4()}
                    brokerDeals={filteredBrokerDeals}
                  />
                );
              })
            : pastUngroupedDeals)}

        {activeTab === 'past' && filteredPastDuplicateDeals.length > 0
          ? filteredPastDuplicateDeals.map((deal) => (
              <div
                className={styles['duplicate-deal']}
                key={deal.lead_history_source_id}
              >
                <p className={styles['deal--hovered']}>
                  <img src={InfoIcon} role="presentation" alt="" />
                  <span>{t('This lead is rejected')}</span>
                </p>
                <DealCard
                  key={deal.lead_source_id}
                  deal={deal}
                  status={
                    deal.sales?.length
                      ? deal.sales[0].deal_status
                      : deal.lead_status
                  }
                  sales={deal.sales}
                  duplicateDeal
                  pastDeal
                />
              </div>
            ))
          : null}

        {activeTab === 'past' &&
        !filteredPastDeals.length &&
        !filteredPastDuplicateDeals.length &&
        !pastGroupedDealsByBroker.length ? (
          <h1>no past deals</h1>
        ) : null}
      </div>
    </div>
  );
}

export default MyDeals;
