import { DestinationFacilities as DestinationFacilitiesInterface } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import styles from './destination-facilities.module.scss';

export interface DestinationFacilitiesProps {
  facilities: DestinationFacilitiesInterface[];
}

export function DestinationFacilities({
  facilities,
}: Readonly<DestinationFacilitiesProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      {Boolean(facilities.length) && (
        <>
          <h3 className={styles['title']}>{t('facilitiesAmenities')}</h3>
          <div className={styles['grid']}>
            {facilities.map((f) => (
              <div key={f.id} className={`card ${styles['facility']}`}>
                <div className={styles['icon-wrapper']}>
                  <img
                    src={f.icon}
                    alt=""
                    role="presentation"
                    className={styles['icon']}
                    loading="lazy"
                  />
                </div>
                <h4 className={styles['label']}>{f.title}</h4>
                <p className={styles['description']}>{f.description}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default DestinationFacilities;
