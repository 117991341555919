import { Filter as FilterAPI } from '../api/filter';
import { getPayloadData, handleError } from '@orascom/utils';

/**
 * group of Filter helpers functionalities.
 */

export class Filter {
  public static getUnitFilters(searchParams?: URLSearchParams) {
    return FilterAPI.getUnitFilters(searchParams)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
