/* eslint-disable @nx/enforce-module-boundaries */
import React, { useContext } from 'react';
import styles from './analytics-filter.module.scss';
import { MultiSelectDropdown } from '@orascom/common-components';
import { FilterValue, OptionValue } from '@orascom/api-interfaces';
import { USER_CONTEXT } from 'libs/broker-sales-man-common-components/src/contexts/user-context';
import {
  AnalyticsFilterSalesManagers,
  commissionStatusOptions,
} from '../../utils/analytics';
import { useTranslation } from 'react-i18next';

export interface AnalyticsFilterProps {
  countriesFilter?: boolean;
  buyerCountry?: boolean;
  unitSizeFilter?: boolean;
  comissionsStatus?: boolean;

  filterBrokerages?: FilterValue[];
  filterBrokerAgents?: FilterValue[];
  filterDestinations?: FilterValue[];
  filterUnitTypes?: FilterValue[];
  filterSalesManagers?: AnalyticsFilterSalesManagers[];

  handleSelectedSalesManagers?(value: OptionValue[]): void;
  handleSelectBrokerages?(value: OptionValue[]): void;
  handleSelectBrokerAgents?(value: OptionValue[]): void;
  handleSelectedDestinations?(value: OptionValue[]): void;
  handleSelectedUnitTypes?(value: OptionValue[]): void;
  handleSelectedStatuses?(value: OptionValue[]): void;

  destinationsLoader?: boolean;
  unitsLoader?: boolean;

  filterByLabel?: boolean;
}

export function AnalyticsFilter(props: Readonly<AnalyticsFilterProps>) {
  const userContext = useContext(USER_CONTEXT);
  const { t } = useTranslation();

  let destinationOptions,
    unitTypeOptions,
    brokerAgentOptions,
    brokeragesCompanies,
    salesAgentsOptions;

  if (props.filterBrokerages) {
    brokeragesCompanies = props.filterBrokerages.map((company) => {
      return {
        label: `${company.name} ${
          company.country_name ? `- ${company.country_name}` : ''
        } `,
        value: company.id,
      };
    });
  }
  if (props.filterDestinations) {
    destinationOptions = props.filterDestinations.map((destination) => {
      return {
        label: destination.name,
        value: destination.id,
      };
    });
  }
  if (props.filterUnitTypes) {
    unitTypeOptions = props.filterUnitTypes.map((unitType) => {
      return {
        label: unitType.name,
        value: unitType.id,
      };
    });
  }
  if (props.filterBrokerAgents) {
    brokerAgentOptions = props.filterBrokerAgents.map((broker) => {
      return {
        label: broker.name,
        value: broker.id,
      };
    });
  }

  if (props.filterSalesManagers) {
    salesAgentsOptions = props.filterSalesManagers.map((manager) => {
      return {
        label: manager.name,
        value: manager.id,
      };
    });
  }

  return (
    <div className={styles['wrapper']}>
      {props.filterByLabel ? (
        <p className={styles['title']}>{t('filterBy')}</p>
      ) : null}
      {props.filterBrokerages && brokeragesCompanies && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Brokererages"
          onChange={(val) => {
            if (props.handleSelectBrokerages) {
              props.handleSelectBrokerages(val);
            }
          }}
          options={brokeragesCompanies}
        />
      )}
      {props.comissionsStatus && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Statuses"
          onChange={(val) => {
            if (props.handleSelectedStatuses) {
              props.handleSelectedStatuses(val);
            }
          }}
          options={commissionStatusOptions}
        />
      )}
      {props.countriesFilter && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Countries"
          onChange={(val) => {
            console.log(val);
          }}
          options={[]}
        />
      )}
      {props.filterDestinations && destinationOptions && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Destinations"
          options={destinationOptions}
          onChange={(val) => {
            if (props.handleSelectedDestinations) {
              props.handleSelectedDestinations(val);
            }
          }}
          isLoading={props.destinationsLoader}
        />
      )}
      {props.unitSizeFilter && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Unit Sizes"
          onChange={(val) => {
            console.log(val);
          }}
          options={[]}
        />
      )}
      {props.filterUnitTypes && unitTypeOptions && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Unit Types"
          options={unitTypeOptions}
          onChange={(val) => {
            if (props.handleSelectedUnitTypes) {
              props.handleSelectedUnitTypes(val);
            }
          }}
          isLoading={props.unitsLoader}
        />
      )}
      {props.buyerCountry && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Buyers' Countries"
          onChange={(val) => {
            console.log(val);
          }}
          options={[]}
        />
      )}
      {props.filterBrokerAgents &&
        brokerAgentOptions &&
        userContext.user?.is_manager && (
          <MultiSelectDropdown
            className={styles['filter-container']}
            placeholder="All Broker Agents"
            onChange={(val) => {
              if (props.handleSelectBrokerAgents) {
                props.handleSelectBrokerAgents(val);
              }
            }}
            options={brokerAgentOptions}
          />
        )}
      {props.filterSalesManagers && salesAgentsOptions && (
        <MultiSelectDropdown
          className={styles['filter-container']}
          placeholder="All Sales Managers"
          onChange={(val) => {
            if (props.handleSelectedSalesManagers) {
              props.handleSelectedSalesManagers(val);
            }
          }}
          options={salesAgentsOptions}
        />
      )}
    </div>
  );
}

export default AnalyticsFilter;
