import { Field, Form, Formik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../auth-wrapper/auth-wrapper';
import styles from './set-password-component.module.scss';
import { useState } from 'react';
import OpenedEyeIcon from '../../assets/icons/eye-opened.svg';
import ClosedEyeIcon from '../../assets/icons/eye-closed.svg';
import logo from '../../assets/icons/logo.svg';
import { SetPasswordInput } from '@orascom/api-interfaces';
import { useNavigate } from 'react-router-dom';
export interface SetPasswordUIProps {
  submitHandler: (setPasswordInput: SetPasswordInput) => void;
  loginPath: string;
}
/**
 * minimum 8 characters, at least one number, at least one lower case character and at least one upper case character
 */
const passwordValidationRegex =
  /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,64})$/;

export function SetPasswordComponent(props: Readonly<SetPasswordUIProps>) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const { name, email, token } = queryString.parse(window.location.search);

  const schema = yup.object({
    password: yup
      .string()
      .required(t('required'))
      .matches(passwordValidationRegex, t('passwordValidationErrorMsg')),
  });

  if (!token || !email || !name) {
    navigate(props.loginPath);
  }

  return (
    <AuthWrapper>
      <div className={styles['wrapper']}>
        <img src={logo} alt="" role="presentation" />
        <h1>
          {t('welcome')} {name}, {t('setUpYourNewPassword')}
        </h1>
        <Formik
          initialValues={{ password: '' }}
          validationSchema={schema}
          onSubmit={(values) => {
            if (token && email) {
              const setPasswordInput: SetPasswordInput = {
                email: email.toString(),
                password: values.password,
                token: token.toString(),
              };
              props.submitHandler(setPasswordInput);
            }
          }}
        >
          {({ errors }) => (
            <Form>
              <div className="form__input">
                <label htmlFor="password">{t('password')}</label>
                <div className={styles['password-wrapper']}>
                  <Field
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="your password"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <img src={OpenedEyeIcon} alt="Open Eye" />
                    ) : (
                      <img src={ClosedEyeIcon} alt="Closed Eye" />
                    )}
                  </button>
                </div>
                {errors?.password && (
                  <div className="form__error">{errors.password}</div>
                )}
              </div>
              <button className="btn btn--navy">{t('proceed')}</button>
            </Form>
          )}
        </Formik>
      </div>
    </AuthWrapper>
  );
}

export default SetPasswordComponent;
