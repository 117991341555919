import {
  OptionValue,
  PaginationInterface,
  UnitInterface,
} from '@orascom/api-interfaces';
import { Unit as UnitUtils } from '../../utils/unit';
import { Loader, MultiSelectDropdown } from '@orascom/common-components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Destination as DestinationUtils } from '../../utils/destination.utils';
import noteIcon from '../../assets/icons/note.svg';
import styles from './saved-units.module.scss';
import { analyticsSavedUnitDestinationFilterCustomEvent } from '../../utils/analytics-events';
import { gtagFiltersParser } from '@orascom/utils';
import { useLocation } from 'react-router-dom';
import {
  CompareButton,
  UnitsListing,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { ROUTES } from '../../api/routes';

export function SavedUnits() {
  const [savedUnits, setSavedUnits] = useState<UnitInterface[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<
    OptionValue[]
  >([]);
  const [availableDestinations, setAvailableDestinations] = useState<
    OptionValue[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [propertyRemoved, setPropertyRemoved] = useState(false);
  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();

  const { t } = useTranslation();
  const unitsCurrentPage = paginationData?.current_page
    ? paginationData.current_page + 1
    : 1;
  const unitsPage = (page?: number) => {
    return page ?? unitsCurrentPage;
  };
  const loadUnitsHandler = (loadMore: boolean, page?: number) => {
    setLoadingMore(true);
    if (!loadMore) {
      setLoading(true);
    }
    UnitUtils.getSavedUnits(unitsPage(page), 12, selectedDestinations)
      .then((res) => {
        if (loadMore) {
          setSavedUnits((prevState) => {
            return [...prevState, ...res.data];
          });
        } else {
          setSavedUnits(res.data);
        }
        setPaginationData(res.meta);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoadingMore(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    DestinationUtils.listDestinations().then((res) => {
      const destinationOptions = res.map((d) => {
        return {
          label: String(d.name),
          value: d.slug ?? '',
        };
      });
      setAvailableDestinations(destinationOptions);
    });
  }, []);

  useEffect(() => {
    loadUnitsHandler(false, 1);
  }, [selectedDestinations, propertyRemoved]);

  const handleRemoveFromSavedUnits = (id: number) => {
    UnitUtils.deleteSavedUnit(id)
      .then(() => {
        setPropertyRemoved(!propertyRemoved);
        // toast.success(t('unitRemovedFromWishlist') as string);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>Saved Units</h1>
      <p className={styles['note']}>
        <img src={noteIcon} alt="" role="presentation" />
        {t('savedUnitsNote')}
      </p>
      <MultiSelectDropdown
        options={availableDestinations}
        placeholder={t('destination')}
        onChange={(value) => {
          analyticsSavedUnitDestinationFilterCustomEvent({
            name: `${gtagFiltersParser(value)}`,
            userId: userContext.user?.id.toString(),
            timestamp: Date.now().toString(),
            portal: 'Broker',
            pageName: location.pathname,
          });
          setSelectedDestinations(value);
        }}
        className={styles['select']}
      />
      {loading && <Loader />}
      {Boolean(savedUnits?.length) && !loading && (
        <>
          <div className={styles['total-count']}>
            {paginationData?.total} {t('availableSavedUnits')}
          </div>

          <UnitsListing
            id="units-listing"
            units={savedUnits}
            handleRemove={handleRemoveFromSavedUnits}
            unitDetailsPath={ROUTES['UnitDetails'].getPath}
            addSavedUnit={UnitUtils.addSavedUnit}
            deleteSavedUnit={UnitUtils.deleteSavedUnit}
            portal="broker"
          />
        </>
      )}
      {!savedUnits?.length && <h1>{t('noUnitsToDisplay')}</h1>}
      {Boolean(savedUnits?.length) && !loading && (
        <div className={styles['pagination']}>
          <div className={styles['info']}>
            <hr className={styles['line']} />
            <div className={styles['count']}>
              {t('showing')} {savedUnits.length} {t('unitsOutOf')}{' '}
              {paginationData?.total}
            </div>
          </div>
          {paginationData?.last_page &&
            paginationData.current_page &&
            paginationData?.last_page > paginationData?.current_page && (
              <div className={styles['button']}>
                <button
                  className="btn btn--navy"
                  onClick={() => loadUnitsHandler(true)}
                  disabled={loadingMore}
                >
                  {t('loadMore')}
                </button>
              </div>
            )}
        </div>
      )}
      <CompareButton comparePropertiesPath={ROUTES['CompareProperties'].path} />
    </div>
  );
}

export default SavedUnits;
