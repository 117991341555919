import { useTranslation } from 'react-i18next';
import styles from './updates.module.scss';
import { useState } from 'react';
import { Loader } from '@orascom/common-components';
import { PaginationStyled } from '@orascom/broker-sales-man-common-components';
import useNotifications from '../../hooks/use-notifications';
import NotificationsUpdates from '../../components/notifications-wrapper/notifications-wrapper';

export function Updates() {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  const { notifications, loading } = useNotifications(currentPage, perPage);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('updates')}</h1>
      <div className={styles['flex']}>
        {Boolean(notifications?.data.length) &&
          notifications?.data.map((u) => (
            <NotificationsUpdates key={u.id} notification={u} />
          ))}
        {!notifications?.data.length && (
          <h3>{t('noNotificationsToDisplay')}</h3>
        )}
      </div>
      <div className={styles['pagination']}>
        {notifications ? (
          <PaginationStyled
            currentPage={currentPage}
            totalCount={notifications?.meta.total}
            pageSize={notifications?.meta.per_page}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Updates;
