import { Network } from './network';
import {
  PaginationPayload,
  Payload,
  UpdatesInterface,
} from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';

export class Updates {
  public static getUpdates(
    page: number,
    perPage: number,
    unSeen?: boolean
  ): Promise<Payload<PaginationPayload<UpdatesInterface[]>>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getRecentUpdates.url}`
    );
    url.searchParams.append('page', String(page));
    url.searchParams.append('per_page', String(perPage));
    if (unSeen) {
      url.searchParams.append('unread', '1');
    }

    const fetchURL = decodeURIComponent(url.href);
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getRecentUpdates.method,
    });
  }

  public static readNotifications(
    notificationsIds: number[],
    isRead = 1
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.readNotifications.url}`,
      {
        method: ENDPOINTS.readNotifications.method,
        body: JSON.stringify({
          notification_ids: notificationsIds,
          is_read: isRead,
        }),
      }
    );
  }
}
