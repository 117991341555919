import {
  Destination as DestinationInterface,
  DestinationEducation,
  DestinationFacilities,
  DestinationFAQ,
  DestinationProject,
  Location,
  Payload,
  PaginationPayload,
} from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';

export class Destination {
  /**
   * list all destinations data from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationInterface[]>}
   */
  public static listDestinations(): Promise<Payload<DestinationInterface[]>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.listDestinations.url}`,
      {
        method: ENDPOINTS.listDestinations.method,
      }
    );
  }

  /**
   * get all destination data from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationInterface>}
   * @memberof Destination
   */
  public static getDestinationInfo(
    destinationSlug: string
  ): Promise<Payload<DestinationInterface>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getDestinationInfo.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationInfo.method,
      }
    );
  }

  /**
   * get destination facilities by destination id
   *
   * @static
   * @param {number} destinationId
   * @returns {Promise<Payload<DestinationFacilities[]>>}
   *
   * @memberOf Destination
   */
  public static getDestinationFacilities(
    destinationSlug: string
  ): Promise<Payload<DestinationFacilities[]>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getDestinationFacilities.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationFacilities.method,
      }
    );
  }

  /**
   * get destination educational hubs by destination id
   *
   * @static
   * @param {number} destinationId
   * @returns {Promise<Payload<DestinationEducation[]>>}
   *
   * @memberOf Destination
   */
  public static getDestinationEducation(
    destinationSlug: string
  ): Promise<Payload<DestinationEducation[]>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getDestinationEducation.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationEducation.method,
      }
    );
  }

  /**
   * get all destination projects from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationProject[]>}
   * @memberof Destination
   */
  public static getDestinationProjects(
    destinationSlug: string
  ): Promise<Payload<DestinationProject[]>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getDestinationProjects.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationProjects.method,
      }
    );
  }

  /**
   * get all destination FAQs from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationFAQ[]>}
   * @memberof Destination
   */
  public static getDestinationFAQ(
    destinationSlug: string
  ): Promise<Payload<PaginationPayload<DestinationFAQ[]>>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getDestinationFAQ.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationFAQ.method,
      }
    );
  }

  /**
   * gets destination locations
   *
   * @static
   * @param {number} destinationId
   * @returns {Promise<Payload<Location[]>>}
   *
   * @memberOf Destination
   */
  public static getDestinationLocations(
    destinationSlug: string
  ): Promise<Payload<Location[]>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getDestinationLocations.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationLocations.method,
      }
    );
  }
}
