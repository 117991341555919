import { OptionValue } from '@orascom/api-interfaces';
import { format } from 'date-fns';

export enum CommissionsStatus {
  LATE = 1,
  PENDING = 2,
  CACHED = 3,
}
export const commissionStatusOptions = [
  { label: 'Late', value: CommissionsStatus.LATE },
  { label: 'Pending', value: CommissionsStatus.PENDING },
  { label: 'Cached', value: CommissionsStatus.CACHED },
];

export interface AnalyticsFilterSalesManagers {
  id: number;
  name: string;
  title: string;
}

export interface TimeStamps {
  timestampStart: string;
  timestampEnd: string;
}

export function getTimeStampsForAnalytics(): TimeStamps {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 365);
  const timestampStart = Math.floor(startDate.getTime() / 1000).toString();
  const endDate = new Date();
  const timestampEnd = Math.floor(endDate.getTime() / 1000).toString();

  return {
    timestampStart,
    timestampEnd,
  };
}
export const parseFiltersInUrl = (filters: OptionValue[]) => {
  return filters.map((filter) => filter.value.toString());
};

export function getChartData(
  period: string,
  data: LineGraphData
): { xAxisLabels: string[]; yAxisValues: number[]; label: string } {
  const count = data.daily_counts_breakdown;
  const xAxisLabels: string[] = [];
  const yAxisValues: number[] = [];
  const label = data.name;

  switch (period) {
    case 'lastyear':
    case 'last6months':
    case 'last90days':
      for (const item of count) {
        mapLineChartAxisAccordingToDuration(
          Number(item.total_count),
          xAxisLabels,
          yAxisValues,
          new Date(item.year, item.month - 1),
          'MMM yy'
        );
      }

      break;
    case 'lastmonth':
    case 'last30days':
    case 'last7days':
    case 'today':
    case 'yesterday':
      for (const item of count) {
        mapLineChartAxisAccordingToDuration(
          Number(item.total_count),
          xAxisLabels,
          yAxisValues,
          new Date(item.year, item.month - 1, item.day),
          'dd MMM'
        );
      }

      break;

    default:
      break;
  }

  return { xAxisLabels, yAxisValues, label };
}
const mapLineChartAxisAccordingToDuration = (
  sales_count: number,
  xAxisLabels: string[],
  yAxisValues: number[],
  formatDate: Date,
  formatString: string
) => {
  if (!xAxisLabels.includes(format(formatDate, formatString))) {
    xAxisLabels.push(format(formatDate, formatString));
    yAxisValues.push(sales_count);
  } else {
    yAxisValues[yAxisValues.length - 1] += sales_count;
  }
};

export interface LineGraphData {
  name: string;
  daily_counts_breakdown: DailyCountsBreakdown[];
}
export interface LoginsOverTime extends LineGraphData {
  total_logins: number;
  average_daily_logins: number;
  average_weekly_logins: number;
  average_monthly_logins: number;
}
export interface SalesPerformanceBreakdown extends LineGraphData {
  sales_count: number;
  sales_revenues: {
    sales_revenues: string;
    currency: string;
  };
}
export interface LeadPerformance extends LineGraphData {
  leads_count: number;
}
export interface DailyCountsBreakdown {
  year: number;
  month: number;
  day: number;
  total_count: string;
}
export const MonthsNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
