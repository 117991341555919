/**
 * @author Salma Hefnawy
 * @date 2023-10-07
 * @description implementation of deals related API calls.
 * @filename deal.ts
 */
import { Network } from './network';
import { Common, Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';

import {
  MeetingInterface,
  NewDealFormInterface,
  NewMeetingInterface,
  ShareReservationLinlInterface,
} from '../definitions/interfaces/inputs/new-deal';
import {
  DealInterface,
  DealsResponse,
  SalesInvoiceInterface,
} from '../definitions/interfaces/deals.interface';
/**
 * implement unit related calls.
 */
export class Deal {
  public static createLead(
    leadData: NewDealFormInterface
  ): Promise<Payload<void>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.createLead.url}`,
      {
        method: ENDPOINTS.createLead.method,
        body: JSON.stringify(leadData),
      }
    );
  }

  public static createMeeting(
    dealId: string,
    countryId: string,
    meetingData: NewMeetingInterface
  ): Promise<Payload<void>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.createMeeting.url(
        dealId
      )}`
    );
    url.searchParams.append('country_id', countryId);
    const fetchURL = decodeURIComponent(url.href);
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.createMeeting.method,
      body: JSON.stringify(meetingData),
    });
  }

  public static getLeads(): Promise<Payload<Payload<DealsResponse>>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getLeads.url}`,
      {
        method: ENDPOINTS.getLeads.method,
      }
    );
  }

  public static getDuplicateLeads(): Promise<Payload<Payload<DealsResponse>>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getDuplicateLeads.url}`,
      {
        method: ENDPOINTS.getDuplicateLeads.method,
      }
    );
  }

  public static getLeadById(
    dealId: string,
    countryId: string,
    duplicate: '1' | '0',
    broker_id: string
  ): Promise<Payload<Payload<DealInterface>>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getLeadById.url(
        dealId
      )}`
    );
    url.searchParams.append('country_id', countryId);
    url.searchParams.append('duplicate', duplicate);
    url.searchParams.append('broker_id', broker_id);
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getLeadById.method,
    });
  }

  public static getMeetings(): Promise<Payload<Payload<MeetingInterface[]>>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getMeetings.url}`,
      {
        method: ENDPOINTS.getMeetings.method,
      }
    );
  }

  public static getLeadMeetings(
    dealId: string,
    countryId: string
  ): Promise<Payload<Payload<MeetingInterface[]>>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getLeadMeetings.url(
        dealId
      )}`
    );
    url.searchParams.append('country_id', countryId);
    const fetchURL = decodeURIComponent(url.href);
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getLeadMeetings.method,
    });
  }

  public static submitLeadInvoice(
    dealId: string,
    countryId: string,
    formData: FormData
  ): Promise<Payload<Common>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.submitInvoice.url(
        dealId
      )}`
    );
    url.searchParams.append('country_id', countryId);
    const fetchURL = decodeURIComponent(url.href);
    return Network.fetch(
      fetchURL,
      {
        method: ENDPOINTS.submitInvoice.method,
        body: formData,
      },
      null
    );
  }

  public static getSaleInvoice(
    dealId: string,
    perPage = 15,
    page = 1
  ): Promise<Payload<Payload<SalesInvoiceInterface[]>>> {
    const url = new URL(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getSaleInvoice.url(
        dealId
      )}`
    );
    url.searchParams.append('page', String(page));
    url.searchParams.append('per_page', String(perPage));
    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getSaleInvoice.method,
    });
  }

  public static shareReservationLink(
    unitId: string,
    reservationLinkData: ShareReservationLinlInterface
  ): Promise<Payload<void>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.shareReservationLink.url(unitId)}`,
      {
        method: ENDPOINTS.shareReservationLink.method,
        body: JSON.stringify(reservationLinkData),
      }
    );
  }
}
