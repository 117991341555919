import { Link, To, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import AuthWrapper from '../auth-wrapper/auth-wrapper';
import styles from './forget-password-component.module.scss';
import logo from '../../assets/icons/logo.svg';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { USER_CONTEXT } from '../../contexts/user-context';

export interface ForgetPasswordComponentProps {
  subtitle?: string;
  isReset: boolean;
  isSubmitting: boolean;
  loginRoute: To;
  dashboardRoute: To;
  submitHandler: (values: { email: string }) => void;
}

const schema = yup.object({
  email: yup.string().email('Please enter a valid email').required('Required'),
});

export function ForgetPasswordComponent(
  props: Readonly<ForgetPasswordComponentProps>
) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext.user) {
      navigate(props.dashboardRoute);
    }
  }, [userContext]);

  return (
    <AuthWrapper>
      <div className={styles['wrapper']}>
        <div className={styles['heading']}>
          <img
            src={logo}
            alt=""
            role="presentation"
            className={styles['logo']}
          />
          <h2 className={styles['subtitle']}>{props.subtitle}</h2>
        </div>
        {props.isReset && (
          <>
            <h1>{t('resetLinkSent')}</h1>
            <div className={styles['note']}>
              {t('weSentEmailResetPassword')}
            </div>
            <Link to={props.loginRoute}>
              <button className="btn btn--navy">{t('backToLogin')}</button>
            </Link>
          </>
        )}
        {!props.isReset && (
          <>
            <h1>{t('forgotYourPassword')}</h1>
            <div className={styles['note']}>
              {t('kindlyInputYourRegisteredEmail')}
            </div>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={schema}
              onSubmit={props.submitHandler}
            >
              {({ errors }) => (
                <Form>
                  <div className="form__input">
                    <label htmlFor="email">{t('email')}</label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder="email@example.com"
                    />
                    {errors.email && (
                      <div className="form__error">{errors?.email}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn--navy"
                    disabled={props.isSubmitting}
                  >
                    {t('resetMyPassword')}
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </AuthWrapper>
  );
}

export default ForgetPasswordComponent;
