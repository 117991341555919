import React from 'react';
import { UnitDetails as UnitDetailsInterface } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import styles from './unit-specs.module.scss';
import {
  MONTENEGRO_UNITS_BATHROOMS,
  MONTENEGRO_UNITS_FLOOR_NUMBER,
} from '../../definitions/consts/units';
import UnitRow from '../unit-row/unit-row';

export interface UnitSpecsProps {
  unit: UnitDetailsInterface;
}

export const UnitSpecs = React.forwardRef(
  (props: UnitSpecsProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();

    const { unit } = props;

    let unitFloorNumber: number | string = unit.floor_number;
    let unitBathroomsNumber: number | string = unit.bathrooms;

    if (
      unitFloorNumber &&
      unit.project?.destination?.country?.slug === 'montenegro'
    ) {
      unitFloorNumber = MONTENEGRO_UNITS_FLOOR_NUMBER[unitFloorNumber];
    }

    if (
      unitBathroomsNumber &&
      unit.project?.destination?.country?.slug === 'montenegro'
    ) {
      unitBathroomsNumber = MONTENEGRO_UNITS_BATHROOMS[unitBathroomsNumber];
    }

    return (
      <div className={`${styles['wrapper']} card`} ref={ref}>
        <h3>{t('fullUnitSpecs')}</h3>
        <table>
          <tbody>
            <UnitRow label={t('unitName')} value={unit.name} />
            <UnitRow
              label={t('destination')}
              value={unit.project.destination.name}
            />
            <UnitRow label={t('unitType')} value={unit.unit_type.name} />
            <UnitRow
              label={t('buildingStatus')}
              value={
                unit.construction_status === 'off_plan'
                  ? t('offPlan')
                  : unit.construction_status?.toString().replace(/_/g, ' ')
              }
            />
            <UnitRow
              label={t('builtUpArea')}
              value={unit.built_up_area}
              valueUnit={t('mSquared')}
            />
            <UnitRow
              label={t('gardenAreaSqM')}
              value={unit.garden_area}
              valueUnit={t('mSquared')}
            />
            <UnitRow
              label={t('totalLandAreaSqM')}
              value={unit.total_area}
              valueUnit={t('mSquared')}
            />
            <UnitRow label={t('numberOfBedrooms')} value={unit.bedrooms} />
            <UnitRow
              label={t('numberOfBathrooms')}
              value={unitBathroomsNumber}
            />
            <UnitRow
              label={t('finishingOption')}
              value={unit.finishing_status}
            />
            <UnitRow label={t('floorNumber')} value={unitFloorNumber} />
          </tbody>
        </table>
      </div>
    );
  }
);

export default UnitSpecs;
