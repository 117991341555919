import plusSign from '../../assets/icons/plus.svg';
import minusSign from '../../assets/icons/minus.svg';
import styles from './faq.module.scss';
import { useState } from 'react';

export interface FaqProps {
  question: string;
  answer: string;
}

export function Faq(props: Readonly<FaqProps>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <button
      className={styles['wrapper']}
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div className={styles['question-wrapper']}>
        <h3 className={styles['question']}>{props.question}</h3>
        <span className={styles['collapse']}>
          <img src={isOpen ? minusSign : plusSign} alt="" role="presentation" />
        </span>
      </div>
      {isOpen && (
        <>
          <hr />
          <p className={styles['answer']}>{props.answer}</p>
        </>
      )}
    </button>
  );
}

export default Faq;
