import { DestinationFAQ } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import styles from './destination-faq.module.scss';
import Faq from '../faq/faq';

export interface DestinationFaqProps {
  FAQs: DestinationFAQ[];
}

export function DestinationFaq({ FAQs }: Readonly<DestinationFaqProps>) {
  const { t } = useTranslation();

  return (
    <div className={`card ${styles['wrapper']}`}>
      <h3>{t('faqs')}</h3>
      {FAQs.map((f) => (
        <Faq key={f.id} question={f.question} answer={f.answer} />
      ))}
    </div>
  );
}

export default DestinationFaq;
