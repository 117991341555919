import { useTranslation } from 'react-i18next';
import styles from './sidebar-component.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Route } from '@orascom/api-interfaces';
import {
  Sidebar as SideNavBar,
  Menu,
  MenuItem,
  SubMenu,
} from 'react-pro-sidebar';
import { useWindowDimensions } from '@orascom/utils';
import { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.webp';

export function SidebarMenuItem({
  route,
  icon,
  activeIcon,
  isActive,
  label,
  onClick,
}: Readonly<{
  route: Route;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  isActive: boolean;
  label: string;
  onClick?: () => void;
}>) {
  return (
    <MenuItem
      icon={isActive ? activeIcon : icon}
      component={<Link to={route.path} />}
      active={isActive}
      onClick={onClick}
    >
      {label}
    </MenuItem>
  );
}

function SidebarLogoutButton({
  onClick,
  loggingOut,
}: Readonly<{
  onClick: () => void;
  loggingOut: boolean;
}>) {
  const { t } = useTranslation();
  return (
    <button
      className={styles['logout']}
      onClick={onClick}
      disabled={loggingOut}
    >
      {t('logOut')}
    </button>
  );
}

export interface SidebarComponentProps {
  logoutHandler: () => void;
  loggingOut: boolean;
  children: React.ReactNode;
  subtitle?: string;
}
export function SidebarComponent(props: Readonly<SidebarComponentProps>) {
  const { width } = useWindowDimensions();
  const [navOpen, setNavOpen] = useState(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = navOpen ? 'hidden' : '';
    document.body.style.height = navOpen ? '100%' : '';
  }, [navOpen]);

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname]);

  return (
    <>
      <button
        className={`${styles['burger']} ${navOpen && styles['burger--open']}`}
        onClick={() => setNavOpen(!navOpen)}
      >
        <div className={styles['bar1']}></div>
        <div className={styles['bar2']}></div>
        <div className={styles['bar3']}></div>
      </button>

      {(navOpen || width > 768) && (
        <aside className={styles['wrapper']}>
          <SideNavBar
            backgroundColor="#ffffff"
            width="230px"
            rtl={i18n.language === 'ar'}
          >
            <Menu>
              <div className={styles['logo']}>
                <img src={logo} alt="orascom" loading="lazy" />
                <p className={styles['subtitle']}>{props.subtitle}</p>
              </div>

              {props.children}
              <SidebarLogoutButton
                onClick={props.logoutHandler}
                loggingOut={props.loggingOut}
              />
            </Menu>
          </SideNavBar>
        </aside>
      )}
    </>
  );
}
export default SidebarComponent;
