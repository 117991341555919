import Pagination from '@atlaskit/pagination';
import { SyntheticEvent } from 'react';
import './pagination.scss';

export interface PaginationProps {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: any) => void;
}

export const PaginationStyled = ({
  totalCount,
  pageSize,
  onPageChange,
  currentPage,
}: PaginationProps) => {
  const range = (start: number, end: number) => {
    const length: number = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };
  const totalPageCount = Math.ceil(totalCount / pageSize);

  const handleChange = (e: SyntheticEvent, page: number) => {
    onPageChange(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPageCount) {
      onPageChange(currentPage + 1);
    }
  };

  const previousButton = () => (
    <button
      className="prev__button"
      onClick={handlePrevious}
      disabled={currentPage === 1}
    >
      PREV
    </button>
  );

  const nextButton = () => (
    <button
      className="next__button"
      onClick={handleNext}
      disabled={currentPage === totalPageCount}
    >
      NEXT
    </button>
  );

  const pageNumberButton = ({ page }: any) => (
    <button
      className={`page__button ${page === currentPage ? 'current__page' : ''}`}
      onClick={() => onPageChange(page)}
    >
      {page}
    </button>
  );

  return totalPageCount > 1 ? (
    <Pagination
      nextLabel="Next"
      label="Page"
      pageLabel="Page"
      pages={
        currentPage === 1 || currentPage <= 5
          ? range(1, totalPageCount)
          : currentPage > 5 && currentPage < totalPageCount - 6
          ? range(currentPage - 2, totalPageCount)
          : range(totalPageCount - 6, totalPageCount)
      }
      previousLabel="Previous"
      onChange={handleChange}
      components={{
        Previous: previousButton,
        Next: nextButton,
        Page: pageNumberButton,
      }}
    />
  ) : null;
};
