import { AnalyticsSalesManager as AnalyticsSalesManagerApi } from '../api/analytics-sales-manager';
import { handleError } from '@orascom/utils';
import {
  AnalyticsBrokerRankingsSalesManager,
  AnalyticsBrokerageRankingsSalesManager,
  AnalyticsCommissions,
  AnalyticsCommissionsRankingSalesManager,
  AnalyticsInternationalSales,
  AnalyticsLeadsCount,
  AnalyticsRevenueInsightsSalesManager,
  AnalyticsSalesManagerRanking,
  SalesCountPerDestination,
} from '../definitions/interfaces/analytics';
import {
  FilterParams,
  FilterValue,
  PaginationPayload,
} from '@orascom/api-interfaces';
import greenBulletIcon from '../assets/icons/green-bullet.svg';
import orangeBulletIcon from '../assets/icons/orange-bullet.svg';
import redBulletIcon from '../assets/icons/red-bullet.svg';
import {
  AnalyticsFilterSalesManagers,
  CommissionsStatus,
} from '@orascom/broker-sales-man-common-components';
export interface TimeStamps {
  timestampStart: string;
  timestampEnd: string;
}

export class AnalyticsSalesManager {
  public static getAnalyticsCommissionsSalesManager(
    params: FilterParams
  ): Promise<PaginationPayload<AnalyticsCommissionsRankingSalesManager[]>> {
    return AnalyticsSalesManagerApi.getAnalyticsCommissionsSalesManager(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsLeadCountSalesManager(
    params: FilterParams
  ): Promise<AnalyticsLeadsCount> {
    return AnalyticsSalesManagerApi.getAnalyticsLeadCountSalesManager(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsRevenueInsightsSalesManager(
    params: FilterParams
  ): Promise<AnalyticsRevenueInsightsSalesManager> {
    return AnalyticsSalesManagerApi.getAnalyticsRevenueInsightsSalesManager(
      params
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsSalesCountPerDestinationSalesManager(
    params: FilterParams
  ): Promise<SalesCountPerDestination[]> {
    return AnalyticsSalesManagerApi.getAnalyticsSalesCountPerDestinationSalesManager(
      params
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsFilterDestinationsSalesManager(): Promise<
    FilterValue[]
  > {
    return AnalyticsSalesManagerApi.getAnalyticsFilterDestinationsSalesManager()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsFilterUnitTypeSalesManager(): Promise<
    FilterValue[]
  > {
    return AnalyticsSalesManagerApi.getAnalyticsFilterUnitTypeSalesManager()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsFilterBrokerageSalesManager(): Promise<
    FilterValue[]
  > {
    return AnalyticsSalesManagerApi.getAnalyticsFilterBrokerageSalesManager()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsBrokerRankingsSalesManager(
    params: FilterParams
  ): Promise<PaginationPayload<AnalyticsBrokerRankingsSalesManager[]>> {
    return AnalyticsSalesManagerApi.getAnalyticsBrokerRankingsSalesManager(
      params
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsSalesManagerRanking(
    params: FilterParams
  ): Promise<PaginationPayload<AnalyticsSalesManagerRanking[]>> {
    return AnalyticsSalesManagerApi.getAnalyticsSalesManagerRanking(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsCommissionsDetailsSalesManager(
    params: FilterParams
  ): Promise<AnalyticsCommissions> {
    return AnalyticsSalesManagerApi.getAnalyticsCommissionsDetailsSalesManager(
      params
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsBrokeragesRankingSalesManager(
    params: FilterParams
  ): Promise<PaginationPayload<AnalyticsBrokerageRankingsSalesManager[]>> {
    return AnalyticsSalesManagerApi.getAnalyticsBrokeragesRankingSalesManager(
      params
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsInternationalSalesManager(
    params: FilterParams
  ): Promise<AnalyticsInternationalSales> {
    return AnalyticsSalesManagerApi.getAnalyticsInternationalSalesManager(
      params
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsFilterSalesManagers(): Promise<
    AnalyticsFilterSalesManagers[]
  > {
    return AnalyticsSalesManagerApi.getAnalyticsFilterSalesManagers()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
export const sortingCommissions = [
  { label: 'Brokers Name', value: 'user_name' },
  { label: 'Company Name', value: 'company_name' },
  { label: 'Payout Date', value: 'expected_payout_date' },
  { label: 'Status', value: 'commission_status' },
  { label: 'Commission Amount', value: 'amount' },
];

export const renderCommissionStatusImage = (status: number) => {
  switch (status) {
    case CommissionsStatus.LATE:
      return (
        <div>
          <img src={redBulletIcon} alt="Late" />
          {''}
          Late
        </div>
      );
    case CommissionsStatus.PENDING:
      return (
        <div>
          <img src={orangeBulletIcon} alt="Pending" />
          {''}
          Pending
        </div>
      );
    case CommissionsStatus.CACHED:
      return (
        <div>
          <img src={greenBulletIcon} alt="Pending" />
          {''}
          Cached
        </div>
      );
    default:
      return null;
  }
};
