import styles from '../../pages/deal-details/deal-details.module.scss';
import { useTranslation } from 'react-i18next';
import {
  DealStatusEnum,
  DealInterface,
} from '../../definitions/interfaces/deals.interface';
import greenBulletIcon from '../../assets/icons/green-bullet.svg';
import { format } from 'date-fns';

function LeadInfoCard({
  leadInfo,
  dealStatus,
}: Readonly<{
  leadInfo: DealInterface | undefined;
  dealStatus: DealStatusEnum | undefined;
}>) {
  const { t } = useTranslation();

  return (
    <>
      <h3 className={styles['sub-title']}>{t('leadInformation')}</h3>
      <div className={styles['details']}>
        <div className={styles['detail']}>
          <p>{t('leadName')}</p>
          <h3>{leadInfo?.name}</h3>
        </div>
        <div className={styles['detail']}>
          <p>{t('phoneNumber')}</p>
          <h3>
            {leadInfo?.phone}
            {leadInfo?.phone_2 && ' , '}
            {leadInfo?.phone_2}
            {leadInfo?.phone_3 && ' , '}
            {leadInfo?.phone_3}
          </h3>
        </div>
        <div className={styles['detail']}>
          <p>{t('leadSubmitted')}</p>
          <h3>
            {leadInfo?.lead_submitted_at
              ? format(
                  new Date(leadInfo.lead_submitted_at * 1000),
                  'dd MMMM yyyy'
                )
              : null}
          </h3>
        </div>
        <div className={styles['detail']}>
          <p>{t('leadStatus')}</p>
          <h3>
            {dealStatus === DealStatusEnum.LEAD_SUBMITTED && (
              <>
                <img src={greenBulletIcon} alt="" role="presentation" />{' '}
                {t('underReview')}
              </>
            )}
            {dealStatus !== DealStatusEnum.LEAD_SUBMITTED && (
              <>
                <img src={greenBulletIcon} alt="" role="presentation" />{' '}
                {t('verified')}
              </>
            )}
          </h3>
        </div>
      </div>
    </>
  );
}

export default LeadInfoCard;
