import { FilterValue, PaginationPayload } from '@orascom/api-interfaces';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Loader } from '@orascom/common-components';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';
import { AnalyticsBrokerageRankingsSalesManager } from '../../definitions/interfaces/analytics';
import { v4 as uuidv4 } from 'uuid';
import Numeral from 'numeral';

import {
  AnalyticsFilter,
  PaginationStyled,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';
interface SalesManagerRankingProps {
  filterDestinations: FilterValue[];
  filterUnitTypes: FilterValue[];
}
function SalesManagerBrokerageRanking(
  props: Readonly<SalesManagerRankingProps>
) {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [loading, setLoading] = useState(true);
  const filtersParams = queryString.parse(window.location.search);

  const [brokeragesRanking, setBrokeragesRanking] =
    useState<PaginationPayload<AnalyticsBrokerageRankingsSalesManager[]>>();

  const {
    selectedDestinations,
    selectedDestinationsIds,
    handleSelectedDestinations,
    selectedUnitTypes,
    selectedUnitTypesIds,
    handleSelectedUnitTypes,
    handleSelectedBrokerage,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedDestinations, selectedUnitTypes]);

  useEffect(() => {
    AnalyticsSalesManagerUtils.getAnalyticsBrokeragesRankingSalesManager({
      page: currentPage.toString(),
      per_page: perPage.toString(),
      from: startDateArg,
      to: endDateArg,
      'destination_ids[]': selectedDestinationsIds,
      'unit_type_ids[]': selectedUnitTypesIds,
    })
      .then((res) => {
        setBrokeragesRanking(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    selectedUnitTypes,
    currentPage,
  ]);
  if (loading) {
    return <Loader />;
  }
  if (!brokeragesRanking) {
    return <h1>no rankings found</h1>;
  }

  return (
    <div className="broker-analytic-wrapper">
      <h3 className="title">Brokerage Ranking</h3>
      <div className={`card ranking-wrapper`}>
        <AnalyticsFilter
          unitSizeFilter
          filterDestinations={props.filterDestinations}
          filterUnitTypes={props.filterUnitTypes}
          handleSelectBrokerages={handleSelectedBrokerage}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
        />

        <div className="analytics-table">
          <table>
            <thead>
              <tr className="manager-tr">
                <th className="manager-th">Rank</th>
                <th className="manager-th">Brokerage Name</th>
                <th className="manager-th">No. of Deals </th>
                <th className="manager-th">Sales</th>
              </tr>
            </thead>
            <tbody>
              {brokeragesRanking.data?.map((company, index) => {
                return (
                  <tr className="manager-tr" key={uuidv4()}>
                    <td className="manager-td--details">
                      <p>#{perPage * (currentPage - 1) + (index + 1)}</p>
                    </td>
                    <td className="manager-td--name">
                      <p>{company.company_name}</p>
                    </td>
                    <td className="manager-td--details">
                      <p>{company.deals_count}</p>
                    </td>
                    <td className="manager-td--name">
                      <p>
                        {Numeral(company.total_selling_price).format('0,0')}{' '}
                        {company.currency}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationStyled
            currentPage={currentPage}
            totalCount={brokeragesRanking.meta.total}
            pageSize={brokeragesRanking.meta.per_page}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default SalesManagerBrokerageRanking;
