import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from '../../../pages/my-deals/my-deals.module.scss';
import './broker-deals-card.scss';
import { DealInterface } from '../../../definitions/interfaces/deals.interface';
import DealCard from '../../deal-card/deal-card';
import PlusIcon from '../../../assets/icons/plus.svg';
import MinusIcon from '../../../assets/icons/minus.svg';
export interface BrokerDealsCardProps {
  brokerDeals: DealInterface[];
  duplicateDeals?: boolean;
}

function BrokerDealsCard(props: Readonly<BrokerDealsCardProps>) {
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [arrowDirection, setArrowDirection] = useState(false);

  const toggleCollapse = () => {
    setCollapseMenu(!collapseMenu);
    setArrowDirection(!arrowDirection);
  };

  const { brokerDeals, duplicateDeals } = props;

  return (
    <div className={styles['deals']}>
      <button className={styles['broker']} onClick={toggleCollapse}>
        <div className={styles['name__container']}>
          <h3 className={styles['name']}>{brokerDeals[0].broker.name}</h3>
        </div>
        <img
          className={styles['collapse-img']}
          src={collapseMenu ? MinusIcon : PlusIcon}
          alt=""
          role="presentation"
        />
      </button>

      <div
        className={`collapse__container ${
          collapseMenu ? 'collapse__container--expanded' : ''
        }`}
      >
        {' '}
        {brokerDeals.map((deal) => (
          <DealCard
            key={uuidv4()}
            deal={deal}
            status={
              deal.sales?.length ? deal.sales[0].deal_status : deal.lead_status
            }
            sales={deal.sales}
            duplicateDeal={duplicateDeals}
          />
        ))}
      </div>
    </div>
  );
}

export default BrokerDealsCard;
