export interface UnitRowProps {
  label: string;
  value?: string | number;
  valueUnit?: string;
}
export function UnitRow(props: Readonly<UnitRowProps>) {
  if (!props.value) {
    return null;
  }

  return (
    <tr>
      <td>{props.label}</td>
      <td>
        {props.value} {props.valueUnit}
      </td>
    </tr>
  );
}
export default UnitRow;
