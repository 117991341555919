import { useContext } from 'react';
import CompareUnitsContext from '../contexts/compare-units-context';

export const useCompareUnitsContext = () => {
  const compareUnitsContext = useContext(CompareUnitsContext);

  if (!compareUnitsContext) {
    throw new Error(
      'useCompareUnitsContext must be used within a CompareUnitsProvider'
    );
  }

  return compareUnitsContext;
};
