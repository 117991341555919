import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { AnalyticsCommissionsRankingSalesManager } from '../../definitions/interfaces/analytics';
import queryString from 'query-string';
import {
  FilterValue,
  OptionValue,
  PaginationPayload,
} from '@orascom/api-interfaces';
import {
  AnalyticsSalesManager as AnalyticsSalesManagerUtils,
  renderCommissionStatusImage,
  sortingCommissions,
} from '../../utils/analytics-sales-manager';
import { Loader, SelectDropdown } from '@orascom/common-components';
import styles from './commissions-ranking.module.scss';
import sortArrow from '../../assets/icons/sort-arrow.svg';
import Numeral from 'numeral';
import {
  AnalyticsFilter,
  PaginationStyled,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';
import { AnalyticsSalesManager as AnalyticsSalesManagerApi } from '../../api/analytics-sales-manager';
import ReportIcon from '../../assets/icons/download-report.svg';
interface CommissionsRankingProps {
  filterBrokerages: FilterValue[];
}

export function CommissionsRanking(props: Readonly<CommissionsRankingProps>) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [loading, setLoading] = useState(true);
  const filtersParams = queryString.parse(window.location.search);

  const [sortDirectionDesc, setSortDirectionDesc] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<OptionValue>();

  const toggleSortDirection = () => {
    setSortDirectionDesc(!sortDirectionDesc);
  };

  const [comissionsRanking, setComissionsRanking] =
    useState<PaginationPayload<AnalyticsCommissionsRankingSalesManager[]>>();

  const {
    selectedStatuses,
    selectedStatusesIds,
    handleSelectedStatuses,
    selectedBrokerage,
    selectedBrokeragesIds,
    handleSelectedBrokerage,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedBrokerage, selectedStatuses]);

  useEffect(() => {
    AnalyticsSalesManagerUtils.getAnalyticsCommissionsSalesManager({
      page: currentPage.toString(),
      per_page: perPage.toString(),
      from: startDateArg,
      to: endDateArg,
      sort_by: sortBy?.value.toString(),
      sort_dir: sortDirectionDesc ? 'desc' : 'asc',
      'brokerage_company_ids[]': selectedBrokeragesIds,
      'status[]': selectedStatusesIds,
    })
      .then((res) => {
        setComissionsRanking(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    currentPage,
    perPage,
    sortBy,
    sortDirectionDesc,
    selectedBrokerage,
    selectedStatuses,
  ]);

  if (loading) {
    return <Loader />;
  }
  if (!comissionsRanking) {
    return <h1>no rankings found</h1>;
  }

  const exportCommissionsFile = () => {
    AnalyticsSalesManagerApi.getAnalyticsBrokerCommissions({
      from: startDateArg,
      to: endDateArg,
      'brokerage_company_ids[]': selectedBrokeragesIds,
      'status[]': selectedStatusesIds,
    })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'commissions.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error('There was an error downloading the file:', error);
      });
  };

  return (
    <div className="broker-analytic-wrapper">
      <div className={styles['header-wrapper']}>
        <h3 className={`${styles['title']} title`}>{t('allCommissions')}</h3>

        <button
          onClick={exportCommissionsFile}
          className="analytics__download-button"
        >
          <img src={ReportIcon} alt="" role="presentation" />
          {t('exportCommissions')}
        </button>
      </div>
      <div className={`card ranking-wrapper`}>
        <div className={styles['analytics-table-header']}>
          {' '}
          <AnalyticsFilter
            filterBrokerages={props.filterBrokerages}
            handleSelectBrokerages={handleSelectedBrokerage}
            handleSelectedStatuses={handleSelectedStatuses}
            comissionsStatus
          />
          <div>
            <SelectDropdown
              className={styles['analytics-filter-container']}
              placeholder="Sort By"
              onChange={(val) => {
                if (val) {
                  setSortBy(val);
                }
              }}
              options={sortingCommissions}
            />
          </div>
        </div>

        <div className="analytics-table">
          <table>
            <thead>
              <tr className="manager-tr">
                <th className="manager-th">{t('brokerName')}</th>
                <th className="manager-th">{t('contractedUnit')}</th>
                <th className="manager-th payout-th">
                  <button
                    className={sortDirectionDesc ? 'arrow-down' : 'arrow-up'}
                    onClick={toggleSortDirection}
                  >
                    {t('payoutDate')}

                    <img src={sortArrow} alt="" />
                  </button>
                </th>
                <th className="manager-th">{t('status')}</th>
                <th className="manager-th">{t('commissionAmout')}</th>
              </tr>
            </thead>
            <tbody>
              {comissionsRanking.data?.map((item) => {
                return (
                  <tr className="manager-tr" key={uuidv4()}>
                    <td className="manager-td--name">
                      <p>{item.user_name}</p>
                      <span>{item.company_name}</span>
                    </td>
                    <td>{item.unit_name}</td>
                    <td>
                      {' '}
                      {item.expected_payout_date
                        ? new Date(
                            item.expected_payout_date
                          ).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          })
                        : 'no date'}
                    </td>
                    <td className="manager-td--status">
                      {renderCommissionStatusImage(item.commission_status)}
                    </td>
                    <td>
                      {item.amount ? Numeral(item.amount).format('0,0') : 0}{' '}
                      {item.currency}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationStyled
            currentPage={currentPage}
            totalCount={comissionsRanking.meta.total}
            pageSize={comissionsRanking.meta.per_page}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default CommissionsRanking;
