import ProgressBar from '../progress-bar/progress-bar';
import styles from './lifecycle-content.module.scss';

/* eslint-disable-next-line */
export interface LifecycleContentProps {
  baseCount: number;
  cycleContentTitle: string;
  cycleContentCount: number;
  cycleContentPrevStepCount: number;
}

export function LifecycleContent({
  baseCount,
  cycleContentTitle,
  cycleContentCount,
  cycleContentPrevStepCount,
}: LifecycleContentProps) {
  const convertToCumulativePercentage = (count: number) => {
    if (count === 0 && baseCount === 0) {
      return 0;
    }
    return +((count / baseCount) * 100).toFixed(2);
  };

  const convertToNextStepPercentage = (count: number, prevCount: number) => {
    if ((count === 0 && prevCount === 0) || prevCount === 0) {
      return 0;
    }
    return +((count / prevCount) * 100).toFixed(2);
  };

  return (
    <div className={`${styles['grid']} ${styles['content']}`}>
      <h4>{cycleContentTitle}</h4>
      <ProgressBar
        completedPercentage={convertToCumulativePercentage(cycleContentCount)}
        completedNumber={cycleContentCount.toString()}
      />
      <div className={styles['chevron']}>
        <p>
          {convertToNextStepPercentage(
            cycleContentCount,
            cycleContentPrevStepCount
          )}
          %
        </p>
      </div>
      <div className={styles['chevron']}>
        <p>{convertToCumulativePercentage(cycleContentCount)}%</p>
      </div>
    </div>
  );
}

export default LifecycleContent;
