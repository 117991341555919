import { useEffect, useRef, useState } from 'react';
import styles from './deal-details-breadcrumbs.module.scss';
import { DealStatusEnum } from '../../definitions/interfaces/deals.interface';
import { useTranslation } from 'react-i18next';

interface DealDetailsBreadcrumbsProps {
  dealStatus: DealStatusEnum;
}

type StylesOptions = 'inactive' | 'active';

interface BreadCrumbsStylesInterface {
  lead_submitted: StylesOptions;
  lead_verified: StylesOptions;
  lead_reserved: StylesOptions;
  deal_closed: StylesOptions;
  submitted_invoice: StylesOptions;
  invoice_approved: StylesOptions;
}

export function DealDetailsBreadcrumbs({
  dealStatus,
}: Readonly<DealDetailsBreadcrumbsProps>) {
  const [breadCrumbsStyles, setBreadCrumbsStyles] =
    useState<BreadCrumbsStylesInterface>();
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    switch (dealStatus) {
      case DealStatusEnum.LEAD_SUBMITTED:
        setBreadCrumbsStyles({
          lead_submitted: 'active',
          lead_verified: 'inactive',
          lead_reserved: 'inactive',
          deal_closed: 'inactive',
          submitted_invoice: 'inactive',
          invoice_approved: 'inactive',
        });
        break;

      case DealStatusEnum.LEAD_VERIFIED:
        setBreadCrumbsStyles({
          lead_submitted: 'active',
          lead_verified: 'active',
          lead_reserved: 'inactive',
          deal_closed: 'inactive',
          submitted_invoice: 'inactive',
          invoice_approved: 'inactive',
        });
        break;

      case DealStatusEnum.LEAD_RESERVED:
        setBreadCrumbsStyles({
          lead_submitted: 'active',
          lead_verified: 'active',
          lead_reserved: 'active',
          deal_closed: 'inactive',
          submitted_invoice: 'inactive',
          invoice_approved: 'inactive',
        });
        break;

      case DealStatusEnum.DEAL_CLOSED:
        setBreadCrumbsStyles({
          lead_submitted: 'active',
          lead_verified: 'active',
          lead_reserved: 'active',
          deal_closed: 'active',
          submitted_invoice: 'inactive',
          invoice_approved: 'inactive',
        });
        break;

      case DealStatusEnum.SUBMITTED_INVOICE:
        setBreadCrumbsStyles({
          lead_submitted: 'active',
          lead_verified: 'active',
          lead_reserved: 'active',
          deal_closed: 'active',
          submitted_invoice: 'active',
          invoice_approved: 'inactive',
        });
        if (breadcrumbsRef?.current) {
          breadcrumbsRef.current.scrollLeft =
            breadcrumbsRef?.current.scrollWidth -
            breadcrumbsRef?.current.clientWidth;
        }
        break;

      case DealStatusEnum.INVOICE_APPROVED:
        setBreadCrumbsStyles({
          lead_submitted: 'active',
          lead_verified: 'active',
          lead_reserved: 'active',
          deal_closed: 'active',
          submitted_invoice: 'active',
          invoice_approved: 'active',
        });
        if (breadcrumbsRef?.current) {
          breadcrumbsRef.current.scrollLeft =
            breadcrumbsRef?.current.scrollWidth -
            breadcrumbsRef?.current.clientWidth;
        }
        break;

      default:
        break;
    }
  }, [dealStatus]);

  return (
    <div className={styles['wrapper']} ref={breadcrumbsRef}>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.lead_submitted}`]
        }`}
      >
        <h4>{t('leadSubmitted')}</h4>
        {dealStatus === DealStatusEnum.LEAD_SUBMITTED && (
          <p>{t('leadIsBeingUnderReview')}</p>
        )}
      </button>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.lead_verified}`]
        }`}
      >
        <h4>{t('leadVerified')}</h4>
        {dealStatus === DealStatusEnum.LEAD_VERIFIED && (
          <p>{t('nowYouCanScheduleMeeting')}</p>
        )}
      </button>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.lead_reserved}`]
        }`}
      >
        <h4>{t('leadReserved')}</h4>
        {dealStatus === DealStatusEnum.LEAD_RESERVED && (
          <p>{t('reservedTheUnit')}</p>
        )}
      </button>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.deal_closed}`]
        }`}
      >
        <h4>{t('dealClosed')}</h4>
        {dealStatus === DealStatusEnum.DEAL_CLOSED && (
          <p>{t('clientSuccessfullyPurchasedTheUnit')}</p>
        )}
      </button>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.submitted_invoice}`]
        }`}
      >
        <h4>{t('submittedInvoice')}</h4>
        {dealStatus === DealStatusEnum.SUBMITTED_INVOICE && (
          <p>{t('commissionConfirmed')}</p>
        )}
      </button>
      <button
        className={`${styles['breadcrumb']} ${
          styles[`breadcrumb--${breadCrumbsStyles?.invoice_approved}`]
        }`}
      >
        <h4>{t('invoiceApproved')}</h4>
        {dealStatus === DealStatusEnum.INVOICE_APPROVED && (
          <p>{t('commissionHasBeenTransferred')}</p>
        )}
      </button>
    </div>
  );
}

export default DealDetailsBreadcrumbs;
