import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListIcon from '../../assets/icons/list.svg?react';
import GridIcon from '../../assets/icons/grid.svg?react';
import { Loader, debounce, UnitsFilters } from '@orascom/common-components';
import {
  useCurrencyContext,
  useCurrencyConverter,
  usePriceSlider,
  useUnitFilters,
  useUnits,
} from '@orascom/utils';
import searchIcon from '../../assets/icons/search.svg';
import styles from './filters-units.module.scss';
// eslint-disable-next-line @nx/enforce-module-boundaries
import UnitsPdfDownloader from 'libs/common-components/src/lib/pdf-downloader/download-units';
import { useSearchParams } from 'react-router-dom';
import {
  FiltersEnums,
  PaginationPayload,
  Payload,
  PriceValue,
  UnitFilters,
  UnitInterface,
} from '@orascom/api-interfaces';
import CompareButton from '../compare-button/compare-button';
import UnitsListing from '../units-listing/units-listing';

interface FiltersUnitsProps {
  portal: 'broker' | 'sales-man';
  title: string;
  onResale: boolean;
  getUnitFilters?(searchParams?: URLSearchParams): Promise<UnitFilters>;
  getUnits(
    page: number,
    perPage: number,
    searchParams?: URLSearchParams,
    unitId?: string,
    price?: number,
    designType?: string
  ): Promise<PaginationPayload<UnitInterface[]>>;
  comparePropertiesPath: string;
  unitDetailsPath?: Function;
  addSavedUnit: (unitId: number) => Promise<Payload<void>>;
  deleteSavedUnit: (unitId: number) => Promise<Payload<void>>;
  similarUnits?: boolean;
  resalePage?: boolean;
}

export function FiltersUnits(props: Readonly<FiltersUnitsProps>) {
  const { t } = useTranslation();
  const setSearchParams = useSearchParams()[1];

  const [isExpanded, setIsExpanded] = useState(true);
  const [listingView, setListingView] = useState<'list' | 'grid'>('grid');

  const { filters, isLoading: isLoadingFilters } = useUnitFilters({
    getUnitFilters: props.getUnitFilters,
  });

  const { selectedCurrency, convertPrice } = useCurrencyConverter();
  const unitsData = useUnits({
    getUnits: props.getUnits,
    selectedCurrency,
    convertPrice,
    similarUnits: props.similarUnits,
    onResale: props.resalePage,
  });

  const { priceSlider, setPriceSlider } = usePriceSlider(filters);
  // destructure units data
  const { units, loadMoreUnits, paginationData, hasMoreUnits } = unitsData;
  const { isLoadingUnits, isLoadingMoreUnits } = unitsData;

  const onChange = (value: string) => {
    if (value) {
      setSearchParams({ [FiltersEnums.UNIT_NAME]: value }, { replace: true });
    } else {
      setSearchParams(
        (searchParam) => {
          searchParam.delete(FiltersEnums.UNIT_NAME);
          return searchParam;
        },
        {
          replace: true,
        }
      );
    }
  };

  const isLoading = isLoadingUnits || isLoadingMoreUnits;

  const shouldShowNoResults = !units?.length && !isLoading;

  function setPriceValue(priceValue: PriceValue) {
    setPriceSlider({
      ...priceSlider,
      price: priceValue,
    });
  }
  const {
    isLoading: isLoadingCurrency,
    setActiveCurrency,
    setDisableCurrencySetting,
  } = useCurrencyContext();

  const [searchParams] = useSearchParams();
  const destinationParam = searchParams.get(FiltersEnums.DESTINATIONS);

  useEffect(() => {
    const isElGouna = destinationParam === 'el-gouna';

    if (isElGouna) {
      setActiveCurrency('USD');
      setDisableCurrencySetting({
        disabled: true,
        info: t(`elGounaCurrencyDisclaimer`),
      });
    }

    return () => {
      if (isElGouna) {
        setDisableCurrencySetting({
          disabled: false,
          info: '',
        });
      }
    };
  }, [destinationParam]);

  const debouncedOnChange = debounce(onChange, 500);

  if (props.onResale && units.length === 0) {
    return (
      <h1 className="empty-unit__placholder">
        {t('noResaleUnitsPlaceholder')}
      </h1>
    );
  }

  return (
    <div className={styles['wrapper']}>
      <h1>{props.title}</h1>

      {!props.similarUnits && (
        <UnitsFilters
          unitFilters={filters}
          isLoadingFilters={isLoadingFilters}
          showResale={!props.resalePage}
          priceSlider={priceSlider}
          setPriceValue={setPriceValue}
          isLoadingCurrency={isLoadingCurrency}
        />
      )}
      {shouldShowNoResults && <h1>{t('noUnitsToDisplay')}</h1>}

      {Boolean(units?.length) && !isLoadingUnits && (
        <>
          <div className={styles['total-count']}>
            {paginationData?.total} {t('availableUnits')}
            <div className={styles['total-count-svgs']}>
              {listingView === 'list' && (
                <div className={styles['download']}>
                  <UnitsPdfDownloader
                    downloadName="units-listing"
                    buttonText={t('downloadUnitsList')}
                    className="anchor"
                  />
                </div>
              )}
              <button
                className={`${styles['total-count-svg']} ${
                  listingView === 'list' && styles['active']
                }`}
                onClick={() => setListingView('list')}
              >
                <ListIcon />
              </button>
              <button
                className={`${styles['total-count-svg']} ${
                  listingView === 'grid' && styles['active']
                }`}
                onClick={() => setListingView('grid')}
              >
                <GridIcon />
              </button>
            </div>
          </div>

          <UnitsListing
            portal={props.portal}
            listingView={listingView}
            id="units-listing"
            units={units}
            unitDetailsPath={props.unitDetailsPath}
            addSavedUnit={props.addSavedUnit}
            deleteSavedUnit={props.deleteSavedUnit}
          />
        </>
      )}

      {isLoading && <Loader />}

      {Boolean(units.length) && !isLoadingUnits && (
        <div className={styles['pagination']}>
          <div className={styles['info']}>
            <hr className={styles['line']} />
            <div className={styles['count']}>
              {t('showing')} {units.length} {t('unitsOutOf')}{' '}
              {paginationData?.total}
            </div>
          </div>
          {hasMoreUnits && (
            <div className={styles['button']}>
              <button
                className="btn btn--navy"
                onClick={loadMoreUnits}
                disabled={isLoading}
              >
                {t('loadMore')}
              </button>
            </div>
          )}
        </div>
      )}
      <CompareButton comparePropertiesPath={props.comparePropertiesPath} />
    </div>
  );
}

export default FiltersUnits;
