import { Route, UserRole } from '@orascom/api-interfaces';
import ForgetPassword from '../pages/forget-password/forget-password';
import Login from '../pages/login/login';
import SetPassword from '../pages/set-password/set-password';
import Dashboard from '../pages/dashboard/dashboard';
import UnitDetails from '../pages/unit-details/unit-details';
import PrimaryUnits from '../pages/primary-units/primary-units';
import MyDocuments from '../pages/my-documents/my-documents';
import MyDeals from '../pages/my-deals/my-deals';
import NewDeal from '../pages/new-deal/new-deal';
import DestinationDetails from '../pages/destination-details/destination-details';
import NewsEvents from '../pages/news-events/news-events';
import NewsDetails from '../pages/news-details/news-details';
import SavedUnits from '../pages/saved-units/saved-units';
import Updates from '../pages/updates/updates';
import DealDetails from '../pages/deal-details/deal-details';
import NewMeeting from '../pages/new-meeting/new-meeting';
import BrokerAnalytics from '../pages/broker-analytics/broker-analytics';
import SalesManagerRevenueAnalytics from '../pages/sales-manager-revenue/sales-manager-revenue';
import SalesManagerCommissions from '../pages/sales-manager-commissions/sales-manager-commissions';
import SalesManagerRanking from '../pages/sales-manager-rankings/sales-manager-rankings';
import NotFoundPage from '../pages/not-found/not-found';
import ComparePropertiesPage from '../pages/compare-properties/compare-properties';
import ResaleUnits from '../pages/resale-units/resale-units';
import SimilarUnitsListing from '../pages/similar-units/similar-units-listing';

export const ROUTES: Record<string, Route> = {
  Dashboard: {
    path: '/',
    component: Dashboard,
    public: false,
    role: UserRole.BROKER,
  },

  Analytics: {
    path: '/analytics',
    component: BrokerAnalytics,
    public: false,
    role: UserRole.BROKER,
  },
  SalesManagerRevenueAnalytics: {
    path: '/revenue-analytics',
    component: SalesManagerRevenueAnalytics,
    public: false,
    role: UserRole.SALES_MANAGER,
  },
  SalesManagerCommissionsAnalytics: {
    path: '/commissions-analytics',
    component: SalesManagerCommissions,
    public: false,
    role: UserRole.SALES_MANAGER,
  },
  SalesManagerRankingsAnalytics: {
    path: '/rankings',
    component: SalesManagerRanking,
    public: false,
    role: UserRole.SALES_MANAGER,
  },
  Login: {
    path: '/login',
    component: Login,
    public: true,
    role: UserRole.ALL,
  },

  ResetPassword: {
    path: '/set-password',
    component: SetPassword,
    public: true,
    role: UserRole.ALL,
  },

  ForgetPassword: {
    path: '/forget-password',
    component: ForgetPassword,
    public: true,
    role: UserRole.ALL,
  },

  Notifications: {
    path: '/notifications',
    component: Updates,
    public: false,
    role: UserRole.BROKER,
  },

  Destination: {
    path: '/destination/:destinationSlug',
    getPath: (destinationSlug: string) => `/destination/${destinationSlug}`,
    component: DestinationDetails,
    public: false,
    role: UserRole.BROKER,
  },

  PrimaryUnits: {
    path: '/primary-units',
    component: PrimaryUnits,
    public: false,
    role: UserRole.BROKER,
  },

  CompareProperties: {
    path: '/compare-properties',
    component: ComparePropertiesPage,
    public: false,
    role: UserRole.BROKER,
  },

  UnitDetails: {
    path: '/unit-details/:unitId',
    getPath: (unitId: string) => `/unit-details/${unitId}`,
    component: UnitDetails,
    public: false,
    role: UserRole.BROKER,
  },
  SimilarUnits: {
    path: '/similar-units/:unitId/:price',
    getPath: (unitId: string, price: number) =>
      `/similar-units/${unitId}/${price}`,
    component: SimilarUnitsListing,
    public: false,
    role: UserRole.BROKER,
  },
  UnitDeal: {
    path: '/unit-details/:unitId/new-deal',
    getPath: (unitId: string) => `/unit-details/${unitId}/new-deal`,
    component: NewDeal,
    public: false,
    role: UserRole.BROKER,
  },
  NewDeal: {
    path: '/new-deal',
    component: NewDeal,
    public: false,
    role: UserRole.BROKER,
  },

  ResaleUnits: {
    path: '/resale-units',
    component: ResaleUnits,
    public: false,
  },

  SavedUnits: {
    path: '/saved-units',
    component: SavedUnits,
    public: false,
    role: UserRole.BROKER,
  },

  MyDeals: {
    path: '/my-deals',
    component: MyDeals,
    public: false,
    role: UserRole.BROKER,
  },

  DealDetails: {
    path: '/deal-details/:dealId/:countryId/:duplicate/:brokerId',
    getPath: (
      dealId: string,
      countryId: string,
      duplicate: string,
      brokerId: string
    ) => `/deal-details/${dealId}/${countryId}/${duplicate}/${brokerId}`,
    component: DealDetails,
    public: false,
    role: UserRole.BROKER,
  },

  NewMeeting: {
    path: '/deal-details/:dealId/:countryId/:duplicate/:brokerId/new-meeting',
    getPath: (
      dealId: string,
      countryId: string,
      duplicate: string,
      brokerId: string
    ) =>
      `/deal-details/${dealId}/${countryId}/${duplicate}/${brokerId}/new-meeting`,
    component: NewMeeting,
    public: false,
    role: UserRole.BROKER,
  },

  MyDocuments: {
    path: '/my-documents',
    component: MyDocuments,
    public: false,
    role: UserRole.BROKER,
  },

  NewsEvents: {
    path: '/news-events',
    component: NewsEvents,
    public: false,
    role: UserRole.BROKER,
  },

  NewsEventsDetails: {
    path: '/news-events-details/:newsSlug',
    getPath: (newsSlug: string) => `/news-events-details/${newsSlug}`,
    component: NewsDetails,
    public: false,
    role: UserRole.BROKER,
  },

  /**
   * Always keep this route at the end of the routes object.
   */
  NotFound: {
    path: '*',
    component: NotFoundPage,
    public: true,
    role: UserRole.ALL,
    side_nav: false,
  },
};
