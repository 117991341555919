export enum ContactTypeEnum {
  VIRTUAL_MEETING = '1',
  MOBILE = '2',
  EMAIL = '3',
  WHATS_APP = '4',
}
export enum ContactTimeEnum {
  MORNING = '1',
  AFTERNOON = '2',
  NIGHT = '3',
}
