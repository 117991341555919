import { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../api/routes';
import { User as UserUtils } from '../utils/user';
import { Loader, ScrollToTop } from '@orascom/common-components';
import Sidebar from '../components/common/sidebar/sidebar';
import {
  CompareUnitsProvider,
  Header,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import styles from './app.module.scss';
import { UserInterface, UserRole } from '@orascom/api-interfaces';
import { changeLanguage, CurrencyContextProvider } from '@orascom/utils';
import { ENDPOINTS } from '../api/endpoints';

export function App() {
  const [user, setUser] = useState<UserInterface | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();
  const availableRoutes = Object.values(ROUTES)
    .map((route) => {
      const userPermitted =
        user?.role === route.role || route.role === UserRole.ALL;

      if ((!route.public && !user) || !userPermitted) {
        return;
      }

      const Component = route.component;

      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    })
    .filter((route) => route !== null);

  useEffect(() => {
    const langValue = localStorage.getItem('lang');
    if (langValue !== null) {
      changeLanguage(JSON.parse(langValue));
    } else {
      changeLanguage({ label: 'EN', value: 'en' });
    }
  }, []);

  useEffect(() => {
    if (!user) {
      if (
        localStorage.getItem(
          process.env['NX_BROKER_ACCESS_TOKEN_KEY'] as string
        )
      ) {
        UserUtils.getUserData()
          .then((res) => {
            setUser(res.data.user);
          })
          .catch(() => {
            if (location.pathname !== ROUTES['ResetPassword'].path) {
              navigate(ROUTES['Login'].path);
            }
          })
          .finally(() => setLoadingUser(false));
      } else {
        setLoadingUser(false);
        if (location.pathname !== ROUTES['ResetPassword'].path) {
          navigate(ROUTES['Login'].path);
        }
      }
    } else {
      setLoadingUser(false);
    }
  }, [user]);

  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
      isLoading: loadingUser,
    }),
    [user, loadingUser]
  );

  // This is necessary because the current implementation forces one layout,
  // and we need to ensure the NotFound route doesn't render the sidebar,
  // without breaking the existing style logic.
  const isNotFoundRoute = !Object.values(ROUTES).some(
    ({ path }) =>
      path === location.pathname ||
      (path.includes(':') &&
        new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`).test(
          location.pathname
        ))
  );

  if (loadingUser && !isNotFoundRoute) {
    return <Loader />;
  }

  const showSidebar = !isNotFoundRoute && user;
  return (
    <USER_CONTEXT.Provider value={userContextValue}>
      <CompareUnitsProvider>
        <CurrencyContextProvider
          defaultDollar
          getCurrenciesUrl={ENDPOINTS.getCurrencies.url}
        >
          <main
            className={`${user ? 'container container--flex' : 'container'}`}
          >
            {showSidebar && <Sidebar />}
            <ScrollToTop />
            <section className={styles['side-section']}>
              {showSidebar && (
                <Header
                  portal="Broker"
                  notificationPath={ROUTES['Notifications'].path}
                />
              )}
              <Routes>{availableRoutes}</Routes>
            </section>
          </main>
        </CurrencyContextProvider>
      </CompareUnitsProvider>
    </USER_CONTEXT.Provider>
  );
}

export default App;
