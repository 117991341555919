import styles from './lifecycle-headers.module.scss';
import InfoIcon from '../../assets/icons/info.svg';
import { useTranslation } from 'react-i18next';

export function LifecycleHeaders() {
  const { t } = useTranslation();
  return (
    <div className={`${styles['grid']} ${styles['headers']}`}>
      <p>LIFECYCLE STAGES</p>
      <p className={styles['count']}>COUNT OF DEALS</p>
      <p>
        NEXT-STEP CONVERSION{' '}
        <span className="info-msg">
          <img src={InfoIcon} alt="info-icon" />
          <p>
            {t(
              'The percentage of contacts moving from that stage to the next.'
            )}
          </p>
        </span>
      </p>
      <p>
        CUMULATIVE CONVERSION{' '}
        <span className="info-msg">
          <img src={InfoIcon} alt="info-icon" />
          <p>
            {t(
              'The percentage of contacts moving from the first stage to the next.'
            )}
          </p>
        </span>
      </p>
    </div>
  );
}

export default LifecycleHeaders;
