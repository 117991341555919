import { useTranslation } from 'react-i18next';
import {
  FiltersEnums,
  PriceSlider,
  PriceValue,
  UnitFilters,
} from '@orascom/api-interfaces';
import { useWindowDimensions } from '@orascom/utils';
import { useSearchParams } from 'react-router-dom';
import searchIcon from '../../../assets/icons/search.svg';
import PriceRangeSlider from '../price-range-slider/price-range-slider';
import { NavyButton } from '../button/button';
import { Modal } from '@orascom/common-components';
import styles from './broker-filters.module.scss';
import { MultiFilterDropdown, SingleFilterDropdown } from './filter-dropdown';
import {
  SortByOptions,
  convertToOptionsWithSlug,
  convertNumFiltersToOptions,
  convertStringFiltersToOptions,
  handleClearAllFilters,
} from './filters-utils';
import { useState } from 'react';
import ClearFilters from './clear-filters';
import OnResaleButton from './on-resale-button';
import { debounce } from '../debounce/debounce';

interface UnitsFiltersProps {
  unitFilters?: UnitFilters;
  isLoadingFilters: boolean;
  showResale: boolean;
  priceSlider: PriceSlider;
  setPriceValue: (price: PriceValue) => void;
  isLoadingCurrency?: boolean;
}

export function UnitsFilters({
  showResale = true,
  unitFilters,
  isLoadingFilters,
  priceSlider,
  isLoadingCurrency = false,
  setPriceValue,
}: Readonly<UnitsFiltersProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const onShowFiltersModal = () => {
    setShowFiltersModal(true);
    document.body.style.overflow = 'hidden';
  };

  const onCloseModal = () => {
    setShowFiltersModal(false);
    document.body.style.overflow = '';
  };

  const onPriceChangeEnd = (price: PriceValue) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set(FiltersEnums.MIN_PRICE, price.min.toString());
        searchParams.set(FiltersEnums.MAX_PRICE, price.max.toString());
        searchParams.set(FiltersEnums.CURRENCY, price.currency);
        return searchParams;
      },
      { replace: true }
    );
  };

  const onChange = (value: string) => {
    if (value) {
      setSearchParams({ [FiltersEnums.UNIT_NAME]: value }, { replace: true });
    } else {
      setSearchParams(
        (searchParam) => {
          searchParam.delete(FiltersEnums.UNIT_NAME);
          return searchParam;
        },
        {
          replace: true,
        }
      );
    }
  };
  const debouncedOnChange = debounce(onChange, 500);

  return (
    <div className={styles['wrapper']}>
      <div className={`${styles['dropdowns']} ${styles['dropdowns-layout']}`}>
        <div className={styles['search-input']}>
          <img src={searchIcon} alt="" role="presentation" />
          <input
            type="text"
            placeholder={t('searchUnitById')}
            onChange={(e) => debouncedOnChange(e.target.value)}
          />
        </div>
        <MultiFilterDropdown
          isLoading={isLoadingFilters}
          filterOptions={convertToOptionsWithSlug(unitFilters?.countries)}
          filterKey="countries"
          showLabel={false}
          placeholder="Country"
          containerClassName="dropdown-container"
        />
        <MultiFilterDropdown
          isLoading={isLoadingFilters}
          filterOptions={unitFilters?.unit_types}
          filterKey="unit_types"
          labelTranslationKey="unitType"
          showLabel={false}
          placeholder="Unit Type"
          containerClassName="dropdown-container"
        />
        <NavyButton onClick={onShowFiltersModal}>{t('moreFilters')}</NavyButton>
      </div>

      <Modal
        title={t('allFilters')}
        sideModal
        sideModalWidth
        show={showFiltersModal}
        onClose={onCloseModal}
      >
        <div className={styles['wrapper']}>
          <div
            className={`${styles['dropdowns']} ${styles['filters-wrapper']}`}
          >
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertToOptionsWithSlug(
                unitFilters?.destinations
              )}
              filterKey="destinations"
              showLabel={false}
              placeholder="Destination"
              containerClassName=""
            />
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertToOptionsWithSlug(unitFilters?.projects)}
              filterKey="projects"
              labelTranslationKey="neighborhoods"
              showLabel={false}
              placeholder="Neighborhoods"
              containerClassName=""
            />
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={unitFilters?.unit_types}
              filterKey="unit_types"
              labelTranslationKey="unitType"
              showLabel={false}
              placeholder="Unit Type"
              containerClassName=""
            />
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertNumFiltersToOptions(
                unitFilters?.built_up_areas
              )}
              filterKey="built_up_areas"
              labelTranslationKey="unitSize"
              getOptionLabel={(v) => `${v.name} ㎡`}
              showLabel={false}
              placeholder="Unit Size"
              containerClassName=""
            />
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertNumFiltersToOptions(unitFilters?.bedrooms)}
              filterKey="bedrooms"
              labelTranslationKey="noOfBedrooms"
              containerClassName=""
              placeholder="No of bedrooms"
              showLabel={false}
            />
            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertNumFiltersToOptions(unitFilters?.bathrooms)}
              filterKey="bathrooms"
              labelTranslationKey="noOfBathrooms"
              containerClassName=""
              placeholder="No of bathrooms"
              showLabel={false}
            />

            <PriceRangeSlider
              disabled={isLoadingCurrency || isLoadingFilters}
              priceSlider={priceSlider}
              onChange={(val) => {
                setPriceValue({ ...priceSlider.price, ...val });
              }}
              onChangeEnd={(val) => onPriceChangeEnd(val)}
              customMargin={0}
            />

            <MultiFilterDropdown
              isLoading={isLoadingFilters}
              filterOptions={convertStringFiltersToOptions(
                unitFilters?.finishing_status
              )}
              filterKey="finishing_status"
              labelTranslationKey="finishingStatus"
              containerClassName=""
              placeholder="Finishing status"
              showLabel={false}
            />

            {showResale && (
              <OnResaleButton
                wrapperClassName={styles['mt-on-bg-screen']}
                disabled={isLoadingFilters}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
