import { FiltersUnits } from '@orascom/broker-sales-man-common-components';
import { useTranslation } from 'react-i18next';
import { Unit as UnitUtils } from '../../utils/unit';
import { Filter as FilterApi } from '../../utils/filter';
import { ROUTES } from '../../api/routes';

export function PrimaryUnits() {
  const { t } = useTranslation();

  return (
    <FiltersUnits
      portal="broker"
      title={t('primaryUnits')}
      onResale={false}
      getUnitFilters={FilterApi.getUnitFilters}
      getUnits={UnitUtils.getUnits}
      comparePropertiesPath={ROUTES['CompareProperties'].path}
      unitDetailsPath={ROUTES['UnitDetails'].getPath}
      addSavedUnit={UnitUtils.addSavedUnit}
      deleteSavedUnit={UnitUtils.deleteSavedUnit}
    />
  );
}

export default PrimaryUnits;
