import {
  Destination as DestinationInterface,
  DestinationFacilities as DestinationFacilitiesInterface,
  DestinationEducation as DestinationEducationInterface,
  DestinationProject as DestinationProjectInterface,
  DestinationFAQ as DestinationFAQInterface,
  Location,
} from '@orascom/api-interfaces';
import { Loader } from '@orascom/common-components';
import styles from './destination-details.module.scss';
import {
  DestinationEducation,
  DestinationFacilities,
  DestinationFaq,
  DestinationGallery,
  DestinationLocation,
  DestinationMasterplan,
  DestinationProjects,
  DestinationStory,
  DestinationVideo,
} from '@orascom/broker-sales-man-common-components';

export interface DestinationDetailsUIProps {
  destinationInfo: DestinationInterface | undefined;
  destinationLocations: Location[];
  loading: boolean;
  destinationFacilities: DestinationFacilitiesInterface[];
  destinationEducation?: DestinationEducationInterface[];
  destinationProjects: DestinationProjectInterface[];
  destinationFAQs?: DestinationFAQInterface[];
  primaryUnitsPath: string;
}
export function DestinationDetailsUI(
  props: Readonly<DestinationDetailsUIProps>
) {
  if (props.loading || !props.destinationInfo) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{props.destinationInfo.name}</h1>
      <DestinationStory destinationInfo={props.destinationInfo} />
      <DestinationLocation
        image={props.destinationInfo.location_image}
        title={props.destinationInfo.location_title}
        description={props.destinationInfo.location_description}
        info={props.destinationLocations}
      />
      <DestinationMasterplan
        image={props.destinationInfo.masterplan_image}
        alt={props.destinationInfo.name}
      />
      <DestinationFacilities facilities={props.destinationFacilities} />
      {props.destinationEducation && props.destinationEducation.length > 0 && (
        <DestinationEducation education={props.destinationEducation} />
      )}
      <DestinationProjects
        projects={props.destinationProjects}
        pathname={props.primaryUnitsPath}
      />
      {props.destinationInfo.video && (
        <DestinationVideo
          destinationName={props.destinationInfo.name}
          playlistUrl={props.destinationInfo.video_playlist}
          videoUrl={props.destinationInfo.video}
        />
      )}
      <DestinationGallery gallery={props.destinationInfo.gallery} />
      {props.destinationFAQs && Boolean(props.destinationFAQs?.length) && (
        <DestinationFaq FAQs={props.destinationFAQs} />
      )}
    </div>
  );
}

export default DestinationDetailsUI;
