import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from '../../api/routes';
import styles from '../../pages/my-deals/my-deals.module.scss';
import pendingIcon from '../../assets/icons/bullet-point.png';
import approvedIcon from '../../assets/icons/approved.svg';
import closedIcon from '../../assets/icons/closed.svg';
import actionIcon from '../../assets/icons/action.svg';
import CircularArrow from '../../components/common/circular-arrow/circular-arrow';
import {
  DealInterface,
  DealStatusEnum,
  SaleInterface,
} from '../../definitions/interfaces/deals.interface';
import WarningIcon from '../../assets/icons/error.svg';
import { replaceUnderscoresWithSpaces } from '../../utils/deal';

interface DealCardProps {
  deal: DealInterface;
  status:
    | DealStatusEnum.LEAD_SUBMITTED
    | DealStatusEnum.LEAD_VERIFIED
    | DealStatusEnum.DEAL_CLOSED
    | DealStatusEnum.SUBMITTED_INVOICE
    | DealStatusEnum.INVOICE_APPROVED
    | DealStatusEnum.LEAD_RESERVED
    | DealStatusEnum
    | null
    | undefined;
  duplicateDeal?: boolean;
  pastDeal?: boolean;
  sales?: SaleInterface[];
}

export function DealCard(props: Readonly<DealCardProps>) {
  const multipleSalesDeal = props.sales && props.sales?.length > 1;

  const getIconForStatus = (status: DealStatusEnum) => {
    switch (status) {
      case DealStatusEnum.LEAD_SUBMITTED:
      case DealStatusEnum.LEAD_RESERVED:
      case DealStatusEnum.SUBMITTED_INVOICE:
        return pendingIcon;
      case DealStatusEnum.LEAD_VERIFIED:
      case DealStatusEnum.INVOICE_APPROVED:
        return approvedIcon;
      case DealStatusEnum.DEAL_CLOSED:
        return actionIcon;
      case DealStatusEnum.PAST_DEALS:
        return closedIcon;
    }
  };
  return (
    <Link
      to={ROUTES['DealDetails'].getPath?.(
        props.duplicateDeal
          ? props.deal.lead_history_source_id
          : props.deal.lead_source_id,
        props.deal.country_id,
        props.duplicateDeal ? '1' : '0',
        props.deal.broker.id
      )}
      className={`card ${styles['deal']}`}
    >
      <div className={styles['name']}>
        {props.deal.name}
        {props.deal.same_company ? (
          <div className={styles['icon-container']}>
            <img src={WarningIcon} alt="" className={styles['warning-icon']} />
            <span className={styles['tooltip']}>Internal Duplication</span>
          </div>
        ) : null}
      </div>
      <div className={styles['details']}>
        <div className={styles['info']}>
          <p className={styles['label']}>Verification Date</p>
          <div className={styles['value']}>
            {props.deal.sales_admin_verification_date}
          </div>
        </div>
        <div className={styles['info']}>
          <p className={styles['label']}>Phone Number</p>
          <div className={styles['value']}>{props.deal.phone}</div>
        </div>
        <div className={styles['info']}>
          <p className={styles['label']}>Destination</p>
          <div className={styles['value']}>
            {props.deal.destinationName?.join(', ')}
          </div>
        </div>
        <div className={styles['info']}>
          <p className={styles['label']}>Deal Status</p>
          <div className={styles['value']}>
            {props.status ? (
              <img
                src={getIconForStatus(props.status)}
                alt=""
                role="presentation"
              />
            ) : null}
            {props.status ? replaceUnderscoresWithSpaces(props.status) : null}
            {multipleSalesDeal ? `+ ${props.sales.length - 1} More` : ''}
            {multipleSalesDeal ? (
              <div className={styles['value--hovered']}>
                {props.sales.map((sale) => (
                  <p key={uuidv4()}>
                    {' '}
                    {sale.unit?.name ?? 'no unit name'}:{' '}
                    {sale.deal_status
                      ? replaceUnderscoresWithSpaces(sale.deal_status)
                      : null}
                  </p>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <CircularArrow />
      </div>
    </Link>
  );
}

export default DealCard;
