import { Location } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import styles from './destination-location.module.scss';

export interface DestinationLocationProps {
  image: string;
  title: string;
  description: string;
  info: Location[];
}

export function DestinationLocation(props: Readonly<DestinationLocationProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      <img
        src={props.image}
        alt={props.title}
        className={styles['image']}
        loading="lazy"
      />
      <div className={styles['details']}>
        <h3>{props.title}</h3>
        <p>{props.description}</p>
        <div className={styles['info-wrapper']}>
          {props.info?.map((l) => (
            <div className={styles['info']} key={l.id}>
              <p className={styles['label']}>
                {l.time} {l.unit} {t('from')}
              </p>
              <h3 className={styles['value']}>{l.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DestinationLocation;
