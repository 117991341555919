import { useContext, useEffect, useState } from 'react';
import Numeral from 'numeral';
import {
  UnitCompareInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import styles from './compare-properties.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LeftArrowIcon from '../../assets/icons/left-arrow.svg?react';
import AddToCompareIcon from '../../assets/icons/add-to-compare.svg?react';
import closeIcon from '../../assets/icons/close.svg';
import { GoldButton, Loader } from '@orascom/common-components';
import {
  BROKER_COMPARE_UNITS_LIMIT,
  useCompareUnitsContext,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import {
  CommonEventParameters,
  CurrencyContext,
  useCurrencyConverter,
} from '@orascom/utils';
import UnitPaymentCalculator from '../unit-payment-calculator/unit-payment-calculator';
import ReportIcon from '../../assets/icons/download-report.svg';

export interface ComparePropertiesPageProps {
  getCompareUnits(unitsIds: number[]): Promise<UnitCompareInterface[]>;
  getUnitPaymentTerms(unitId: number): Promise<UnitPaymentTerms[]>;
  primaryUnitsRoute: string;
  unitDetailsGetPath?: Function;
  unitDealGetPath?: Function;
  analyticsNewtDealCustomEvent?(params: CommonEventParameters): void;
  getExportCompareUnits?: (
    unitsIds: number[],
    selectedCurrency: string
  ) => Promise<Blob>;
}

export const ComparePropertiesPage = (
  props: Readonly<ComparePropertiesPageProps>
) => {
  const [compareUnits, setCompareUnits] = useState<UnitCompareInterface[]>([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const { compareUnitsIds, clearCompareUnits, removeUnitFromCompare } =
    useCompareUnitsContext();
  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { convertToSelectedCurrency } = useCurrencyConverter();

  const { currency: selectedCurrency } = useContext(CurrencyContext);

  useEffect(() => {
    // we are updating the state on the client side only for better UX
    if (compareUnits?.length || !compareUnitsIds) return;

    if (compareUnitsIds?.length) {
      setIsLoadingUnits(true);
      props
        .getCompareUnits(compareUnitsIds)
        .then((res) => setCompareUnits(res))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoadingUnits(false);
        });
    } else {
      setIsLoadingUnits(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareUnitsIds]);

  function onRemoveUnitFromCompare(id: number) {
    removeUnitFromCompare(id);
    setCompareUnits((units) => units?.filter((unit) => unit.id !== id));
  }

  function onClearCompareUnits() {
    clearCompareUnits();
    setCompareUnits([]);
  }

  if (isLoadingUnits) {
    return <Loader />;
  }

  const shouldShowEmptyState = compareUnitsIds?.length === 0;

  if (shouldShowEmptyState) {
    return (
      <div className={`container ${styles['compare-units__empty-state']}`}>
        <p>{t('noPropertiesToCompare')}</p>
        <Link className={`btn btn--navy`} to={props.primaryUnitsRoute}>
          {t('browseUnits')}
        </Link>
      </div>
    );
  }

  const shouldShowAddBtn = compareUnits.length < BROKER_COMPARE_UNITS_LIMIT;

  const shouldShowClearBtn = compareUnits.length > 0;
  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
  };

  const handleExportCompare = () => {
    if (compareUnitsIds) {
      props
        .getExportCompareUnits?.(compareUnitsIds, selectedCurrency)
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'compare-units.xlsx';
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => {
          console.error('There was an error downloading the file:', error);
        });
    }
  };
  return (
    <div className={styles['compare-units']}>
      <button
        type="button"
        className={styles['compare-units__back-btn']}
        onClick={() => navigate(props.primaryUnitsRoute)}
      >
        <LeftArrowIcon fontSize="28px" />
        {t('back')}
      </button>
      <div className={styles['compare-units__header']}>
        <div className={styles['compare-units__header__title']}>
          {' '}
          <h1>
            {t('compareProperties', {
              count: compareUnits.length,
            })}
          </h1>
          {shouldShowClearBtn && (
            <button
              type="button"
              onClick={onClearCompareUnits}
              className={styles['compare-units__header__title-clear']}
            >
              {t('clearAll')}
            </button>
          )}
        </div>

        {props.getExportCompareUnits && (
          <button
            onClick={handleExportCompare}
            className={styles['compare-units__header__export']}
          >
            <img src={ReportIcon} alt="" role="presentation" />
            {t('downloadCompareUnits')}
          </button>
        )}
      </div>
      <div className={`card ${styles['comparison-table']}`}>
        <div className={styles['row']}>
          {compareUnits.map((unit) => (
            <Link
              key={unit.id}
              to={props.unitDetailsGetPath?.(unit.id)}
              className={styles['unit-card']}
            >
              <button
                className={styles['unit-card__remove-btn']}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemoveUnitFromCompare(unit.id);
                }}
              >
                <img
                  className={styles['unit-card__remove-btn__close-icon']}
                  src={closeIcon}
                  alt=""
                  role="presentation"
                />
              </button>
              <img
                className={styles['unit-card__image']}
                src={unit.cover_image}
                alt={unit.unit_type.name}
                loading="lazy"
              />

              <div className={styles['unit-card__info']}>
                <p>{`${unit.project.destination.name} - ${unit.project.name}`}</p>
                <p>{unit.unit_type.name}</p>
              </div>
            </Link>
          ))}
          {shouldShowAddBtn && (
            <div className={styles['add-button-column']}>
              <button
                type="button"
                className={styles['add-button']}
                onClick={() => navigate(props.primaryUnitsRoute)}
              >
                <AddToCompareIcon />
              </button>
              <span>{t('addProperty')}</span>
            </div>
          )}
        </div>
        <div className={styles['row-title']}>{t(`destination`)}</div>
        <div className={`${styles['row']} bg-white-300`}>
          {compareUnits.map((unit) => (
            <div key={unit.id} className={styles['column']}>
              {unit.project.destination.name}
            </div>
          ))}
        </div>
        <div className={styles['row-title']}>{t(`builtUpArea`)}</div>
        <div className={`${styles['row']} bg-white-300`}>
          {compareUnits.map((unit) => (
            <div key={unit.id} className={styles['column']}>
              {unit.built_up_area} M²
            </div>
          ))}
        </div>
        <div className={styles['row-title']}>{t(`bedrooms`)}</div>
        <div className={`${styles['row']} bg-shadow`}>
          {compareUnits.map((unit) => (
            <div key={unit.id} className={styles['column']}>
              {unit.bedrooms > 0 ? unit.bedrooms : null}
            </div>
          ))}
        </div>
        <div className={styles['row-title']}>{t(`bathrooms`)}</div>
        <div className={`${styles['row']} bg-white-300`}>
          {compareUnits.map((unit) => (
            <div key={unit.id} className={styles['column']}>
              {unit.bathrooms > 0 ? unit.bathrooms : null}
            </div>
          ))}
        </div>
        <div className={styles['row-title']}>{t('unitPrice')}</div>
        <div className={`${styles['row']} bg-shadow`}>
          {compareUnits?.map((unit) => {
            const { price, currency } = convertToSelectedCurrency(
              unit.price,
              unit.currency
            );
            return (
              <td key={unit.id} className={styles['column']}>
                {Numeral(price).format('0,0')} {currency}
              </td>
            );
          })}
        </div>

        <div className={styles['row-title']}>{t('masterPlan')}</div>
        <div className={styles['row']}>
          {compareUnits.map((unit) =>
            unit.masterplan_image ? (
              <td key={unit.id} className={styles['master-plan']}>
                <div className={styles['master-plan-image']}>
                  <img
                    src={unit.masterplan_image}
                    alt="master-plan"
                    loading="lazy"
                  />
                </div>
              </td>
            ) : (
              <td key={unit.id}>{t('noImageFound')}</td>
            )
          )}
        </div>

        <div className={styles['row-title']}>{t('paymentPlan')}</div>
        <div className={styles['row']}>
          {compareUnits.map((unit) => (
            <div key={unit.id} className={styles['payment-plan']}>
              <UnitPaymentCalculator
                getUnitPaymentTerms={props.getUnitPaymentTerms}
                unitCountry={unit.project.destination.country.slug}
                saveButton={
                  <GoldButton
                    onClick={() => {
                      console.log('hi');
                    }}
                  >
                    {t('saveAndProceedToCheckout')}
                  </GoldButton>
                }
                comparePage={true}
                hideDetails
                unitId={unit.id}
              />
            </div>
          ))}
        </div>
        <div className={styles['row']}>
          {compareUnits.map((unit) => (
            <div key={unit.id} className={styles['deal-container']}>
              <Link
                to={props.unitDealGetPath?.(unit?.id)}
                className={styles['deal-anchor']}
                onClick={() =>
                  props.analyticsNewtDealCustomEvent?.(analyticsEventParams)
                }
              >
                {t('submitInterest')}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComparePropertiesPage;
