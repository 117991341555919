import { Link } from 'react-router-dom';
import styles from './destination-video.module.scss';
import { useTranslation } from 'react-i18next';
import arrowIcon from '../../assets/icons/right-arrow.svg';
import { VideoPlayer } from '@orascom/common-components';

export interface DestinationVideoProps {
  videoUrl: string;
  playlistUrl?: string;
  destinationName: string;
}

export function DestinationVideo(props: Readonly<DestinationVideoProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <div className={styles['title']}>
          {props.destinationName} {t('inMotion')}
        </div>
        {props.playlistUrl && (
          <Link
            target="_blank"
            to={props.playlistUrl}
            className={styles['playlist-btn']}
          >
            {t('seePlaylist')}
            <img src={arrowIcon} alt="" role="presentation" />
          </Link>
        )}
      </div>
      <VideoPlayer videoUrl={props.videoUrl} className="video-rounded" />
    </div>
  );
}
export default DestinationVideo;
