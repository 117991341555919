/* eslint-disable import/no-extraneous-dependencies */
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import styles from './new-meetings.module.scss';
import { MeetingInterface } from '../../definitions/interfaces/inputs/new-deal';
import { meetingStatusData } from '../../utils/deal';

export interface NewMeetingsProps {
  brokerMeetings: MeetingInterface[];
}

export function NewMeetings(props: Readonly<NewMeetingsProps>) {
  const { t } = useTranslation();

  props.brokerMeetings.sort(
    (a: MeetingInterface, b: MeetingInterface) =>
      Number(new Date(b.meeting_date)) - Number(new Date(a.meeting_date))
  );

  const upComingMeetings = props.brokerMeetings.filter(
    (meeting) => new Date(meeting.meeting_date) > new Date()
  );

  return (
    <div className={styles['wrapper']}>
      <h3 className={styles['title']}>{t('upcomingMeetings')}</h3>
      <div className={styles['meetings']}>
        {upComingMeetings.length ? (
          upComingMeetings.slice(0, 3).map((meeting) => {
            return (
              <div key={uuidv4()} className={styles['meeting-wrapper']}>
                <div className={styles['meeting']}>
                  <div
                    className={`${
                      styles[
                        meetingStatusData(meeting.approval_status)
                          .statusRingClassName
                      ]
                    }  ${styles['status-ring']}`}
                  />
                  <div className={styles['details']}>
                    <h5>{`Meeting with ${meeting.lead.name ?? 'no name'}`}</h5>
                    <p>{`${format(
                      new Date(meeting.meeting_date),
                      'hh:mm aa'
                    )} | ${format(
                      new Date(meeting.meeting_date),
                      'EEE. MMM d, yyyy'
                    )}`}</p>
                  </div>
                  <span
                    className={`status ${
                      meetingStatusData(meeting.approval_status).statusClassName
                    }`}
                  >
                    {meetingStatusData(meeting.approval_status).statusText}
                  </span>
                </div>
                <hr />
              </div>
            );
          })
        ) : (
          <div className={styles['meeting-wrapper']}>
            <p className={styles['empty-placholder']}>
              {t('noUpcomingMeetings')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewMeetings;
