import logo from '../../assets/icons/logo-horizontal.png';
import emptyFloorImage from '../../assets/images/empty-floor.webp';
import styles from './not-found-ui.module.scss';
import { Link, To } from 'react-router-dom';

export interface NotFoundPageUIProps {
  dashboardRoute: To;
  backHome: string;
  error404: string;
  notFoundPageTitle1: string;
  notFoundPageTitle2: string;
  notFoundPageDesc1: string;
  notFoundPageDesc2: string;
}
export function NotFoundPageUI(props: Readonly<NotFoundPageUIProps>) {
  return (
    <div className={styles['wrapper']}>
      <img
        src={emptyFloorImage}
        alt="empty floor building"
        className={styles['image']}
        loading="lazy"
      />
      <div className={styles['not-found-info']}>
        <img
          src={logo}
          alt=""
          role="presentation"
          className={styles['logo']}
          loading="lazy"
        />
        <h1>{props.error404}</h1>
        <h2>
          {props.notFoundPageTitle1}
          <br />
          {props.notFoundPageTitle2}
        </h2>
        <p>
          {props.notFoundPageDesc1}
          <br />
          {props.notFoundPageDesc2}
        </p>

        <Link to={props.dashboardRoute} className={`btn btn--navy`}>
          {props.backHome}
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPageUI;
