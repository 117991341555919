import styles from './broker-international-sales.module.scss';
import { AnalyticsInternationalSales } from '../../../definitions/interfaces/analytics';
import { ProgressBar } from '@orascom/broker-sales-man-common-components';

export interface BrokerInternationalSalesProps {
  currency: string;
  internationalSales?: AnalyticsInternationalSales;
}

export function BrokerInternationalSales(
  props: Readonly<BrokerInternationalSalesProps>
) {
  if (!props.internationalSales) {
    return <p>no international sales found</p>;
  }

  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      <div className={`card ${styles['deal-lifecycle-wrapper']}`}>
        <div className={`${styles['grid']} ${styles['headers']}`}>
          <p>TYPE</p>
          <p className={styles['count']}>SALES</p>
        </div>
        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>All International Sales (Sales Team + All Brokers)</h4>
          <ProgressBar
            completedPercentage={100}
            completedNumber={props.internationalSales.all_international_sales}
            currency={props.currency}
            showPercentageInBar
          />
        </div>

        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>Sales Team International Sales</h4>
          <ProgressBar
            completedPercentage={props.internationalSales.team_percent}
            completedNumber={props.internationalSales.team_international_sales}
            currency={props.currency}
            showPercentageInBar
          />
        </div>

        <div className={`${styles['grid']} ${styles['content']}`}>
          <h4>My Broker International Sales</h4>
          <ProgressBar
            completedPercentage={props.internationalSales.manager_percent}
            completedNumber={
              props.internationalSales.manager_international_sales
            }
            currency={props.currency}
            showPercentageInBar
          />
        </div>
      </div>
    </div>
  );
}

export default BrokerInternationalSales;
