import {
  ErrorMessage,
  Field,
  FieldArray,
  FormikErrors,
  FormikTouched,
} from 'formik';
import styles from './multiple-phone-numbers-fields.module.scss';
import { validatePhone } from '@orascom/utils';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import removeIcon from '../../assets/icons/close.svg';
import plusIcon from '../../assets/icons/plus.svg';

/* eslint-disable-next-line */
interface phoneNumber {
  countryCode: string;
  phone: string;
}
export interface MultiplePhoneNumbersFieldsProps {
  phoneNumbers: phoneNumber[];
  errors: FormikErrors<{
    phoneNumbers: phoneNumber[];
  }>;
  touched: FormikTouched<{
    phoneNumbers: phoneNumber[];
  }>;

  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<{
    phoneNumbers: phoneNumber[];
  }>>;
}

export function MultiplePhoneNumbersFields(
  props: MultiplePhoneNumbersFieldsProps
) {
  const { t } = useTranslation();

  return (
    <div className="form__input">
      <label htmlFor="phoneNumbers">{t('phoneNumber')}</label>
      <FieldArray name="phoneNumbers">
        {({ push, remove }) => (
          <div className={styles['input-wrapper']}>
            {props.phoneNumbers.map((_, index) => (
              <div key={index} className={styles['phone-container']}>
                <div>
                  <div className={styles['phone-wrapper']}>
                    <PhoneInput
                      countryCodeEditable={false}
                      inputProps={{
                        name: 'countryCode',
                      }}
                      country={'eg'}
                      onChange={(phone) => {
                        props.setFieldValue(
                          `phoneNumbers[${index}].countryCode`,
                          phone
                        );
                        props.setFieldValue(`phoneNumbers[${index}].phone`, '');
                      }}
                      enableSearch={true}
                      value={props.phoneNumbers[index].countryCode}
                    />
                    <Field
                      name={`phoneNumbers.[${index}].phone`}
                      type="tel"
                      validate={() =>
                        validatePhone(
                          props.phoneNumbers[index].phone,
                          props.phoneNumbers[index].countryCode ?? ''
                        )
                      }
                      className={
                        props.errors.phoneNumbers?.[index] &&
                        props.touched.phoneNumbers?.[index]
                          ? 'error'
                          : ''
                      }
                      placeholder="123 456 7890"
                    />
                  </div>
                  <ErrorMessage
                    name={`phoneNumbers[${index}].phone`}
                    component="div"
                    className="form__error"
                  />
                </div>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className={styles['remove-btn']}
                  >
                    <img src={removeIcon} alt="Remove Icon" />
                  </button>
                )}
              </div>
            ))}
            {props.phoneNumbers.length < 3 && (
              <div className={styles['btn-wrapper']}>
                <button
                  className={styles['add-btn']}
                  type="button"
                  onClick={() => push({ countryCode: '20', phone: '' })}
                >
                  <img src={plusIcon} alt="" role="presentation" />
                  {t('addPhoneNumber')}
                </button>
              </div>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
}

export default MultiplePhoneNumbersFields;
