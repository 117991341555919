/**
 * @author Salma Hefnawy
 * @date 2022-10-02
 * @description implementation of Unit related utility functions.
 * @filename unit.ts
 */

import {
  UnitDetails as UnitDetailsInterface,
  PaginationPayload,
  UnitInterface,
  OptionValue,
  Payload,
  UnitPaymentTerms,
  FiltersEnums,
  UnitCompareInterface,
} from '@orascom/api-interfaces';
import {
  constructSimilarUnitsParams,
  downloadSalesOfferOman,
  getPayloadData,
  handleError,
} from '@orascom/utils';
import { Unit as UnitAPI } from '../api/unit';
/**
 * group of Unit helpers functionalities.
 */
export class Unit {
  public static getUnits(
    page: number,
    perPage: number,
    searchParams?: URLSearchParams,
    unitId?: string,
    price?: number,
    designType?: string,
    similarUnits?: boolean
  ): Promise<PaginationPayload<UnitInterface[]>> {
    if (searchParams) {
      // on_resale is 0 by default (temporary)
      if (!searchParams.has(FiltersEnums.ON_RESALE) && !similarUnits) {
        searchParams.append(FiltersEnums.ON_RESALE, '0');
      }

      searchParams.append('page', page.toString());
      searchParams.append('per_page', perPage.toString());
      if (unitId) {
        searchParams.append('exclude_id', unitId.toString());
      }
      if (price) {
        searchParams.append('min_dollar_price', (price * 0.8).toFixed());
        searchParams.append('max_dollar_price', (price * 1.2).toFixed());
      }
      if (designType) {
        searchParams.append('design_types[]', designType);
      }
    }

    return UnitAPI.getUnits(searchParams)
      .then((result) => {
        return result.data[0];
      })
      .catch((error: string | object) => {
        return Promise.reject(handleError(error)); //NOSONAR
      });
  }

  public static getSimilarUnits(
    unitId: string,
    price: number,
    designType: string
  ): Promise<PaginationPayload<UnitInterface[]>> {
    const queryString = constructSimilarUnitsParams(unitId, price, designType);
    return UnitAPI.getUnits(queryString)
      .then((result) => {
        if (result.data[0]) {
          return result.data[0];
        }
        return result.data as unknown as PaginationPayload<UnitInterface[]>;
      })
      .catch((error: unknown) => {
        return Promise.reject(handleError(error)); //NOSONAR
      });
  }

  public static getUnitPaymentTerms(
    unitId: number
  ): Promise<UnitPaymentTerms[]> {
    return UnitAPI.getUnitPaymentTerms(unitId)
      .then((result) => {
        return Promise.resolve(result.data?.data ?? []);
      })
      .catch((error: unknown) => {
        return Promise.reject(handleError(error)); //NOSONAR
      });
  }

  public static getCompareUnits(
    unitsIds: number[]
  ): Promise<UnitCompareInterface[]> {
    return UnitAPI.getCompareUnits(unitsIds)
      .then((result) => {
        return Promise.resolve(getPayloadData(result));
      })
      .catch((error: unknown) => {
        return Promise.reject(handleError(error)); //NOSONAR
      });
  }

  public static getUnitDetails(unitId: number): Promise<UnitDetailsInterface> {
    return UnitAPI.getUnitDetails(unitId)
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getSavedUnits(
    page: number,
    perPage: number,
    destinations?: OptionValue[]
  ): Promise<PaginationPayload<UnitInterface[]>> {
    return UnitAPI.geSavedUnits(page, perPage, destinations)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error)); //NOSONAR
      });
  }

  public static addSavedUnit(unitId: number): Promise<Payload<void>> {
    return UnitAPI.addSavedUnit(unitId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static deleteSavedUnit(unitId: number): Promise<Payload<void>> {
    return UnitAPI.deleteSavedUnit(unitId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static downloadOmanSalesOffer(
    unitId: number,
    unitName: string
  ): Promise<any> {
    return UnitAPI.downloadOmanSalesOffer(unitId)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        downloadSalesOfferOman(url, unitName);
        return new Blob([res]);
      })
      .catch((err: Error) => {
        console.log(err);
      });
  }
}
