import { Unit } from '../../utils/unit';
import { ROUTES } from '../../api/routes';
import { analyticsNewtDealCustomEvent } from '../../utils/analytics-events';
import { Unit as UnitApi } from '../../api/unit';
import { ComparePropertiesPage as ComparePropertiesPageUi } from '@orascom/broker-sales-man-common-components';
const ComparePropertiesPage = () => {
  return (
    <ComparePropertiesPageUi
      getCompareUnits={Unit.getCompareUnits}
      getUnitPaymentTerms={Unit.getUnitPaymentTerms}
      primaryUnitsRoute={ROUTES['PrimaryUnits'].path}
      unitDetailsGetPath={ROUTES['UnitDetails'].getPath}
      unitDealGetPath={ROUTES['UnitDeal'].getPath}
      analyticsNewtDealCustomEvent={analyticsNewtDealCustomEvent}
      getExportCompareUnits={UnitApi.getExportCompareUnits}
    />
  );
};

export default ComparePropertiesPage;
