import { Analytics as AnalyticsApi } from '../api/analytics';
import { handleError } from '@orascom/utils';
import {
  AnalyticsCommissions,
  AnalyticsBrokerRankings,
  AnalyticsLeadsCount,
  AnalyticsRevenueInsights,
  SalesCountPerDestination,
  AnalyticsCommissionsRankingResponse,
  AnalyticsCompanyRanking,
} from '../definitions/interfaces/analytics';
import {
  FilterParams,
  FilterValue,
  PaginationPayload,
} from '@orascom/api-interfaces';
import { LineGraphData } from '@orascom/broker-sales-man-common-components';

export class Analytics {
  public static getAnalyticsLeadCount(
    params: FilterParams
  ): Promise<AnalyticsLeadsCount> {
    return AnalyticsApi.getAnalyticsLeadCount(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsCommissionsRanking(
    params: FilterParams
  ): Promise<AnalyticsCommissionsRankingResponse> {
    return AnalyticsApi.getAnalyticsCommissionsRanking(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsCompanyRanking(
    params: FilterParams
  ): Promise<AnalyticsCompanyRanking> {
    return AnalyticsApi.getAnalyticsCompanyRanking(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsRevenueInsights(
    params: FilterParams
  ): Promise<AnalyticsRevenueInsights> {
    return AnalyticsApi.getAnalyticsRevenueInsights(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsCommissions(
    params: FilterParams
  ): Promise<AnalyticsCommissions> {
    return AnalyticsApi.getAnalyticsCommissions(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsFilterDestinations(): Promise<FilterValue[]> {
    return AnalyticsApi.getAnalyticsFilterDestinations()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsFilterAssignedBrokers(): Promise<FilterValue[]> {
    return AnalyticsApi.getAnalyticsFilterAssignedBrokers()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsFilterUnitTypes(): Promise<FilterValue[]> {
    return AnalyticsApi.getAnalyticsFilterUnitTypes()
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsSalesCountPerDestination(
    params: FilterParams
  ): Promise<SalesCountPerDestination[]> {
    return AnalyticsApi.getAnalyticsSalesCountPerDestination(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsSalesCountPerUnitType(
    params: FilterParams
  ): Promise<LineGraphData[]> {
    return AnalyticsApi.getAnalyticsSalesCountPerUnitType(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getAnalyticsAgentsRanking(
    params: FilterParams
  ): Promise<PaginationPayload<AnalyticsBrokerRankings[]>> {
    return AnalyticsApi.getAnalyticsAgentsRanking(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}

export const lineColors = [
  '#003A70',
  '#DFBD69',
  '#FF7B00',
  '#C60000',
  '#547b9f',
  '#8e753e',
  '#f08686',
  '#a1d7bb',
  '#32a96c',
  '#1c5d3b',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
