/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './analytics-calendar.module.scss';
import LeftArrowIcon from '../../assets/icons/calendar-left-arrow.svg';
import RightArrowIcon from '../../assets/icons/calendar-right-arrow.svg';
import Tick from '../../assets/icons/tick-selected-dates.svg';
import { useTranslation } from 'react-i18next';
import { DateRange, PeriodType } from '@orascom/api-interfaces';

interface AnalyticsCalendarProps {
  value: DateRange;
  setValue(value: DateRange): void;
  handleSetSelectedDuration: (duration: PeriodType) => void;
  selectedDuration: PeriodType;
}
export default function AnalyticsCalendar(
  props: Readonly<AnalyticsCalendarProps>
) {
  const { t } = useTranslation();
  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleSetSelectedDuration(event.target.value as PeriodType);
    switch (event.target.value) {
      case 'today': {
        const today = new Date();
        props.setValue({
          startDate: today,
          endDate: today,
        });
        break;
      }
      case 'yesterday': {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        props.setValue({
          startDate: yesterday,
          endDate: yesterday,
        });
        break;
      }
      case 'last7days': {
        const last7DaysStartDate = new Date();
        last7DaysStartDate.setDate(last7DaysStartDate.getDate() - 7);
        props.setValue({
          startDate: last7DaysStartDate,
          endDate: new Date(),
        });
        break;
      }
      case 'last30days': {
        const last30DaysStartDate = new Date();
        last30DaysStartDate.setDate(last30DaysStartDate.getDate() - 29);
        props.setValue({
          startDate: last30DaysStartDate,
          endDate: new Date(),
        });
        break;
      }
      case 'last90days': {
        const last90DaysStartDate = new Date();
        last90DaysStartDate.setDate(last90DaysStartDate.getDate() - 89);
        props.setValue({
          startDate: last90DaysStartDate,
          endDate: new Date(),
        });
        break;
      }
      case 'last6months': {
        const last6MonthsStartDate = new Date();
        last6MonthsStartDate.setMonth(last6MonthsStartDate.getMonth() - 5);
        props.setValue({
          startDate: last6MonthsStartDate,
          endDate: new Date(),
        });
        break;
      }
      case 'lastyear': {
        const lastYearStartDate = new Date();
        lastYearStartDate.setFullYear(lastYearStartDate.getFullYear() - 1);
        props.setValue({
          startDate: lastYearStartDate,
          endDate: new Date(),
        });
        break;
      }
      default: {
        props.setValue({
          startDate: new Date(),
          endDate: new Date(),
        });
        break;
      }
    }
  };
  return (
    <div className={styles['wrapper']}>
      <div className={styles['duration-selection']}>
        <label
          className={`${styles['duration']} ${
            props.selectedDuration === 'today' ? styles['checkedText'] : ''
          }`}
        >
          <input
            type="radio"
            name="duration"
            value="today"
            checked={props.selectedDuration === 'today'}
            onChange={handleDurationChange}
          />
          {t('today')}
          {props.selectedDuration === 'today' && (
            <img src={Tick} alt="Checked Icon" />
          )}
        </label>
        <hr className={styles['divider']} />
        <label
          className={`${styles['duration']} ${
            props.selectedDuration === 'yesterday' ? styles['checkedText'] : ''
          }`}
        >
          <input
            type="radio"
            name="duration"
            value="yesterday"
            checked={props.selectedDuration === 'yesterday'}
            onChange={handleDurationChange}
          />
          {t('yesterday')}
          {props.selectedDuration === 'yesterday' && (
            <img src={Tick} alt="Checked Icon" />
          )}
        </label>
        <hr className={styles['divider']} />
        <label
          className={`${styles['duration']} ${
            props.selectedDuration === 'last7days' ? styles['checkedText'] : ''
          }`}
        >
          <input
            type="radio"
            name="duration"
            value="last7days"
            checked={props.selectedDuration === 'last7days'}
            onChange={handleDurationChange}
          />
          {t('last7Days')}
          {props.selectedDuration === 'last7days' && (
            <img src={Tick} alt="Checked Icon" />
          )}
        </label>
        <hr className={styles['divider']} />
        <label
          className={`${styles['duration']} ${
            props.selectedDuration === 'last30days' ? styles['checkedText'] : ''
          }`}
        >
          <input
            type="radio"
            name="duration"
            value="last30days"
            checked={props.selectedDuration === 'last30days'}
            onChange={handleDurationChange}
          />
          {t('last30Days')}
          {props.selectedDuration === 'last30days' && (
            <img src={Tick} alt="Checked Icon" />
          )}
        </label>
        <hr className={styles['divider']} />
        <label
          className={`${styles['duration']} ${
            props.selectedDuration === 'last90days' ? styles['checkedText'] : ''
          }`}
        >
          <input
            type="radio"
            name="duration"
            value="last90days"
            checked={props.selectedDuration === 'last90days'}
            onChange={handleDurationChange}
          />
          {t('last90Days')}
          {props.selectedDuration === 'last90days' && (
            <img src={Tick} alt="Checked Icon" />
          )}
        </label>
        <hr className={styles['divider']} />
        <label
          className={`${styles['duration']} ${
            props.selectedDuration === 'last6months'
              ? styles['checkedText']
              : ''
          }`}
        >
          <input
            type="radio"
            name="duration"
            value="last6months"
            checked={props.selectedDuration === 'last6months'}
            onChange={handleDurationChange}
          />
          {t('last6Months')}
          {props.selectedDuration === 'last6months' && (
            <img src={Tick} alt="Checked Icon" />
          )}
        </label>
        <hr className={styles['divider']} />
        <label
          className={`${styles['duration']} ${
            props.selectedDuration === 'lastyear' ? styles['checkedText'] : ''
          }`}
        >
          <input
            type="radio"
            name="duration"
            value="lastyear"
            checked={props.selectedDuration === 'lastyear'}
            onChange={handleDurationChange}
          />
          {t('lastYear')}
          {props.selectedDuration === 'lastyear' && (
            <img src={Tick} alt="Checked Icon" />
          )}
        </label>
      </div>
      <div className={styles['calendar-wrapper']}>
        <Calendar
          value={[props.value.startDate, props.value.endDate]}
          view={'month'}
          prevLabel={
            <img
              style={{ height: 'auto' }}
              src={LeftArrowIcon}
              alt=""
              role="presentation"
            />
          }
          nextLabel={
            <img
              style={{ height: 'auto' }}
              src={RightArrowIcon}
              alt=""
              role="presentation"
            />
          }
        />
      </div>
    </div>
  );
}
