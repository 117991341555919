import { useContext, useEffect, useState } from 'react';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Destination, Route, UserRole } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import {
  SidebarComponent,
  SidebarMenuItem,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { ROUTES } from '../../../api/routes';
import { Authentication as AuthenticationAPI } from '../../../api/authentication';
import { Destination as DestinationUtils } from '../../../utils/destination.utils';
import { toast } from 'react-toastify';
import DashboardIcon from '../../../assets/icons/dashboard.svg?react';
import DashboardActiveIcon from '../../../assets/icons/dashboard-active.svg?react';
import DestinationsIcon from '../../../assets/icons/destinations.svg?react';
import DestinationsActiveIcon from '../../../assets/icons/destinations-active.svg?react';
import PrimaryIcon from '../../../assets/icons/primary-units.svg?react';
import PrimaryActiveIcon from '../../../assets/icons/primary-units-active.svg?react';
import SavedIcon from '../../../assets/icons/saved-units.svg?react';
import SavedActiveIcon from '../../../assets/icons/saved-units-active.svg?react';
import DealsIcon from '../../../assets/icons/my-deals.svg?react';
import DealsActiveIcon from '../../../assets/icons/my-deals-active.svg?react';
import NewsIcon from '../../../assets/icons/news-events.svg?react';
import NewsActiveIcon from '../../../assets/icons/news-events-active.svg?react';
import AnalyticsIcon from '../../../assets/icons/analytics.svg?react';
import AnalyticsActiveIcon from '../../../assets/icons/analytics--active.svg?react';
import CommissionsIcon from '../../../assets/icons/commissions.svg?react';
import CommissionsActiveIcon from '../../../assets/icons/commissions--active.svg?react';
import RevenueIcon from '../../../assets/icons/revenue.svg?react';
import RevenueActiveIcon from '../../../assets/icons/revenue--active.svg?react';
import RankingsIcon from '../../../assets/icons/rankings.svg?react';
import RankingsActiveIcon from '../../../assets/icons/rankings--active.svg?react';
import { CommonEventParameters } from '@orascom/utils';
import {
  analyticsBrokerDashboardCustomEvent,
  analyticsBrokerDestinationCustomEvent,
  analyticsBrokerDestinationsCustomEvent,
  analyticsBrokerSavedUnitsCustomEvent,
  analyticsPrimaryUnitsCustomEvent,
} from '../../../utils/analytics-events';

function useAvailableDestinations(userRole: UserRole | undefined) {
  const [availableDestinations, setAvailableDestinations] = useState<
    Destination[]
  >([]);

  useEffect(() => {
    if (userRole === UserRole.BROKER) {
      DestinationUtils.listDestinations().then(setAvailableDestinations);
    }
  }, [userRole]);

  return availableDestinations;
}

export function Sidebar() {
  const [loggingOut, setLoggingOut] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(USER_CONTEXT);
  const userRole = userContext.user?.role;
  const availableDestinations = useAvailableDestinations(userRole);

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
  };

  const logoutHandler = () => {
    setLoggingOut(true);
    const promise =
      userRole === UserRole.BROKER
        ? AuthenticationAPI.logoutUser()
        : AuthenticationAPI.logoutSalesManager();

    promise
      .then((res) => {
        userContext?.setUser?.(null);
        toast.success(res.message);
        navigate(ROUTES['Login'].path);
      })
      .catch(toast.error)
      .finally(() => setLoggingOut(false));
  };
  const isActive = (routeName: Route, id?: string | number) =>
    location.pathname === routeName.path ||
    location.pathname === routeName.getPath?.(id);
  return (
    <SidebarComponent loggingOut={loggingOut} logoutHandler={logoutHandler}>
      {userRole === UserRole.BROKER ? (
        <>
          <SidebarMenuItem
            route={ROUTES['Dashboard']}
            icon={<DashboardIcon />}
            activeIcon={<DashboardActiveIcon />}
            isActive={isActive(ROUTES['Dashboard'])}
            label={t('dashboard')}
            onClick={() =>
              analyticsBrokerDashboardCustomEvent(analyticsEventParams)
            }
          />
          <SidebarMenuItem
            route={ROUTES['Analytics']}
            icon={<AnalyticsIcon />}
            activeIcon={<AnalyticsActiveIcon />}
            isActive={isActive(ROUTES['Analytics'])}
            label={t('analytics')}
          />
          <SubMenu
            label={t('destinations')}
            icon={
              location.pathname.includes('destination') ? (
                <DestinationsActiveIcon />
              ) : (
                <DestinationsIcon />
              )
            }
            active={location.pathname.includes('destination')}
            open={subMenuOpen}
            onOpenChange={() => setSubMenuOpen(!subMenuOpen)}
            onClick={() =>
              analyticsBrokerDestinationsCustomEvent(analyticsEventParams)
            }
          >
            {availableDestinations.map((destination) => (
              <MenuItem
                key={destination.id}
                component={
                  <Link
                    to={ROUTES['Destination'].getPath?.(destination.slug)}
                  />
                }
                active={isActive(ROUTES['Destination'], destination.slug)}
                onClick={() =>
                  analyticsBrokerDestinationCustomEvent({
                    name: destination.slug,
                    userId: userContext.user?.id.toString(),
                    timestamp: Date.now().toString(),
                    portal: 'Broker',
                    pageName: location.pathname,
                  })
                }
              >
                {destination.name}
              </MenuItem>
            ))}
          </SubMenu>
          <SidebarMenuItem
            route={ROUTES['PrimaryUnits']}
            icon={<PrimaryIcon />}
            activeIcon={<PrimaryActiveIcon />}
            isActive={isActive(ROUTES['PrimaryUnits'])}
            label={t('primaryUnits')}
            onClick={() =>
              analyticsPrimaryUnitsCustomEvent(analyticsEventParams)
            }
          />
          <SidebarMenuItem
            route={ROUTES['SavedUnits']}
            icon={<SavedIcon />}
            activeIcon={<SavedActiveIcon />}
            isActive={isActive(ROUTES['SavedUnits'])}
            label={t('savedUnits')}
            onClick={() =>
              analyticsBrokerSavedUnitsCustomEvent(analyticsEventParams)
            }
          />
          <SidebarMenuItem
            route={ROUTES['MyDeals']}
            icon={<DealsIcon />}
            activeIcon={<DealsActiveIcon />}
            isActive={
              isActive(ROUTES['MyDeals']) ||
              location.pathname.includes('deal-details')
            }
            label={t('myDeals')}
          />
          <SidebarMenuItem
            route={ROUTES['NewsEvents']}
            icon={<NewsIcon />}
            activeIcon={<NewsActiveIcon />}
            isActive={isActive(ROUTES['NewsEvents'])}
            label={t('newsEvents')}
          />
        </>
      ) : (
        <>
          <SidebarMenuItem
            route={ROUTES['SalesManagerCommissionsAnalytics']}
            icon={<CommissionsIcon />}
            activeIcon={<CommissionsActiveIcon />}
            isActive={isActive(ROUTES['SalesManagerCommissionsAnalytics'])}
            label={t('commissions')}
          />
          <SidebarMenuItem
            route={ROUTES['SalesManagerRevenueAnalytics']}
            icon={<RevenueIcon />}
            activeIcon={<RevenueActiveIcon />}
            isActive={isActive(ROUTES['SalesManagerRevenueAnalytics'])}
            label={t('revenue')}
          />
          <SidebarMenuItem
            route={ROUTES['SalesManagerRankingsAnalytics']}
            icon={<RankingsIcon />}
            activeIcon={<RankingsActiveIcon />}
            isActive={isActive(ROUTES['SalesManagerRankingsAnalytics'])}
            label={t('rankings')}
          />
        </>
      )}
    </SidebarComponent>
  );
}

export default Sidebar;
