import { ReactNode } from 'react';
import styles from './auth-wrapper.module.scss';
import loginCover from '../../assets/images/login-cover.webp';
export interface AuthWrapperProps {
  children: ReactNode;
}

export function AuthWrapper(props: Readonly<AuthWrapperProps>) {
  return (
    <div className={styles['wrapper']}>
      <img
        src={loginCover}
        alt="login"
        className={styles['image']}
        loading="lazy"
      />
      {props.children}
    </div>
  );
}

export default AuthWrapper;
