import { useContext, useEffect } from 'react';
import {
  Authentication as AuthenticationApi,
  removeTokenDetails,
} from '../../utils/authentication';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import {
  USER_CONTEXT,
  SetPasswordComponent,
} from '@orascom/broker-sales-man-common-components';
import { toast } from 'react-toastify';
import { errorMessagesHandler } from '@orascom/utils';
import { SetPasswordInput } from '@orascom/api-interfaces';

export function SetPassword() {
  const navigate = useNavigate();
  const userContext = useContext(USER_CONTEXT);

  useEffect(() => {
    if (userContext.user) {
      navigate(ROUTES['Dashboard'].path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext]);

  const submitHandler = (setPasswordInput: SetPasswordInput) => {
    AuthenticationApi.setPassword(setPasswordInput)
      .then((res) => {
        toast.success(res.message);
        userContext.setUser?.(res.data.user);
        removeTokenDetails();
        navigate(ROUTES['Dashboard'].path);
      })
      .catch((err) => errorMessagesHandler(err));
  };

  return (
    <SetPasswordComponent
      submitHandler={submitHandler}
      loginPath={ROUTES['Login'].path}
    />
  );
}

export default SetPassword;
