import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { format, addDays } from 'date-fns';
import { toast } from 'react-toastify';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { useTranslation } from 'react-i18next';
import styles from './new-meeting.module.scss';
import { SelectDropdown, Loader } from '@orascom/common-components';
import { NewMeetingInterface } from '../../definitions/interfaces/inputs/new-deal';
import { Deal as DealUtils } from '../../utils/deal';
import { OptionValue } from '@orascom/api-interfaces';
import { ROUTES } from '../../api/routes';

export function NewMeeting() {
  const today = new Date();

  //  TODO: these locations should be given by client
  const locations = [
    { label: 'Gouna Sales Office', value: 'Gouna Sales Office' },
    { label: 'Makadi Sales Office', value: 'Makadi Sales Office' },
    { label: 'Owest Sales Office', value: 'Owest Sales Office' },
    { label: 'Lustica Sales Office', value: 'Lustica Sales Office' },
    { label: ' Dubai Sales Office', value: ' Dubai Sales Office' },
    { label: ' Oman Sales Office', value: ' Oman Sales Office' },
  ];

  const [selectedDate, setSelectedDate] = useState(addDays(today, 1));
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState<OptionValue>(
    locations[0]
  );
  const [viewMoreDates, setViewMoreDates] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dealId, countryId, duplicate, brokerId } = useParams();

  const selectedTimestampResult = () => {
    if (selectedTime === 0) {
      return selectedDate.setHours(9, 30, 0);
    } else if (selectedTime === 1) {
      return selectedDate.setHours(11, 0, 0);
    } else if (selectedTime === 2) {
      return selectedDate.setHours(13, 0, 0);
    } else {
      return selectedDate.setHours(15, 0, 0);
    }
  };
  const selectedTimeStamp = selectedTimestampResult();

  const submitHandler = () => {
    const submittedData: NewMeetingInterface = {
      meeting_date_timestamp: Math.floor(selectedTimeStamp / 1000),
      meeting_location: selectedLocation.value.toString(),
    };

    if (dealId && countryId) {
      setLoading(true);
      DealUtils.createMeeting(dealId, countryId, submittedData)
        .then(() => {
          toast.success('Meeting Created Successfully');
          navigate(-1);
        })
        .catch((err) => {
          toast.error('failure to add meeting');
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };

  if (loading) {
    return <Loader />;
  }

  const getMeetingDateOption = (date: Date) => {
    return (
      <button
        className={`${styles['date']} ${
          selectedDate.getDate() === date.getDate() && styles['date--active']
        }`}
        onClick={() => setSelectedDate(date)}
      >
        <h6 className={styles['day']}>{format(date, 'E')}</h6>
        <h5 className={styles['month']}>
          {format(date, 'dd')} {format(date, 'MMM')}
        </h5>
      </button>
    );
  };

  return (
    <div className={styles['wrapper']}>
      <Link
        to={ROUTES['DealDetails'].getPath?.(
          dealId,
          countryId,
          duplicate,
          brokerId
        )}
        className={styles['back-anchor']}
      >
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('newMeeting')}</h1>
      <h3 className={styles['sub-title']}>{t('meetingInformation')}</h3>

      <h5 className={styles['label']}>{t('selectDate')}</h5>
      <div className={styles['dates']}>
        {getMeetingDateOption(addDays(today, 1))}
        {getMeetingDateOption(addDays(today, 2))}
        {getMeetingDateOption(addDays(today, 3))}
        {getMeetingDateOption(addDays(today, 4))}
        {!viewMoreDates && (
          <div className={styles['date']}>
            <button
              type="button"
              className={styles['more']}
              onClick={() => setViewMoreDates(true)}
            >
              {t('viewMoreDates')}
            </button>
          </div>
        )}

        {viewMoreDates && (
          <>
            {getMeetingDateOption(addDays(today, 5))}
            {getMeetingDateOption(addDays(today, 6))}
            {getMeetingDateOption(addDays(today, 7))}
            {getMeetingDateOption(addDays(today, 8))}
          </>
        )}
      </div>

      <h5 className={styles['label']}>{t('selectTimeSlot')}</h5>
      <div className={styles['time-slots']}>
        <button
          className={`${styles['time']} ${
            selectedTime === 0 && styles['time--active']
          }`}
          onClick={() => setSelectedTime(0)}
        >
          09:30 AM - 10:30 AM
        </button>
        <button
          className={`${styles['time']} ${
            selectedTime === 1 && styles['time--active']
          }`}
          onClick={() => setSelectedTime(1)}
        >
          11:00 AM - 12:00 PM
        </button>
        <button
          className={`${styles['time']} ${
            selectedTime === 2 && styles['time--active']
          }`}
          onClick={() => setSelectedTime(2)}
        >
          01:00 PM - 02:00 PM
        </button>
        <button
          className={`${styles['time']} ${
            selectedTime === 3 && styles['time--active']
          }`}
          onClick={() => setSelectedTime(3)}
        >
          03:00 PM - 04:00 PM
        </button>
      </div>

      <h5 className={styles['label']}>{t('meetingLocation')}</h5>
      <SelectDropdown
        options={locations}
        placeholder="Select Location"
        selectedOption={selectedLocation}
        onChange={(val) => {
          if (val) {
            setSelectedLocation(val);
          }
        }}
      />
      <div className={styles['buttons']}>
        <button
          className="btn btn--navy"
          onClick={submitHandler}
          disabled={loading}
        >
          {t('createMeeting')}
        </button>
        <Link
          to={ROUTES['DealDetails'].getPath?.(
            dealId,
            countryId,
            duplicate,
            brokerId
          )}
          className="anchor"
        >
          {t('cancel')}
        </Link>
      </div>
    </div>
  );
}

export default NewMeeting;
