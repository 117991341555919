import React from 'react';
import styles from './commissions-breakdown.module.scss';
import { useTranslation } from 'react-i18next';
import AnalyticsPieChartCard from '../common/analytics-pie-chart-card/analytics-pie-chart-card';
import { PieChartData } from '../../definitions/interfaces/analytics';

interface CommissionsBreakdownProps {
  commissionsBreakdownData: PieChartData[];
  commissionsPendingStatusData: PieChartData[];
  totalCommissions: number;
  totalPendingCommissions: number;
  currency: string;
  downloadingReport?: boolean;
}
function CommissionsBreakdown({
  commissionsBreakdownData,
  commissionsPendingStatusData,
  totalCommissions,
  totalPendingCommissions,
  currency,
  downloadingReport,
}: Readonly<CommissionsBreakdownProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      <div>
        <AnalyticsPieChartCard
          commissionsBreakdownChart
          title={t('commissionsBreakdown')}
          data={commissionsBreakdownData}
          totalSales={totalCommissions}
          currency={currency}
          totalSalesTitle={t('Total Commissions')}
          downloadingReport={downloadingReport}
        />
      </div>
      <div>
        <AnalyticsPieChartCard
          commissionsBreakdownChart
          title={t('commissionPendingStatusBreakdown')}
          data={commissionsPendingStatusData}
          totalSales={totalPendingCommissions}
          currency={'Deals'}
          totalSalesTitle={t('Total Pending Commissions')}
          downloadingReport={downloadingReport}
        />
      </div>
    </div>
  );
}

export default CommissionsBreakdown;
