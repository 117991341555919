import { useTranslation } from 'react-i18next';
import { FiltersUnits } from '@orascom/broker-sales-man-common-components';
import { Unit as UnitUtils } from '../../utils/unit';
import { ROUTES } from '../../api/routes';
import { Link, useParams } from 'react-router-dom';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import styles from './similar-units-listing.module.scss';
export function SimilarUnitsListing() {
  const { unitId } = useParams();
  const { t } = useTranslation();
  return (
    <div>
      <Link
        to={ROUTES['UnitDetails'].getPath?.(unitId)}
        className={styles['back-anchor']}
      >
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('backToUnitDetails')}</span>
      </Link>
      <FiltersUnits
        portal="broker"
        title={t('allSimilarUnits')}
        onResale={false}
        getUnits={UnitUtils.getUnits}
        comparePropertiesPath={ROUTES['CompareProperties'].path}
        unitDetailsPath={ROUTES['UnitDetails'].getPath}
        addSavedUnit={UnitUtils.addSavedUnit}
        deleteSavedUnit={UnitUtils.deleteSavedUnit}
        similarUnits
      />
    </div>
  );
}

export default SimilarUnitsListing;
