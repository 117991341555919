import { useState, useEffect } from 'react';
import { Updates as UpdatesUtils } from '../utils/updates';
import { PaginationPayload, UpdatesInterface } from '@orascom/api-interfaces';

const useNotifications = (
  currentPage: number,
  perPage: number,
  unSeen?: boolean
) => {
  const [notifications, setNotifications] =
    useState<PaginationPayload<UpdatesInterface[]>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    UpdatesUtils.getUpdates(currentPage, perPage, unSeen)
      .then((response) => {
        setNotifications(response);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [currentPage, perPage]);

  return { notifications, loading };
};

export default useNotifications;
