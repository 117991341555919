import { DestinationProject as DestinationProjectInterface } from '@orascom/api-interfaces';
import {
  CommonEventParameters,
  routeSearchParamsConstructor,
} from '@orascom/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './destination-projects.module.scss';
import { useContext } from 'react';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';

export interface DestinationProjectsProps {
  projects: DestinationProjectInterface[];
  pathname?: string;
}

export function DestinationProjects({
  projects,
  pathname,
}: Readonly<DestinationProjectsProps>) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
  };

  return (
    <div className={styles['wrapper']}>
      {Boolean(projects.length) && (
        <>
          <h3 className={styles['title']}>{t('neighborhoods')}</h3>
          <div className={styles['grid']}>
            {projects.map((p) => (
              <div key={p.id} className={styles['project']}>
                <img
                  src={p.image}
                  alt={p.name}
                  className={styles['image']}
                  loading="lazy"
                />
                <div className={styles['content']}>
                  <h4 className={styles['name']}>{p.name}</h4>
                  <ul className={styles['list']}>
                    {Boolean(p.unit_types.length) && (
                      <li className={styles['item']}>
                        {t('propertyTypes')}:{' '}
                        {p.unit_types.map(
                          (u, i) => `${i !== 0 ? '-' : ''} ${u.name} `
                        )}
                      </li>
                    )}
                  </ul>
                  <div className={styles['buttons']}>
                    <Link
                      to={{
                        pathname: pathname,
                        search: routeSearchParamsConstructor(
                          undefined,
                          undefined,
                          [{ label: p.name, value: p.slug ?? '' }]
                        ).toString(),
                      }}
                      className={`btn btn--navy ${styles['view']}`}
                    >
                      {t('viewAvailableUnits')}
                    </Link>
                    {p.brochure ? (
                      <a
                        href={p.brochure}
                        download
                        target="_blank"
                        rel="noreferrer"
                        className={`anchor ${styles['download']}`}
                      >
                        {t('downloadBrochure')}
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default DestinationProjects;
