/* eslint-disable import/no-extraneous-dependencies */
import {
  DESTINATION_SLUG,
  Destination as DestinationInterface,
} from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import styles from './destination-story.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

export interface DestinationStoryProps {
  destinationInfo: DestinationInterface;
}

export function DestinationStory({
  destinationInfo,
}: Readonly<DestinationStoryProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      <img
        src={destinationInfo.cover_image}
        alt={destinationInfo.name}
        className={styles['image']}
        loading="lazy"
      />
      <div className={styles['grid']}>
        <div className={styles['story']}>
          <h3>{t('destinationStory')}</h3>
          <p>{destinationInfo.about_description}</p>
        </div>
        <div className={styles['details']}>
          <div className={styles['title-wrapper']}>
            <div>
              <h3>{destinationInfo.name}</h3>
              <h3>{t('destinationDetails')}</h3>
              {destinationInfo.units_sold ? (
                <p className={styles['units-sold']}>
                  {destinationInfo.units_sold} Units Sold in the{' '}
                  {destinationInfo.time_range}
                </p>
              ) : null}
            </div>
            <img
              src={destinationInfo.logo}
              alt=""
              role="presentation"
              className={styles['logo']}
              loading="lazy"
            />
          </div>
          <hr />
          <div className={styles['info-wrapper']}>
            {destinationInfo.metadata?.about?.map((d) => (
              <div key={uuidv4()}>
                <p className={styles['label']}>{d.name}</p>
                <h2 className={styles['value']}>
                  {d.value} {d.unit}
                </h2>
              </div>
            ))}
          </div>
          {destinationInfo.slug === DESTINATION_SLUG.LUSTICA_BAY && (
            <div className={styles['map-btn']}>
              <Link
                to="https://lustica-bay.sooada.com/masterplan/1026"
                className="btn btn--navy"
                style={{ width: '100%' }}
              >
                {t('interactiveMap')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DestinationStory;
