/**
 * @author Ahmed Serag
 * @date 2024-02-05
 * @description Implementation of analytics events related utilities.
 * @filename analytics-events.ts
 */

import { CommonEventParameters } from '@orascom/utils';

export function analyticsBrokerDashboardCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'dashboard', params);
  } catch (e) {
    console.error('Error in analyticsBrokerDashboardCustomEvent', e);
  }
}

export function analyticsBrokerDestinationCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `destination_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsBrokerDestinationCustomEvent', e);
  }
}

export function analyticsBrokerDestinationsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'destinations', params);
  } catch (e) {
    console.error('Error in analyticsBrokerDestinationsCustomEvent', e);
  }
}

export function analyticsBrokerSavedUnitsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'saved_units', params);
  } catch (e) {
    console.error('Error in analyticsBrokerSavedUnitsCustomEvent', e);
  }
}

export function analyticsPrimaryUnitsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'primary_units', params);
  } catch (e) {
    console.error('Error in analyticsPrimaryUnitsCustomEvent', e);
  }
}

export function analyticsUnitSpecsCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'view_all_specs', params);
  } catch (e) {
    console.error('Error in analyticsUnitSpecsCustomEvent', e);
  }
}

export function analyticsUnitBrochureCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `broker_download_brochure_${params.unit}`, params);
  } catch (e) {
    console.error('Error in analyticsUnitBrochureCustomEvent', e);
  }
}

export function analyticsDownloadDestinationBrochureCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag(
      'event',
      `broker_download_brochure_destination_${params.destination}`,
      params
    );
  } catch (e) {
    console.error(
      'Error in analyticsDownloadDestinationBrochureCustomEvent',
      e
    );
  }
}

export function analyticsDownloadProjectBrochureCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag(
      'event',
      `broker_download_brochure_project_${params.neighborhood}`,
      params
    );
  } catch (e) {
    console.error('Error in analyticsDownloadProjectBrochureCustomEvent', e);
  }
}

export function analyticsUnitSubmitInterestCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'submit_interest', params);
  } catch (e) {
    console.error('Error in analyticsUnitSubmitInterestCustomEvent', e);
  }
}

export function analyticsUnitSalesOfferDownloadCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'download_salesoffer', params);
  } catch (e) {
    console.error('Error in analyticsBrokerDestinationsCustomEvent', e);
  }
}

export function analyticsBackButtonCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'back', params);
  } catch (e) {
    console.error('Error in analyticsBackButtonCustomEvent', e);
  }
}

export function analyticsCreateDealCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'createdeal', params);
  } catch (e) {
    console.error('Error in analyticsCreateDealCustomEvent', e);
  }
}

export function analyticsActiveDealsCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'active_deals', params);
  } catch (e) {
    console.error('Error in analyticsActiveDealsCustomEvent', e);
  }
}

export function analyticsPastDealsCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'past_deals', params);
  } catch (e) {
    console.error('Error in analyticsPastDealsCustomEvent', e);
  }
}

export function analyticsNewtDealCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'new_deal', params);
  } catch (e) {
    console.error('Error in analyticsNewtDealCustomEvent', e);
  }
}

export function analyticsSavedUnitDestinationFilterCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `savedunits_(${params.name})_filter`, params);
  } catch (e) {
    console.error('Error in analyticsSavedUnitDestinationFilterCustomEven', e);
  }
}
