import styles from '../../pages/deal-details/deal-details.module.scss';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import greenBulletIcon from '../../assets/icons/green-bullet.svg';
import orangeBulletIcon from '../../assets/icons/orange-bullet.svg';
import {
  SalesInvoiceInterface,
  SalesInvoiceStatusEnum,
} from '../../definitions/interfaces/deals.interface';
import { Loader } from '@orascom/common-components';
import Numeral from 'numeral';
import { v4 as uuidv4 } from 'uuid';

export interface DealDetailsInvoiceProps {
  salesInvoices?: SalesInvoiceInterface[];
  loading: boolean;
}
function DealDetailsInvoice({
  salesInvoices,
  loading,
}: Readonly<DealDetailsInvoiceProps>) {
  const { t } = useTranslation();

  if (loading) {
    return <Loader />;
  }

  const getSalesInvoiceItemStatus = (salesInvoice: SalesInvoiceInterface) => {
    if (salesInvoice.commission_payment_status) {
      return (
        <>
          <img src={greenBulletIcon} alt="" role="presentation" /> {t('cashed')}
        </>
      );
    }

    switch (salesInvoice?.status) {
      case SalesInvoiceStatusEnum.PENDING:
        return (
          <>
            <img src={orangeBulletIcon} alt="" role="presentation" />{' '}
            {t('pending')}
          </>
        );
      case SalesInvoiceStatusEnum.RECEIVED:
        return (
          <>
            <img src={greenBulletIcon} alt="" role="presentation" />{' '}
            {t('received')}
          </>
        );
      case SalesInvoiceStatusEnum.REJECTED:
        return (
          <>
            <img src={orangeBulletIcon} alt="" role="presentation" />{' '}
            {t('rejected')}
          </>
        );
      case SalesInvoiceStatusEnum.APPROVED:
        return (
          <>
            <img src={greenBulletIcon} alt="" role="presentation" />{' '}
            {t('approved')}
          </>
        );
      default:
        return '-';
    }
  };

  return (
    <>
      <h3 className={styles['sub-title']}>{t('salesInvoiceStatus')}</h3>
      <div className={styles['invoices-container']}>
        {salesInvoices?.map((salesInvoice) => (
          <div key={uuidv4()} className={styles['details']}>
            <div className={styles['detail']}>
              <p>{t('submissionDate')}</p>
              <h3>
                {salesInvoice?.submission_date
                  ? format(
                      new Date(salesInvoice.submission_date * 1000),
                      'dd MMMM yyyy'
                    )
                  : '-'}
              </h3>
            </div>

            <div className={styles['detail']}>
              <p>{t('expectedPayoutDate')}</p>
              <h3>
                {salesInvoice?.expected_payout_date
                  ? format(
                      new Date(salesInvoice.expected_payout_date * 1000),
                      'dd MMMM yyyy'
                    )
                  : '-'}
              </h3>
            </div>

            {salesInvoice.status === SalesInvoiceStatusEnum.APPROVED && (
              <div className={styles['detail']}>
                <p>{t('salesInvoiceAmount')}</p>
                <h3>
                  {salesInvoice?.amount
                    ? Numeral(salesInvoice.amount).format('0,0') +
                      ' ' +
                      salesInvoice.currency
                    : '-'}
                </h3>
              </div>
            )}
            <div className={styles['detail']}>
              <p>{t('salesInvoiceStatus')}</p>
              {getSalesInvoiceItemStatus(salesInvoice)}
            </div>
            {salesInvoice.status === SalesInvoiceStatusEnum.REJECTED && (
              <div className={styles['detail']}>
                <p>{t('rejectionReason')}</p>
                <h3>{salesInvoice.rejection_reason}</h3>
              </div>
            )}
          </div>
        ))}
      </div>

      <hr />
    </>
  );
}

export default DealDetailsInvoice;
