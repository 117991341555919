import { Network } from './network';
import {
  Payload,
  SetPasswordInput,
  UserLoginResponse,
} from '@orascom/api-interfaces';
import { removeTokenDetails, storeTokenDetails } from '../utils/authentication';
import { ENDPOINTS } from './endpoints';

export class Authentication {
  public static login(userData: {
    email: string;
    password: string;
  }): Promise<Payload<UserLoginResponse>> {
    const url = `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.login.url}`;
    const loginResponsePromise = Network.fetch(url, {
      method: ENDPOINTS.login.method,
      body: JSON.stringify(userData),
    });
    return loginResponsePromise.then((payload: Payload<UserLoginResponse>) => {
      storeTokenDetails(payload);
      return payload;
    });
  }

  public static setPassword(
    userData: SetPasswordInput
  ): Promise<Payload<UserLoginResponse>> {
    const url = `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.setPassword.url}`;
    const setPasswordResponsePromise = Network.fetch(url, {
      method: ENDPOINTS.setPassword.method,
      body: JSON.stringify(userData),
    });
    return setPasswordResponsePromise.then(
      (payload: Payload<UserLoginResponse>) => {
        storeTokenDetails(payload);
        return payload;
      }
    );
  }

  public static forgetPassword(userData: {
    email: string;
  }): Promise<Payload<void>> {
    const url = `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.forgetPassword.url}`;
    return Network.fetch(url, {
      method: ENDPOINTS.forgetPassword.method,
      body: JSON.stringify(userData),
    });
  }

  public static logoutUser(): Promise<Payload<void>> {
    const promise = Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.logout.url}`,
      {
        method: ENDPOINTS.logout.method,
      }
    );
    return promise.then((result) => {
      removeTokenDetails();
      return Promise.resolve(result);
    });
  }

  public static logoutSalesManager(): Promise<Payload<void>> {
    const promise = Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.logoutSalesManager.url}`,
      {
        method: ENDPOINTS.logoutSalesManager.method,
      }
    );
    return promise.then((result) => {
      removeTokenDetails();
      return Promise.resolve(result);
    });
  }
}
