import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styles from './broker-rankings.module.scss';
import stockIcon from '../../assets/icons/stock.svg';
import { AnalyticsCommissionsRanking } from '../../definitions/interfaces/analytics';

interface BrokerRankingsProps {
  comissionsRanking?: AnalyticsCommissionsRanking[];
  currency?: string;
}
export function BrokerRankings(props: Readonly<BrokerRankingsProps>) {
  const { t } = useTranslation();

  const brokerRankingMarkup = props.comissionsRanking?.length ? (
    props.comissionsRanking?.map((ranking) => (
      <div key={uuidv4()}>
        <h4 className={styles['title']}>{ranking.destination_name}</h4>

        <div
          className={`${styles['broker-rankings-wrapper']} broker-cards-grid`}
        >
          <div className="overview-card">
            <div className="icon icon--gold">
              <p>#{ranking.broker_company_rank}</p>
            </div>
            <div className="content--rank">
              <h2>
                {t('outOf')} <span>{ranking.companies_count}</span>{' '}
                {t('brokerages')}
              </h2>
            </div>
          </div>
          {ranking.higher_rank > 1 && ranking.broker_company_rank > 1 ? (
            <div className="overview-card">
              <div className="icon">
                <img src={stockIcon} alt="" role="presentation" />
              </div>
              <div className="content">
                <h2>
                  {t('earn')} {ranking.diff_higher_rank} {props.currency}
                </h2>
                <p>
                  {t('toReachThe')} <span>{ranking.higher_rank}</span>
                </p>
              </div>
            </div>
          ) : null}
          {ranking.broker_company_rank > 1 ? (
            <div className="overview-card">
              <div className="icon">
                <img src={stockIcon} alt="" role="presentation" />
              </div>
              <div className="content">
                <h2>
                  {t('earn')} {ranking.diff_first_rank} {props.currency}
                </h2>
                <p>
                  {t('toReachThe')} <span>1st Place</span>
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ))
  ) : (
    <div className="overview-card">no rankings found</div>
  );
  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      <div>
        <h3 className="title">{t('rankings')}</h3>
        <div className={`card ${styles['broker-rankings']}`}>
          {brokerRankingMarkup}
        </div>
      </div>
    </div>
  );
}

export default BrokerRankings;
