/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import styles from './image-gallery.module.scss';
import { v4 as uuidv4 } from 'uuid';
import RightArrow from '../../assets/icons/right-arrow.svg';

export interface ImageGalleryProps {
  images: string[];
}

export function ImageGallery(props: Readonly<ImageGalleryProps>) {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    setImages(props.images);
  }, [props.images]);

  /**
   * Moves the selected image to the first position to "enlarge" it via CSS.
   *
   * @param {number} index
   */
  const enlargeImgHandler = (index: number) => {
    if (index === 0) return;
    const reorderedImages = [...images];
    const [selectedImage] = reorderedImages.splice(index, 1); // Remove the clicked image
    reorderedImages.unshift(selectedImage); // Add it to the start
    setImages(reorderedImages);
  };

  /**
   * Navigates to the next image by reordering images array.
   */
  const handleNext = () => {
    const reorderedImages = [...images];
    reorderedImages.push(reorderedImages.shift()!); // Move the first image to the end
    setImages(reorderedImages);
  };

  /**
   * Navigates to the previous image by reordering images array.
   */
  const handlePrev = () => {
    const reorderedImages = [...images];
    reorderedImages.unshift(reorderedImages.pop()!); // Move the last image to the start
    setImages(reorderedImages);
  };

  const ImagesComponent = () => {
    if (images.length > 1) {
      const multiImages = images.slice(1);

      return (
        <div className={styles['images-container-first']}>
          <div className={styles['first-image']}>
            <button
              onClick={handlePrev}
              className={`${styles['first-image--left']} ${styles['btn-wrapper']}`}
            >
              <img src={RightArrow} alt="Previous" />
            </button>

            <img
              key={uuidv4()}
              src={images[0]}
              alt={`Image 1`}
              className={styles['hero']}
              onClick={() => enlargeImgHandler(1)}
              onKeyDown={() => enlargeImgHandler(1)}
              loading="lazy"
            />

            <button
              onClick={handleNext}
              className={`${styles['first-image--right']} ${styles['btn-wrapper']}`}
            >
              <img src={RightArrow} alt="Next" />
            </button>
          </div>

          {multiImages.map((img, index) => (
            <img
              key={uuidv4()}
              src={img}
              alt={`Image ${index + 1}`}
              className={styles['image']}
              onClick={() => enlargeImgHandler(index + 1)}
              onKeyDown={() => enlargeImgHandler(index + 1)}
              loading="lazy"
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className={styles['images-container']}>
          {images.map((img, index) => (
            <img
              key={uuidv4()}
              src={img}
              alt={`Image ${index}`}
              className={styles['image']}
              onClick={() => enlargeImgHandler(index)}
              onKeyDown={() => enlargeImgHandler(index)}
              loading="lazy"
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div className={styles['wrapper']}>
      <ImagesComponent />
    </div>
  );
}

export default ImageGallery;
