import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import {
  Payload,
  FilterValue,
  PaginationPayload,
  FilterParams,
} from '@orascom/api-interfaces';
import {
  AnalyticsBrokerRankings,
  AnalyticsCommissions,
  AnalyticsCommissionsRankingResponse,
  AnalyticsCompanyRanking,
  AnalyticsLeadsCount,
  AnalyticsRevenueInsights,
  SalesCountPerDestination,
} from '../definitions/interfaces/analytics';
import { constructURL } from '@orascom/utils';
import { LineGraphData } from '@orascom/broker-sales-man-common-components';
/**
 * implement Analytics related calls.
 */

export class Analytics {
  /**
   * get broker analytics lead count
   *
   * @static
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [destinationId]
   * @returns {Promise<Payload<AnalyticsLeadsCount>>}
   *
   * @memberOf Analytics
   */
  public static getAnalyticsLeadCount(
    params: FilterParams
  ): Promise<Payload<AnalyticsLeadsCount>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsLeadCount.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsLeadCount.method,
    });
  }

  public static getAnalyticsCommissionsRanking(
    params: FilterParams
  ): Promise<Payload<AnalyticsCommissionsRankingResponse>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsCommissionsRanking.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsCommissionsRanking.method,
    });
  }

  public static getAnalyticsCompanyRanking(
    params: FilterParams
  ): Promise<Payload<AnalyticsCompanyRanking>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsCompanyRanking.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsCompanyRanking.method,
    });
  }

  public static getAnalyticsFilterAssignedBrokers(): Promise<
    Payload<FilterValue[]>
  > {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterAssignedBrokers.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterAssignedBrokers.method,
      }
    );
  }

  public static getAnalyticsFilterDestinations(): Promise<
    Payload<FilterValue[]>
  > {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterDestinations.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterDestinations.method,
      }
    );
  }

  public static getAnalyticsFilterUnitTypes(): Promise<Payload<FilterValue[]>> {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterUnitTypes.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterUnitTypes.method,
      }
    );
  }

  public static getAnalyticsSalesCountPerDestination(
    params: FilterParams
  ): Promise<Payload<SalesCountPerDestination[]>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsSalesCountPerDestination.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsSalesCountPerDestination.method,
    });
  }

  public static getAnalyticsSalesCountPerUnitType(
    params: FilterParams
  ): Promise<Payload<LineGraphData[]>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsSalesCountPerUnitType.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsSalesCountPerUnitType.method,
    });
  }

  public static getAnalyticsCommissions(
    params: FilterParams
  ): Promise<Payload<AnalyticsCommissions>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsCommissions.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsCommissions.method,
    });
  }

  public static getAnalyticsRevenueInsights(
    params: FilterParams
  ): Promise<Payload<AnalyticsRevenueInsights>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsRevenueInsights.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsRevenueInsights.method,
    });
  }

  /**
   * get broker analytics agents ranking
   *
   * @static
   * @param {string} [from]
   * @param {string} [to]
   * @param {string[]} [destinationIds]
   * @param {string[]} [unitTypeIds]
   * @returns {Promise<PaginationPayload<AnalyticsBrokerRankings>>}
   *
   * @memberOf Analytics
   */
  public static getAnalyticsAgentsRanking(
    params: FilterParams
  ): Promise<Payload<PaginationPayload<AnalyticsBrokerRankings[]>>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsAgentsRanking.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsAgentsRanking.method,
    });
  }
}
