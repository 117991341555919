import { useContext, useState } from 'react';
import { Authentication as AuthenticationUtils } from '../../utils/authentication';
import { toast } from 'react-toastify';
import { ROUTES } from '../../api/routes';
import { useNavigate } from 'react-router-dom';
import { errorMessagesHandler } from '@orascom/utils';
import { UserRole } from '@orascom/api-interfaces';
import {
  LoginComponent,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';

export function Login() {
  const navigate = useNavigate();
  const userContext = useContext(USER_CONTEXT);
  const [invalidCredentials, setInvalidCredentials] = useState('');

  const submitHandler = (
    values: { email: string; password: string },
    setSubmitting: (arg: boolean) => void
  ) => {
    AuthenticationUtils.login(values)
      .then((res) => {
        toast.success(res.message);
        userContext.setUser?.(res.data.user);
        if (res.data.user.role === UserRole.BROKER) {
          navigate(ROUTES['Dashboard'].path);
        } else {
          navigate(ROUTES['SalesManagerCommissionsAnalytics'].path);
        }
      })
      .catch((err) => {
        errorMessagesHandler(err);
        setInvalidCredentials(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <LoginComponent
      invalidCredentials={invalidCredentials}
      submitHandler={submitHandler}
      forgetPasswordRoute={ROUTES['ForgetPassword'].path}
      dashboardRoute={ROUTES['Dashboard'].path}
    />
  );
}

export default Login;
