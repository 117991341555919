import { useEffect, useState } from 'react';
import { Unit as UnitUtils } from '../../utils/unit';
import { Deal as DealUtils } from '../../utils/deal';
import { Destination as DestinationUtils } from '../../utils/destination.utils';
import RecentUpdates from '../../components/recent-updates/recent-updates';
import { useTranslation } from 'react-i18next';
import { Destination, UnitInterface } from '@orascom/api-interfaces';
import DashboardUnits from '../../components/dashboard-units/dashboard-units';
import { ROUTES } from '../../api/routes';
import NewMeetings from '../../components/new-meetings/new-meetings';
import styles from './dashboard.module.scss';
import { Loader } from '@orascom/common-components';
import { MeetingInterface } from '../../definitions/interfaces/inputs/new-deal';
import DestinationsListing from '../destinations-listing/destinations-listing';

export function Dashboard() {
  const [savedUnits, setSavedUnits] = useState<UnitInterface[]>([]);
  const [newUnits, setNewUnits] = useState<UnitInterface[]>([]);
  const [brokerMeetings, setBrokerMeetings] = useState<MeetingInterface[]>([]);
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      UnitUtils.getSavedUnits(1, 3),
      UnitUtils.getUnits(1, 3),
      DealUtils.getMeetings(),
      DestinationUtils.listDestinations(),
    ])
      .then((res) => {
        setSavedUnits(res[0].data);
        setNewUnits(res[1].data);
        setBrokerMeetings(res[2].data);
        setDestinations(res[3]);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('overview')}</h1>
      <div className={styles['updates-grid']}>
        <RecentUpdates />
        <NewMeetings brokerMeetings={brokerMeetings} />
      </div>
      {Boolean(savedUnits.length) && (
        <DashboardUnits
          title={t('savedUnits')}
          anchorTitle={t('viewAllSavedUnits')}
          anchorLink={ROUTES['SavedUnits'].path}
          units={savedUnits}
        />
      )}
      {Boolean(newUnits?.length) && (
        <DashboardUnits
          title={t('newProperties')}
          anchorTitle={t('viewAllNewProperties')}
          anchorLink={ROUTES['PrimaryUnits'].path}
          units={newUnits}
        />
      )}
      {/* <AnalyticsOverview /> */}
      <DestinationsListing destinations={destinations} />
    </div>
  );
}

export default Dashboard;
