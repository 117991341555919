import { FilterValue, PaginationPayload } from '@orascom/api-interfaces';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Loader } from '@orascom/common-components';
import { AnalyticsSalesManager as AnalyticsSalesManagerUtils } from '../../utils/analytics-sales-manager';
import { AnalyticsSalesManagerRanking } from '../../definitions/interfaces/analytics';
import { v4 as uuidv4 } from 'uuid';
import Numeral from 'numeral';
import {
  AnalyticsFilter,
  AnalyticsFilterSalesManagers,
  PaginationStyled,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';

interface SalesManagerRankingProps {
  filterDestinations: FilterValue[];
  filterUnitTypes: FilterValue[];
  filterBrokerages: FilterValue[];
  filterSalesManagers: AnalyticsFilterSalesManagers[];
}
function SalesManagerRankingTable(props: Readonly<SalesManagerRankingProps>) {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [loading, setLoading] = useState(true);
  const filtersParams = queryString.parse(window.location.search);

  const [salesManagerRanking, setSalesManagerRanking] =
    useState<PaginationPayload<AnalyticsSalesManagerRanking[]>>();
  const {
    selectedDestinations,
    selectedDestinationsIds,
    handleSelectedDestinations,
    selectedUnitTypes,
    selectedUnitTypesIds,
    handleSelectedUnitTypes,
    selectedBrokerage,
    selectedBrokeragesIds,
    handleSelectedBrokerage,
    selectedSalesManager,
    selectedSalesManagersIds,
    handleSelectedSalesManagers,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    setCurrentPage(1);
  }, [
    selectedBrokerage,
    selectedDestinations,
    selectedUnitTypes,
    selectedSalesManager,
  ]);

  useEffect(() => {
    AnalyticsSalesManagerUtils.getAnalyticsSalesManagerRanking({
      page: currentPage.toString(),
      per_page: perPage.toString(),
      from: startDateArg,
      to: endDateArg,
      'brokerage_company_ids[]': selectedBrokeragesIds,
      'destination_ids[]': selectedDestinationsIds,
      'unit_type_ids[]': selectedUnitTypesIds,
      'sales_managers_titles[]': selectedSalesManagersIds,
    })
      .then((res) => {
        setSalesManagerRanking(res);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedBrokerage,
    selectedDestinations,
    selectedUnitTypes,
    selectedSalesManager,
    currentPage,
  ]);

  if (loading) {
    return <Loader />;
  }

  if (!salesManagerRanking?.data.length) {
    return <h1>no sales managers found</h1>;
  }

  return (
    <div className="broker-analytic-wrapper">
      <h3 className="title">ODH Sales Manager Ranking</h3>
      <div className={`card ranking-wrapper`}>
        <AnalyticsFilter
          unitSizeFilter
          filterBrokerages={props.filterBrokerages}
          filterDestinations={props.filterDestinations}
          filterUnitTypes={props.filterUnitTypes}
          filterSalesManagers={props.filterSalesManagers}
          handleSelectBrokerages={handleSelectedBrokerage}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          handleSelectedSalesManagers={handleSelectedSalesManagers}
        />

        <div className="analytics-table">
          <table>
            <thead>
              <tr className="manager-tr">
                <th className="manager-th">Rank</th>
                <th className="manager-th">ODH Sales Manager</th>
                <th className="manager-th">No. of deals</th>
                <th className="manager-th">Sales</th>
              </tr>
            </thead>
            <tbody>
              {salesManagerRanking.data?.map((manager, index) => {
                return (
                  <tr className="manager-tr" key={uuidv4()}>
                    <td>
                      <td className="manager-td--details">
                        <p>#{perPage * (currentPage - 1) + (index + 1)}</p>
                      </td>
                    </td>
                    <td className="manager-td--name">
                      <p>{manager.odh_sales_manager_name}</p>
                    </td>
                    <td className="manager-td--details">
                      <p>{manager.deals_count}</p>
                    </td>
                    <td className="manager-td--name">
                      <p>
                        {' '}
                        {Numeral(manager.total_selling_price).format(
                          '0,0'
                        )}{' '}
                        {manager.currency}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationStyled
            currentPage={currentPage}
            totalCount={salesManagerRanking.meta.total}
            pageSize={salesManagerRanking.meta.per_page}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default SalesManagerRankingTable;
