export const MONTENEGRO_UNITS_BATHROOMS: Record<string, string> = {
  '1': '1',
  '2': '1.5',
  '3': '2',
  '4': '2.5',
  '5': '3',
  '753240000': '3.5',
  '6': '4',
  '753240001': '4.5',
  '7': '5',
  '8': '6',
};

export const MONTENEGRO_UNITS_FLOOR_NUMBER: Record<string, string> = {
  '753240000': 'Basement',
  '0': 'Ground',
  '1': 'First',
  '2': 'Second',
  '3': 'Third',
  '4': 'Fourth',
};
