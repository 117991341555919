import { handleError } from '@orascom/utils';
import { Updates as UpdatesAPI } from '../api/updates';
import { PaginationPayload, UpdatesInterface } from '@orascom/api-interfaces';

export class Updates {
  public static getUpdates(
    page: number,
    perPage: number,
    unSeen?: boolean
  ): Promise<PaginationPayload<UpdatesInterface[]>> {
    return UpdatesAPI.getUpdates(page, perPage, unSeen)
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static readNotifications(
    notificationsIds: number[],
    isRead?: 0 | 1
  ): Promise<string> {
    return UpdatesAPI.readNotifications(notificationsIds, isRead)
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
