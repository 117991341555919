import { useTranslation } from 'react-i18next';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styles from './units-sold-per-unit-type.module.scss';
import { FilterValue, PeriodType } from '@orascom/api-interfaces';
import { useEffect, useState } from 'react';
import { Analytics as AnalyticsUtils, lineColors } from '../../utils/analytics';
import { Loader } from '@orascom/common-components';
import queryString from 'query-string';
import {
  AnalyticsFilter,
  getChartData,
  LineGraphData,
  MonthsNames,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';

interface UnitsSoldPerUnitTypeProps {
  filterDestinations: FilterValue[];
  filterBrokerAgents: FilterValue[];
  selectedDuration: PeriodType;
}
// Register the plugins to the chart
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function UnitsSoldPerUnitType(
  props: Readonly<UnitsSoldPerUnitTypeProps>
) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [salesCountPerUnitType, setSalesCountPerUnitType] =
    useState<LineGraphData[]>();
  const filtersParams = queryString.parse(window.location.search);

  const [axis, setAxis] = useState<
    {
      xAxisLabels: string[];
      yAxisValues: number[];
      label: string;
    }[]
  >();

  const {
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedBrokerAgents,
    handleSelectedBrokerAgents,
    selectedBrokerAgentsIds,
    startDateArg,
    endDateArg,
  } = useAnalyticsFilters();

  useEffect(() => {
    AnalyticsUtils.getAnalyticsSalesCountPerUnitType({
      from: startDateArg,
      to: endDateArg,
      'destination_ids[]': selectedDestinationsIds,
      'broker_ids[]': selectedBrokerAgentsIds,
    })
      .then((res) => {
        setSalesCountPerUnitType(res);

        const allAxis = res.map((dataByType) =>
          getChartData(props.selectedDuration, dataByType)
        );

        setAxis(allAxis);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [
    filtersParams['start'],
    filtersParams['end'],
    selectedDestinations,
    props.selectedDuration,
    selectedBrokerAgents,
  ]);

  if (loading) {
    return <Loader />;
  }
  if (!salesCountPerUnitType) {
    return <h1>No Sales Per Unit Type Found</h1>;
  }
  const dataSets = axis
    ? axis.map((axisByType, index) => {
        return {
          data: axisByType.yAxisValues,
          borderColor: lineColors[index],
          backgroundColor: lineColors[index],
          borderWidth: 1.5,
          pointBackgroundColor: lineColors[index],
          label: `${axisByType.label}`,
          parsing: {
            xAxisKey: 'period',
            yAxisKey: `${axisByType.label} sold`,
          },
          tension: 0.3,
        };
      })
    : [];

  const data: ChartData<'line', number[], string> = {
    labels: axis?.length ? axis[0].xAxisLabels : MonthsNames,
    datasets: dataSets,
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: { grid: { display: false }, offset: true },
      y: {
        title: { text: `sales in ${props.selectedDuration}`, display: true },
        grid: { display: true },
        ticks: {
          stepSize: 1,
        },
      },
    },

    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'start' as const,

        labels: {
          boxWidth: 18,
          boxHeight: 18,
          borderRadius: 9,
          useBorderRadius: true,
          padding: 30,
        },
      },
    },
  };
  return (
    <div className={`${styles['wrapper']} broker-analytic-wrapper`}>
      <h3 className="title">{t('numberOfunitsSoldPerUnitType')} </h3>
      <div className={`card ${styles['chart-wrapper']}`}>
        <AnalyticsFilter
          filterByLabel
          countriesFilter
          filterDestinations={props.filterDestinations}
          handleSelectedDestinations={handleSelectedDestinations}
          filterBrokerAgents={props.filterBrokerAgents}
          handleSelectBrokerAgents={handleSelectedBrokerAgents}
        />
        <div className={styles['chart']}>
          <Line height={400} options={options} data={data} />
        </div>
      </div>
    </div>
  );
}

export default UnitsSoldPerUnitType;
