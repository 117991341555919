import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import parse from 'html-react-parser';
import { News as NewsUtils } from '../../utils/news-events';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import styles from './news-details.module.scss';
import { NewsDetailsInterface } from '../../definitions/interfaces/news-events';
import { Loader } from '@orascom/common-components';

export function NewsDetails() {
  const [news, setNews] = useState<NewsDetailsInterface>();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const { newsSlug } = useParams();

  useEffect(() => {
    if (!newsSlug) {
      return;
    }

    NewsUtils.getNewsDetails(newsSlug)
      .then((res) => setNews(res))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['NewsEvents'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('backToAllNewsEvents')}</span>
      </Link>
      <img
        src={news?.image}
        alt={news?.title}
        className={styles['image']}
        loading="lazy"
      />
      <div className={`card ${styles['content']}`}>
        <h1 className={styles['title']}>{news?.title}</h1>
        <div className={styles['body']}>{parse(news?.content as string)}</div>
      </div>
    </div>
  );
}

export default NewsDetails;
