import { PaginationPayload } from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';
import { News as NewsAPI } from '../api/news-events';
import {
  NewsDetailsInterface,
  NewsInterface,
} from '../definitions/interfaces/news-events';

export class News {
  /**
   * fetch all news and events
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @param {('true' | 'false')} isEvent
   * @returns {Promise<PaginationPayload<NewsInterface[]>>}
   *
   * @memberOf News
   */
  public static getNewsEvents(
    page: number,
    perPage: number,
    isEvent: '1' | '0'
  ): Promise<PaginationPayload<NewsInterface[]>> {
    return NewsAPI.getNewsEvents(page, perPage, isEvent)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get news details by news slug
   *
   * @static
   * @param {string} newsSlug
   * @returns {Promise<NewsDetailsInterface>}
   *
   * @memberOf News
   */
  public static getNewsDetails(
    newsSlug: string
  ): Promise<NewsDetailsInterface> {
    return NewsAPI.getNewsDetails(newsSlug)
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}
