import { useTranslation } from 'react-i18next';
import styles from './destination-masterplan.module.scss';

export interface DestinationMasterplanProps {
  image: string;
  alt: string;
}

export function DestinationMasterplan({
  image,
  alt,
}: Readonly<DestinationMasterplanProps>) {
  const { t } = useTranslation();
  return (
    <div className={`card ${styles['wrapper']}`}>
      <h3 className={styles['title']}>{t('masterPlan')}</h3>
      <img
        src={image}
        alt={alt}
        className={styles['masterplan']}
        loading="lazy"
      />
    </div>
  );
}

export default DestinationMasterplan;
