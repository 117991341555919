import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import {
  Payload,
  FilterValue,
  PaginationPayload,
  FilterParams,
} from '@orascom/api-interfaces';
import {
  AnalyticsBrokerRankingsSalesManager,
  AnalyticsBrokerageRankingsSalesManager,
  AnalyticsCommissions,
  AnalyticsCommissionsRankingSalesManager,
  AnalyticsInternationalSales,
  AnalyticsLeadsCount,
  AnalyticsRevenueInsightsSalesManager,
  AnalyticsSalesManagerRanking,
  SalesCountPerDestination,
} from '../definitions/interfaces/analytics';
import { constructURL } from '@orascom/utils';
import { AnalyticsFilterSalesManagers } from '@orascom/broker-sales-man-common-components';

export class AnalyticsSalesManager {
  public static getAnalyticsCommissionsSalesManager(
    params: FilterParams
  ): Promise<
    Payload<PaginationPayload<AnalyticsCommissionsRankingSalesManager[]>>
  > {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsCommissionsSalesManager.url,
      params
    );

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsCommissionsSalesManager.method,
    });
  }

  public static getAnalyticsCommissionsDetailsSalesManager(
    params: FilterParams
  ): Promise<Payload<AnalyticsCommissions>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsCommissionsDetailsSalesManager.url,
      params
    );

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsCommissionsDetailsSalesManager.method,
    });
  }

  public static getAnalyticsLeadCountSalesManager(
    params: FilterParams
  ): Promise<Payload<AnalyticsLeadsCount>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsLeadCountSalesManager.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsLeadCountSalesManager.method,
    });
  }

  public static getAnalyticsSalesCountPerDestinationSalesManager(
    params: FilterParams
  ): Promise<Payload<SalesCountPerDestination[]>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsSalesCountPerDestinationSalesManager.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsSalesCountPerDestinationSalesManager.method,
    });
  }

  public static getAnalyticsRevenueInsightsSalesManager(
    params: FilterParams
  ): Promise<Payload<AnalyticsRevenueInsightsSalesManager>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsRevenueInsightsSalesManager.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsRevenueInsightsSalesManager.method,
    });
  }

  public static getAnalyticsFilterDestinationsSalesManager(): Promise<
    Payload<FilterValue[]>
  > {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterDestinationsSalesManager.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterDestinationsSalesManager.method,
      }
    );
  }

  public static getAnalyticsFilterUnitTypeSalesManager(): Promise<
    Payload<FilterValue[]>
  > {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterUnitTypeSalesManager.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterUnitTypeSalesManager.method,
      }
    );
  }

  public static getAnalyticsFilterBrokerageSalesManager(): Promise<
    Payload<FilterValue[]>
  > {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterBrokerageSalesManager.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterBrokerageSalesManager.method,
      }
    );
  }

  public static getAnalyticsBrokerRankingsSalesManager(
    params: FilterParams
  ): Promise<
    Payload<PaginationPayload<AnalyticsBrokerRankingsSalesManager[]>>
  > {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsBrokerRankingsSalesManager.url,
      params
    );

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsBrokerRankingsSalesManager.method,
    });
  }

  public static getAnalyticsSalesManagerRanking(
    params: FilterParams
  ): Promise<Payload<PaginationPayload<AnalyticsSalesManagerRanking[]>>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsSalesManagerRanking.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsSalesManagerRanking.method,
    });
  }

  public static getAnalyticsBrokeragesRankingSalesManager(
    params: FilterParams
  ): Promise<
    Payload<PaginationPayload<AnalyticsBrokerageRankingsSalesManager[]>>
  > {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsBrokeragesRankingSalesManager.url,
      params
    );

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsBrokeragesRankingSalesManager.method,
    });
  }

  public static getAnalyticsInternationalSalesManager(
    params: FilterParams
  ): Promise<Payload<AnalyticsInternationalSales>> {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsInternationalSalesManager.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsInternationalSalesManager.method,
    });
  }

  public static getAnalyticsFilterSalesManagers(): Promise<
    Payload<AnalyticsFilterSalesManagers[]>
  > {
    return Network.fetch(
      `${process.env['NX__BROKER_API_ENDPOINT']}${ENDPOINTS.getAnalyticsFilterSalesManagers.url} `,
      {
        method: ENDPOINTS.getAnalyticsFilterSalesManagers.method,
      }
    );
  }

  public static getAnalyticsBrokerCommissions(params: FilterParams) {
    const fetchURL = constructURL(
      process.env['NX__BROKER_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsBrokerCommissions.url,
      params
    );
    return fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsBrokerCommissions.method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          process.env['NX_BROKER_ACCESS_TOKEN_KEY'] as string
        )}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }
}
