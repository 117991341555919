import { User as UserApi } from '../api/user';
import {
  PaginationPayload,
  Payload,
  UserLoginResponse,
} from '@orascom/api-interfaces';
import { handleError } from '@orascom/utils';
import { DocumentsInterface } from '../definitions/interfaces/documents';

export class User {
  public static getUserData(): Promise<Payload<UserLoginResponse>> {
    return UserApi.getUserData()
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getUserDocuments(): Promise<
    PaginationPayload<DocumentsInterface[]>
  > {
    return UserApi.getUserDocuments()
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
