import { OptionValue } from '@orascom/api-interfaces';

export interface NewDealInputInterface {
  firstName: string;
  lastName: string;
  email: string;
  unitName: string;
  unitType?: string;
  contactType?: OptionValue | null;
  clientBudget?: string;
  contactTime?: OptionValue | null;
  additionalInfo?: string;
  destination: OptionValue | null;
  phoneNumbers: { countryCode: string; phone: string }[];
}

export interface NewDealFormInterface {
  first_name: string;
  last_name: string;
  customer_inquiry: string;
  email: string;
  phone: string;
  mobile_country_code: string;
  interested_in_unit_id?: string;
  portal_comments?: string;
  portal_page: PortalPageEnum;
  preferred_communication_method: string;
  preferred_communication_time: string;
  destination_slug: string;
  budget?: number;
  phone_2?: string;
  phone_3?: string;
}

export enum CustomerInquiryEnum {
  INVESTMENT_INQUIRY = '1',
  UNIT_PURCHASING_INQUIRY = '2',
  GENETRAKL_INQUIRY = '3',
}

export enum PortalPageEnum {
  UNIT_PAGE = '1',
  DESTINATION_PAGE = '2',
  COMPARE_FILLED_PAGE = '3',
  TALK_TO_SALES = '4',
}

export interface LeadInterface {
  source_id: string;
  name: string;
  phone: string;
}
export interface NewMeetingInterface {
  meeting_date_timestamp: number;
  meeting_location: string;
}

export interface ShareReservationLinlInterface {
  lead_email: string;
  lead_name: string;
}

export enum MeetingStatusEnum {
  APPROVED = '1',
  REJECTED = '2',
}

export interface MeetingInterface {
  meeting_date: string;
  location: string;
  name: string;
  status_code: MeetingStatusEnum;
  lead: LeadInterface;
  approval_status: MeetingStatusEnum | null;
  rejection_reason: string | null;
}
