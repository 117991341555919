import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/white-arrow.svg';
import styles from './recent-updates.module.scss';
import { Loader } from '@orascom/common-components';
import useNotifications from '../../hooks/use-notifications';
import NotificationsUpdates from '../notifications-wrapper/notifications-wrapper';

export function RecentUpdates() {
  const { t } = useTranslation();
  const { notifications, loading } = useNotifications(1, 2, true);
  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <h3>{t('recentUpdates')}</h3>
        <Link to={ROUTES['Notifications'].path} className={styles['anchor']}>
          <span className="anchor">{t('viewAllUpdates')}</span>
          <img src={arrowIcon} alt="" role="presentation" />
        </Link>
      </div>
      <div className={styles['flex']}>
        {notifications?.data.length ? (
          notifications?.data.map((u) => (
            <NotificationsUpdates key={u.id} notification={u} dashboard />
          ))
        ) : (
          <div className={styles['news']}>
            <div className={styles['details']}>
              <p className={styles['empty-placholder']}>
                {t('noRecentUpdates')}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecentUpdates;
